import React, { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { BrowserView } from 'react-device-detect';
import { AnimatedLogo, Button } from '@setapp/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { Link, NavLink, useHistory } from 'components/navigation';
import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';
import InviteFriendsButton from 'components/shared/invite-friends-button/invite-friends-button';
import DefaultError from 'components/shared/default-error/default-error';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import analytics, { events } from 'utils/analytics';

import { detectDesktopApp } from 'state/desktop-app/desktop-app-reducer';
import {
  canUseReferralProgram as canUseReferralProgramSelector,
  hasDesktopAppAlreadyDetected as hasDesktopAppAlreadyDetectedSelector,
  isDesktopAppAvailable as isDesktopAppAvailableSelector,
  isUserAuthenticated as isUserAuthenticatedSelector,
  isUserRegistrationCompleted as isUserRegistrationCompletedSelector,
  getFeatureFlags,
  getUser,
  isMembershipPriceIncreaseEnabled,
} from 'state/root-reducer';
import { logout as logOut } from 'state/user/user-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import MenuOpenIcon from './images/menu-open-icon.svg';
import MenuCloseIcon from './images/menu-close-icon.svg';

import './app-header.scss';

const MAIN_MENU_ITEMS = [{
  link: externalUrls.howItWorks,
  title: <FormattedMessage id="appHeader.howItWorksLinkTitle" defaultMessage="How it works" />,
}, {
  link: externalUrls.allApps,
  title: <FormattedMessage id="appHeader.allAppsLinkTitle" defaultMessage="All apps" />,
}, {
  link: externalUrls.pricing,
  title: <FormattedMessage id="appHeader.pricingLinkTitle" defaultMessage="Pricing" />,
}, {
  link: externalUrls.teams,
  title: <FormattedMessage id="appHeader.forTeamsLinkTitle" defaultMessage="For Teams" />,
}, {
  link: externalUrls.blog,
  title: <FormattedMessage id="appHeader.blogLinkTitle" defaultMessage="Blog" />,
}];

type Props = InjectedIntlProps & {
  onToggle?: (isOpened: boolean) => void;
  outerLayoutHeader?: boolean;
};

const messages = defineMessages({
  logoLinkDescription: {
    id: 'appHeader.logoAriaLabel',
    defaultMessage: 'Go to homepage',
  },
  openNavAriaLabel: {
    id: 'appHeader.openNavAriaLabel',
    defaultMessage: 'Show navigation',
  },
  closeNavAriaLabel: {
    id: 'appHeader.closeNavAriaLabel',
    defaultMessage: 'Close navigation',
  },
});

const AppHeader = ({
  outerLayoutHeader = false,
  intl,
  onToggle
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const [menuItems, setMenuItems] = useState(MAIN_MENU_ITEMS);

  const { desktopCcRequired } = useSelector(getUser);
  const hasDesktopAppAlreadyDetected = useSelector(hasDesktopAppAlreadyDetectedSelector);
  const isDesktopAppAvailable = useSelector(isDesktopAppAvailableSelector);
  const isUserAuthenticated =  useSelector(isUserAuthenticatedSelector);
  const isUserRegistrationCompleted = useSelector(isUserRegistrationCompletedSelector);
  const canUseReferralProgram = useSelector(canUseReferralProgramSelector);
  const featureFlags = useSelector(getFeatureFlags);
  const { membershipPriceIncrease } = useSelector(isMembershipPriceIncreaseEnabled);

  useEffect(() => {
    if (featureFlags.podcastLandingPage.value) {
      setMenuItems([
        ...menuItems,
        {
          link: externalUrls.podcast,
          title: <FormattedMessage id="appHeader.podcastLinkTitle" defaultMessage="Podcast" />,
        },
      ]);
    }

    if (!hasDesktopAppAlreadyDetected) {
      dispatch(detectDesktopApp());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavToggleClick = () => {
    const newValue = !isOpened;
    setIsOpened(newValue);

    if (onToggle) {
      onToggle(newValue);
    }
  };

  const handleLogoutClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      await dispatch(logOut());
      history.push(urls.login);
    } catch (error) {
      showDangerNotification(<DefaultError />);
    }
  };

  const handleDownloadClick = () => {
    analytics.trackEvent(events.HEADER_DOWNLOAD_LINK_CLICK);
  };

  const downloadButton = (
    <SetappDownloadLinkWrapper>
      <Button
        href={externalUrls.downloadDesktopClient}
        size="sm"
        onClick={handleDownloadClick}
      >
        <FormattedMessage id="appHeader.downloadLinkTitle" defaultMessage="Download" />
      </Button>
    </SetappDownloadLinkWrapper>
  );

  const actionButton = (isDesktopAppAvailable && canUseReferralProgram && !membershipPriceIncrease)
    ? <InviteFriendsButton />
    : downloadButton;

  const showActionButton = desktopCcRequired
    ? isUserAuthenticated
    : isUserAuthenticated && isUserRegistrationCompleted;

  const overlayClasses = classNames('app-header__overlay', {
    'app-header__overlay_shown': isOpened,
  });
  const accountLinkClasses = classNames('app-header__link', {
    'app-header__link_active': !outerLayoutHeader,
  });

  return (
    <nav className="app-header">
      <div className="app-header__content">
        <a
          className="app-header__logo"
          href={externalUrls.setappSite}
          aria-label={intl.formatMessage(messages.logoLinkDescription)}
        >
          <span className="hidden-xs">
            <AnimatedLogo hasCaption size="sm" />
          </span>
          <span className="hidden visible-xs">
            <AnimatedLogo size="sm" />
          </span>
        </a>
        <BrowserView renderWithFragment>
          {showActionButton && <div className="app-header__action-button hidden-lg">{actionButton}</div>}
        </BrowserView>
        <button
          type="button"
          className="hidden-lg app-header__open-button"
          aria-label={intl.formatMessage(messages.openNavAriaLabel)}
          onClick={handleNavToggleClick}
        >
          <img src={MenuOpenIcon} width={20} height={20} alt="Open menu" />
        </button>
        <CSSTransition
          classNames={{
            enter: 'app-header__menu-slider_enter',
            enterActive: 'app-header__menu-slider_enter-active',
            enterDone: 'app-header__menu-slider_enter-done',
            exit: 'app-header__menu-slider_exit',
          }}
          timeout={300}
          in={isOpened}
        >
          <div className="app-header__menu-slider">
            <button
              type="button"
              className="hidden-lg app-header__close-button"
              aria-label={intl.formatMessage(messages.closeNavAriaLabel)}
              onClick={handleNavToggleClick}
            >
              <img src={MenuCloseIcon} width={20} height={20} alt="Close menu" />
            </button>
            <div className="app-header__menu-list-container">
              <div className="app-header__menu-logo_mobile visible-xs">
                <AnimatedLogo size="sm" />
              </div>
              <div className="app-header__menu-logo_tablet visible-sm visible-md">
                <AnimatedLogo />
              </div>
              <ul className="app-header__menu-list">
                <li className="app-header__menu-list-item visible-xs">
                  <a
                    className="app-header__link"
                    href={externalUrls.setappSite}
                  >
                    <FormattedMessage id="appHeader.setappHomeTitle" defaultMessage="Home" />
                  </a>
                </li>

                {menuItems.map(({ link, title }) => (
                  <li key={link} className="app-header__menu-list-item">
                    <a className="app-header__link" href={link}>{title}</a>
                  </li>
                ))}

                {isUserAuthenticated && (
                  isUserRegistrationCompleted
                    ? (
                      <li className="app-header__menu-list-item app-header__menu-list-item_divider">
                        <Link to={urls.root} className={accountLinkClasses}>
                          <FormattedMessage
                            id="appHeader.accountLinkTitle"
                            defaultMessage="My Setapp"
                          />
                        </Link>
                      </li>
                    ) : (
                      <li className="app-header__menu-list-item app-header__menu-list-item_divider">
                        <Link to={urls.login} className="app-header__link" onClick={handleLogoutClick}>
                          <FormattedMessage
                            id="appHeader.logOutLinkTitle"
                            defaultMessage="Log out"
                          />
                        </Link>
                      </li>
                    )
                )}

                {!isUserAuthenticated && (
                  <li className="app-header__menu-list-item">
                    <NavLink to={urls.login} className="app-header__link" activeClassName="app-header__link_active">
                      <FormattedMessage
                        id="appHeader.signInLinkTitle"
                        defaultMessage="Sign in"
                      />
                    </NavLink>
                  </li>
                )}

                <BrowserView renderWithFragment>
                  {showActionButton && (
                    <li className="app-header__menu-list-item hidden visible-lg">
                      {actionButton}
                    </li>
                  )}
                </BrowserView>
              </ul>
            </div>
          </div>
        </CSSTransition>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={overlayClasses} onClick={handleNavToggleClick} />
    </nav>
  );
};

export default injectIntl(AppHeader);
