import React, { PureComponent } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { type RouteComponentProps } from 'react-router-dom';

import urls from 'config/urls';

import { fetchFamilyPlan } from 'state/family-plan/family-plan-actions';
import { fetchSubscription } from 'state/subscription/subscription-actions';
import {
  isFamilyStatusActive,
  isFamilyFetched,
  isSubscriptionsFetched,
  isFamilyStatusPending,
  isUserFamilyOwner,
} from 'state/root-reducer';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';

import withRouter from 'components/navigation/navigation-with-router';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import DefaultError from 'components/shared/default-error/default-error';
import ActivatedFamilyPlan from './activated-family-plan/activated-family-plan';


import './old-family-plan-page.scss';

const mapStateToProps = (state) => ({
  isFamilyStatusActive: isFamilyStatusActive(state),
  isFamilyStatusPending: isFamilyStatusPending(state),
  isFamilyFetched: isFamilyFetched(state),
  isSubscriptionsFetched: isSubscriptionsFetched(state),
  isUserFamilyOwner: isUserFamilyOwner(state),
});

const mapActionsToProps = {
  fetchFamilyPlan,
  fetchSubscription,
  showSuccessNotification,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

class OldFamilyPlanPage extends PureComponent<Props> {
  componentDidMount() {
    const {
      history,
      isUserFamilyOwner,
      fetchFamilyPlan,
      fetchSubscription,
    } = this.props;

    if (!isUserFamilyOwner) {
      history.replace(urls.subscription);

      return;
    }

    // eslint-disable-next-line consistent-return
    return Promise.all([
      fetchFamilyPlan(),
      fetchSubscription(),
    ])
      .catch(() => {
        const { showDangerNotification } = this.props;

        showDangerNotification(<DefaultError />);
      });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      isFamilyStatusPending,
      isFamilyStatusActive,
      showSuccessNotification,
      showDangerNotification,
    } = this.props;

    if (prevProps.isFamilyStatusPending && !isFamilyStatusPending) {
      if (isFamilyStatusActive) {
        showSuccessNotification(<FormattedMessage
          id="familyPlan.paymentSuccessNotification"
          defaultMessage="Family plan activated successfully."
                                />);
      } else {
        showDangerNotification(<FormattedMessage
          id="familyPlan.paymentFailedNotification"
          defaultMessage="Something went wrong with your payment. Please retry or update your payment details."
                               />);
      }
    }
  }

  render() {
    const { isFamilyStatusActive, isFamilyFetched, isSubscriptionsFetched } = this.props;

    if (!isFamilyFetched || !isSubscriptionsFetched) {
      return <AppLayoutLoading />;
    }

    return isFamilyStatusActive && <ActivatedFamilyPlan />;
  }
}

export { OldFamilyPlanPage as PureOldFamilyPlanPage };

const enhancedOldFamilyPlanPage = withRouter(OldFamilyPlanPage);

export default connector(enhancedOldFamilyPlanPage);
