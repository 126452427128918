import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import analytics, { events } from 'utils/analytics';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import { getTrialLength } from 'state/root-reducer';

import { Link } from 'components/navigation';

import './trial-unknown-action.scss';

const TrialUnknownAction: FC = () => {
  const trialLength = useSelector(getTrialLength);
  const handleDownloadSetappApp = () => {
    analytics.trackEvent(events.SUBSCRIPTION_BLOCK_DOWNLOAD_START, { eventLabel: 'Click Button' });
  };
  const handleBuyPlan = () => {
    analytics.trackEvent(events.SUBSCRIPTION_BLOCK_CLICK_BUY_PLAN);
  };

  return (
    <section className="trial-unknown-action">
      <h5>
        <FormattedMessage
          id="trialUnknownAction.title"
          defaultMessage="Start your Setapp trial"
        />
      </h5>
      <p className="trial-unknown-action__description">
        <FormattedMessage
          id="trialUnknownAction.description"
          defaultMessage="Download and install the Setapp desktop app to kick off your free {trialLength}-day trial."
          values={{ trialLength }}
        />
      </p>
      <div className="trial-unknown-action__cta-wrapper">
        <Button
          variant="primary"
          className="trial-unknown-action__btn-download"
          href={externalUrls.downloadDesktopClient}
          onClick={handleDownloadSetappApp}
        >
          <FormattedMessage
            id="trialUnknownAction.downloadButton"
            defaultMessage="Download Setapp app"
          />
        </Button>
        <Link to={urls.activateSubscription}>
          <Button
            variant="secondary-outline"
            className="trial-unknown-action__btn-buy-plan"
            onClick={handleBuyPlan}
          >
            <FormattedMessage
              id="trialUnknownAction.buyPlanButton"
              defaultMessage="Buy plan"
            />
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default TrialUnknownAction;
