import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Cookies from 'js-cookie';
import { Button } from '@setapp/ui-kit';
import { getFeatureFlags } from 'state/root-reducer';
import externalUrls from 'config/external-urls';
import { COOKIES_ROOT_DOMAIN } from 'config/app';
import { useNavigation } from 'components/navigation';

import './cookie-banner.scss';

const ACCEPTED_COOKIE_NAME = 'cookieAccepted';

const CookieBanner = () => {
  const { isEmbedded } = useNavigation();
  const featureFlags = useSelector(getFeatureFlags);

  const [showCookieBanner, setShowCookieBanner]
  = useState(featureFlags.isVisibleOldCookieBanner.value && !Cookies.get(ACCEPTED_COOKIE_NAME));

  if (!showCookieBanner || isEmbedded) return null;

  const onConfirmCookie = () => {
    Cookies.set(ACCEPTED_COOKIE_NAME, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 5 });
    setShowCookieBanner(false);
  };

  return (
    <div className="cookie-banner" role="alert">
    <div className="cookie-banner__text text_xs mr-3">
      <FormattedMessage
        id="cookieNotification.text"
        defaultMessage="Setapp uses cookies to personalize your experience on our website. By continuing to use this site, you agree to our {cookiePolicyLink}."
        values={{
          cookiePolicyLink: (
            <a
              className="link"
              href={externalUrls.cookiePolicy}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage
                id="cookieBanner.cookieLink"
                defaultMessage="cookie policy"
              />
            </a>
          ),
        }}
      />
    </div>

    <Button
      className="cookie-banner__button"
      variant="light"
      size="sm"
      onClick={onConfirmCookie}
    >
      <FormattedMessage
        id="cookieBanner.confirmBtn"
        defaultMessage="Okay"
      />
    </Button>
    </div>
  );
};

export default CookieBanner;
