import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import modalComponents from 'components/modals';

import { hideModal, removeModal } from 'state/modal/modal-reducer';
import type { State } from 'state/state-types';

const ModalRoot = () => {
  const dispatch = useDispatch();
  const { modalType, modalProps, isVisible } = useSelector((state: State) => state.modal);

  if (!modalType) {
    return null;
  }

  const Modal = modalComponents[modalType];

  if (!Modal) {
    throw new Error(`Unknown modal type "${modalType}"`);
  }

  const handleHide = () => dispatch(hideModal());
  const handleExited = () => dispatch(removeModal());

  return (
    <Modal
      {...modalProps}
      show={isVisible}
      onHide={handleHide}
      onExited={handleExited}
    />
  );
};

export default ModalRoot;
