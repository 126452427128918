import { RouteComponentProps } from 'react-router-dom';

export const isHTTPS = () => window.location.protocol === 'https:';

export const isValidHttpURL = (url: string) => {
  let protocol: string;

  try {
    ({ protocol } = new URL(url));
  } catch {
    return false;
  }

  return protocol === 'http:' || protocol === 'https:';
};

/**
 * Returns root cookie domain for application. E.g. .example.com for subdomain.example.com, other.example.com, etc.
 */
export const getCookiesRootDomain = () => window.location.hostname.split('.').slice(-2).join('.');

export const removeQueryParams = (history: RouteComponentProps['history'], ...params: Array<string>) => {
  const { location } = history;
  const query = new URLSearchParams(location.search);

  params.forEach((param) => query.delete(param));

  history.replace({ search: query.toString() });
};
