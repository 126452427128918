import React from 'react';
import classNames from 'classnames';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'state/root-reducer';
import ImmediatelyDownloadLink from 'components/shared/immediately-download-link/immediately-download-link';

import setappIcon from './images/setapp-icon.png';
import setappIcon2x from './images/setapp-icon@2x.png';
import setappIconO10rSepRelease from './images/setapp-icon.svg';

import './setapp-description-section-for-desktop.scss';

type Props = {
  appName: string;
  onDirectDownloadClick: () => void;
  onHowItWorksButtonClick: () => void;
};

const SetappDescriptionSectionForDesktop = ({
  appName, onDirectDownloadClick, onHowItWorksButtonClick,
}: Props) => {
  const { o10rReleaseScopeEnabled } = useSelector(getFeatureFlags);
  const isO10rEnabled = Boolean(o10rReleaseScopeEnabled.value);

  const contentMap = {
    new: {
      image: (
        <img
          src={setappIconO10rSepRelease}
          className="setapp-description-section-for-desktop__setapp-icon"
          data-qa="defaultIcon"
          alt="Setapp logo"
          width={160}
        />
      ),
      title: (
        <FormattedHTMLMessage
          id="setappDescriptionSectionForDesktop.o10rReleaseScopeEnabled.title"
          defaultMessage="Done! Let’s install Setapp to</br> get {appName}"
          values={{ appName }}
        />
      ),
      description: (
        <FormattedHTMLMessage
          id="setappDescriptionSectionForDesktop.o10rReleaseScopeEnabled.description"
          defaultMessage="Jump into Setapp. Get the app you came for.</br> Explore other apps you’re getting with Setapp Membership."
        />
      ),
    },
    legacy: {
      image: (
        <img
          src={setappIcon}
          srcSet={`${setappIcon2x} 2x`}
          className="setapp-description-section-for-desktop__legacy-setapp-icon"
          data-qa="defaultIcon"
          alt=""
        />
      ),
      title: (
        <FormattedMessage
          id="setappDescriptionSectionForDesktop.title"
          defaultMessage="{appName} and Setapp are yours"
          values={{ appName }}
        />
      ),
      description: (
        <FormattedHTMLMessage
          id="setappDescriptionSectionForDesktop.description"
          defaultMessage="You came here for the app you love.</br>We hope you stay for the apps you never knew existed."
        />
      ),
    }
  };

  const { image, title, description } = isO10rEnabled ? contentMap.new : contentMap.legacy;

  const titleClasses = classNames({
    'h4': !isO10rEnabled,
    'h3 setapp-description-section-for-desktop__text-nowrap': isO10rEnabled
  });
  const descriptionClasses = classNames('mb-8',
    { 'text_lg setapp-description-section-for-desktop__text-nowrap': isO10rEnabled }
  );
  const buttonClasses = classNames({
    'setapp-description-section-for-desktop__legacy-button': !isO10rEnabled,
    'setapp-description-section-for-desktop__button': isO10rEnabled
  });

  return (
    <div className="setapp-description-section-for-desktop">
      {image}

      <h1 className={titleClasses} data-qa="pageTitle">{title}</h1>
      <p className={descriptionClasses} data-qa="pageDescription">{description}</p>

      <Button
        className={buttonClasses}
        onClick={onHowItWorksButtonClick}
        data-qa="howSetappWorksButton"
      >
        <FormattedMessage
          id="setappDescriptionSectionForDesktop.button"
          defaultMessage="How Setapp works"
        />
      </Button>
      <p className="text_color-secondary">
        <ImmediatelyDownloadLink
          onDirectDownloadClick={onDirectDownloadClick}
          customClass="secondary-text"
        />
      </p>
    </div>
  );
};

export default SetappDescriptionSectionForDesktop;
