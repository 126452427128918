import urls from 'config/urls';
import { COOKIES_ROOT_DOMAIN } from 'config/app';

export enum MagicFrameAction {
  SetAccessToken = 'wizard:setAccessToken',
  SyncLocale = 'wizard:syncLocale',
  SetAllowedPaths = 'wizard:setAllowedPaths',
  SetNotification = 'wizard:setNotification',
  Navigate = 'wizard:navigate',
  Error = 'wizard:error',
}

export enum MagicFrameErrorEventType {
  UnauthorizedRequest = 'wizard:error:unauthorized',
  BadRequest = 'wizard:error:badRequest',
}

export interface NotificationEventData {
  message: string;
  color?: 'info' | 'warning' | 'danger';
}

export interface NavigateEventData {
  path: string;
  isAllowed: boolean;
}

export const EMBED_PAGE_PATH = '/embed';

export const O10R_REDIRECT_EXCEPTION_URLS = new Set([
  urls.oauthLogin,
  urls.oauthAuthorize,
  urls.oauthConfirmEmail,
  `${urls.socialAuth}/google`,
  `${urls.socialAuth}/apple`,
  urls.recoverPassword,
  urls.newPassword,
]);

export const COOKIES_SETTINGS = {
  domain: COOKIES_ROOT_DOMAIN,
  secure: true,
  sameSite: 'none',
} as const;
