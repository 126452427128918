import React, { ReactNode } from 'react';
import { AnimatedLogo } from '@setapp/ui-kit';

import classnames from 'classnames';
import Footer from 'components/layout/footer/footer';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import './fullscreen-layout-wide.scss';
import { useNavigation } from 'components/navigation';

type Props = {
  withLogo?: boolean;
  withFooter?: boolean;
  children: ReactNode;
};

const FullscreenLayoutWide = ({ withLogo, withFooter, children }: Props) => {
  const { isEmbedded } = useNavigation();

  return (
    <FullscreenLayout>
      <div className="fullscreen-layout-wide">
        <div
          className={classnames('fullscreen-layout-wide__container', {
            'fullscreen-layout-wide__container-embedded': isEmbedded,
          })}
        >
          <div className="fullscreen-layout-wide__content">
            {withLogo && !isEmbedded && (
              <div className="fullscreen-layout-wide__setapp-logo">
                <AnimatedLogo hasCaption size="sm" />
              </div>
            )}
            {children}
          </div>
        </div>
        {withFooter && !isEmbedded && <Footer />}
      </div>
    </FullscreenLayout>
  );
};

export default FullscreenLayoutWide;
