import { defineMessages,  type InjectedIntlProps } from 'react-intl';
import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import PRICE_PLAN_PERIOD_MAPPER from 'services/price-plans/price-plan-period-mapper';
import { type NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { type Subscription } from 'state/subscription/subscription-initial-state';

const messages = defineMessages({
  changePricePlanTrial: {
    id: 'changePricePlanFlow.planChangedNotification.changePricePlan.trial',
    defaultMessage: '{nextPricePlanTitle} plan features on! Subscription will begin after trial.',
  },
  changeBillingPeriodMonthly: {
    id: 'changePricePlanFlow.planChangedNotification.changeBillingPeriod.monthly',
    defaultMessage: 'You’ve changed your billing period. You will be charged monthly starting {date}.',
  },
  changeBillingPeriodAnnual: {
    id: 'changePricePlanFlow.planChangedNotification.changeBillingPeriod.annual',
    defaultMessage: 'You’ve changed your billing period. You will be charged annually starting {date}.',
  },
  changeBillingPeriodMonthlyNoDate: {
    id: 'changePricePlanFlow.planChangedNotification.changeBillingPeriod.monthlyNoDate',
    defaultMessage: 'You’ve changed your billing period. You will be charged monthly.',
  },
  changeBillingPeriodAnnualNoDate: {
    id: 'changePricePlanFlow.planChangedNotification.changeBillingPeriod.annualNoDate',
    defaultMessage: 'You’ve changed your billing period. You will be charged annually.',
  },
  planChangedNotificationDefault: {
    id: 'changePricePlanFlow.planChangedNotification.default',
    defaultMessage: 'You’ve switched to the {nextPricePlanTitle} plan.',
  },
  changePricePlanScheduledStrategy: {
    id: 'changePricePlanFlow.planChangedNotification.changePricePlan.scheduledStrategy',
    defaultMessage:
      'You’ve switched to the {nextPricePlanTitle} plan. Your new plan will become active on {nextPaymentDate}.',
  },
});

const getChangeBillingPeriodMessage = (primarySubscription, nextPricePlan, intl) => {
  const { nextPaymentDate } = primarySubscription;
  const isMonthlyNextBillingPeriod = nextPricePlan.paidMonth === 1;

  if (nextPaymentDate) {
    const formattedNextPaymentDate = (
      intl.formatDate(nextPaymentDate * 1000, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    );

    return isMonthlyNextBillingPeriod
      ? intl.formatMessage(messages.changeBillingPeriodMonthly, { date: formattedNextPaymentDate })
      : intl.formatMessage(messages.changeBillingPeriodAnnual, { date: formattedNextPaymentDate });
  }

  return isMonthlyNextBillingPeriod
    ? intl.formatMessage(messages.changeBillingPeriodMonthlyNoDate)
    : intl.formatMessage(messages.changeBillingPeriodAnnualNoDate);
};

const getChangePlanMessage = (nextPricePlanCalculationData, nextPricePlanTitle, intl) => {
  let message = intl.formatMessage(messages.planChangedNotificationDefault, {
    nextPricePlanTitle
  });

  if (nextPricePlanCalculationData) {
    const { strategy, nextPaymentDate } = nextPricePlanCalculationData;

    if (strategy === 'scheduled') {
      message = intl.formatMessage(messages.changePricePlanScheduledStrategy, {
        nextPricePlanTitle,
        nextPaymentDate: intl.formatDate(nextPaymentDate * 1000, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      });
    }
  }

  return message;
};

type RenderMessageParams = InjectedIntlProps & {
  nextPricePlan: PricePlan;
  previousPricePlan: PricePlan;
  primarySubscription: Subscription;
  nextPricePlanCalculationData?: NextPricePlanCalculationData;
};

const renderPlanChangedNotificationMessage = ({
  nextPricePlan,
  previousPricePlan,
  primarySubscription,
  nextPricePlanCalculationData,
  intl,
}: RenderMessageParams) => {
  const nextPricePlanMetadata = getPricePlanMetadata(nextPricePlan);
  const nextPricePlanTitle = intl.formatMessage(nextPricePlanMetadata.titleMessageDescriptor);

  if (primarySubscription.status === subscriptionStatuses.TRIAL) {
    return intl.formatMessage(messages.changePricePlanTrial, { nextPricePlanTitle });
  }

  const oppositeBillingPeriodTierType = PRICE_PLAN_PERIOD_MAPPER[previousPricePlan.tierType];
  const isBillingPeriodChange = nextPricePlan.tierType === oppositeBillingPeriodTierType;

  return isBillingPeriodChange
    ? getChangeBillingPeriodMessage(primarySubscription, nextPricePlan, intl)
    : getChangePlanMessage(nextPricePlanCalculationData, nextPricePlanTitle, intl);
};

export default renderPlanChangedNotificationMessage;
