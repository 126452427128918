import React from 'react';
import { Notification } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { DEPRECATED_SOCIAL_PROVIDER_NAMES } from 'config/social';
import { useNavigation } from 'components/navigation';
import NotificationsContainer from 'components/shared/notification/notifications-container';
import CookieBanner from 'components/shared/banners/cookie-banner/cookie-banner';
import ErrorBoundary from 'components/shared/error-boundary/error-boundary';
import Footer from '../footer/footer';
import AppHeader from '../app-layout/header/app-header';
import ModalRoot from '../app-layout/modal-root/modal-root';

import './outer-layout.scss';

type Props = {
  children: ReactNode;
};

const OuterLayout = ({ children }: Props) => {
  const location = useLocation();
  const { isEmbedded } = useNavigation();
  const { authService } = queryString.parse(location.search);
  const authServiceQueryParam = authService ? authService.toString() : '';
  const showNotification = authServiceQueryParam
    && (authServiceQueryParam in DEPRECATED_SOCIAL_PROVIDER_NAMES);

  return (
    <>
      <div className="outer-layout outer-layout_light">
        <div className="outer-layout__container">
          {
            !isEmbedded && (
              <header className="outer-layout__header">
                <AppHeader outerLayoutHeader />
              </header>
            )
          }

          {/* remove it after stop supporting version 3.22.3 of desktop app */}
          {showNotification && (
            <div className="outer-layout__permanent-notifications mt-5">
              <Notification type="danger" withIcon>
                <FormattedMessage
                  id="socialAuth.facebookNotSupported"
                  defaultMessage="We don’t support Facebook sign in anymore. Please use your email and password to sign in or restore below."
                />
              </Notification>
            </div>
          )}

          <main className="outer-layout__main container-fluid">
            <div className="outer-layout__notifications">
              <NotificationsContainer />
            </div>
            <ErrorBoundary fullscreen>
              {children}
            </ErrorBoundary>
          </main>

          {
            !isEmbedded && (
              <div className="outer-layout__footer">
                <Footer />
              </div>
            )
          }
        </div>
      </div>
      <CookieBanner />
      <ModalRoot />
    </>
  );
};

export default OuterLayout;
