import React, { createContext, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { MagicFrameInnerProvider } from '@mwkt/react-magic-frame';

import useNavigationGuard from './hooks/use-navigation-guard';
import EmbeddedProvider from './embedded-provider';

interface NavigationContextProps {
  isEmbedded: boolean;
  allowedPaths: string[];
  setAllowedPaths: (paths: string[]) => void;
}

export const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const NavigationProvider = ({ children }) => {
  const [allowedPaths, setAllowedPaths] = useState<string[]>([]);
  const { isEmbedded, redirectUrl, redirectPath } = useNavigationGuard();

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (redirectUrl) {
    window.location.replace(redirectUrl);

    return null;
  }

  if (isEmbedded) {
    document.body.dataset.iframe = 'true';
  }

  return (
    <NavigationContext.Provider value={{ isEmbedded, allowedPaths, setAllowedPaths }}>
      {isEmbedded ? (
        <MagicFrameInnerProvider rootSelector="#root" allowedOrigin="*">
          <EmbeddedProvider>
            {children}
          </EmbeddedProvider>
        </MagicFrameInnerProvider>
      ) : children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }

  return context;
};
