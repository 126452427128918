import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'components/navigation';

import urls from 'config/urls';

type Props = {
  signUpForm: ReactNode;
  isPromoCodeValid: boolean;
}

const SecondStepPrimaryContent = ({ signUpForm, isPromoCodeValid }: Props) => (
  <>
    <h3 className="redeem__primary-title">
      <FormattedMessage id="redeem.primary.title.success" defaultMessage="Success! Let’s make you an account." />
    </h3>
    {signUpForm}
    {!isPromoCodeValid && (
      <span className="mt-5">
        <FormattedMessage
          id="redeem.primary.note.login"
          defaultMessage="Already have an account? {loginLink}"
          values={{
            loginLink: (
              <Link to={urls.login}>
                <FormattedMessage
                  id="redeem.primary.note.login.link"
                  defaultMessage="Sign in"
                />
              </Link>
            ),
          }}
        />
      </span>
    )}
  </>
);

export default SecondStepPrimaryContent;
