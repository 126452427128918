import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { useHistory } from 'components/navigation';
import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import useIosCollectionsConfig from 'components/hooks/use-ios-collections/use-ios-collections';
import useIosEventHandlers from 'components/hooks/use-ios-event-handlers/use-ios-event-handlers';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchIosApps } from 'state/ios-apps/ios-apps-actions';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import {
  hasIosSeats as hasIosSeatsSelector,
  isSubscriptionsFetched as isSubscriptionsFetchedSelector,
  getIosApps,
} from 'state/root-reducer';
import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

import { shouldOpenActivationForApp } from 'utils/activate-ios-helpers';

import urls from 'config/urls';

import IosAppsList from './ios-apps-list/ios-apps-list';
import IosAppsPack from './ios-apps-pack/ios-apps-pack';
import IosCollectionBanner from './ios-collection-banner/ios-collection-banner';
import PopupAboutDownload from './popup-about-download/popup-about-download';

const IosAppsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [firstApp, setFirstApp] = useState<IosApp>();

  const { handleIosAppClick } = useIosEventHandlers();
  const { appPacks, collectionBanners } = useIosCollectionsConfig();

  const hasIosSeats = useSelector(hasIosSeatsSelector);
  const isSubscriptionsFetched = useSelector(isSubscriptionsFetchedSelector);
  const { list: appsList, isLoading: isIosAppsLoading } = useSelector(getIosApps);

  useEffect(() => {
    try {
      if (!isSubscriptionsFetched) {
        dispatch(fetchAllSubscriptions());
      }
      if (!appsList.length) {
        dispatch(fetchIosApps());
      }
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appsList.length) {
      const { shouldOpenActivation, selectedAppId } = shouldOpenActivationForApp(history);
      // if there is query for app activation, we place it first in the list
      const firstAppToShow = appsList.find((app) => app.id === selectedAppId);

      if (shouldOpenActivation && firstAppToShow) {
        setFirstApp(firstAppToShow);
        handleIosAppClick(firstAppToShow);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsList.length]);

  if (!isSubscriptionsFetched) {
    return <AppLayoutLoading />;
  }

  if (!hasIosSeats) {
    history.push(urls.subscription);
  }

  const appsWithNewFirstApp: IosApp[] = firstApp
    ? [firstApp, ...appsList.filter((app) => app.id !== firstApp.id)]
    : appsList;

  return (
    <>
      <PageTitle>
        <FormattedMessage id="iosApps.welcomeTitle" defaultMessage="Apps for iOS" />
      </PageTitle>
      <p className="mb-0">
        <FormattedMessage
          id="iosApps.welcomeSubtitle"
          defaultMessage="Power up your flow with these handy apps for your iOS devices."
        />
      </p>

      {!appsList.length || isIosAppsLoading ? (
        <AppLayoutLoading />
      ) : (
        <>
          {isMobile && (
            <>
              {appPacks.map((pack) => (
                <IosAppsPack
                  key={pack.id}
                  id={pack.id}
                  appsList={appsList
                    .filter((app) => pack.appIds.includes(app.id))
                    .sort((a, b) => b.id - a.id)}
                  title={pack.title}
                  pictureType={pack.pictureType}
                  collectionName={pack.name}
                />
              ))}
              <h4 className="mt-8">
                <FormattedMessage id="iosApps.collections.title" defaultMessage="Collections" />
              </h4>
              {collectionBanners.map((banner) => (
                <IosCollectionBanner
                  key={banner.id}
                  id={banner.id}
                  title={banner.title}
                  description={banner.description}
                  appsList={appsList.filter((app) => banner.appIds.includes(app.id))}
                />
              ))}
              <h4 className="mb-0 mt-6">
                <FormattedMessage
                  id="iosApps.list.allApps"
                  defaultMessage="All apps"
                />
              </h4>
            </>
          )}
          <IosAppsList appsList={appsWithNewFirstApp} />
          <PopupAboutDownload />
        </>
      )}
    </>
  );
};

export default IosAppsPage;
