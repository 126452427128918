import type { ResolvedFeatureFlags } from 'utils/feature-flags';
import { defaultFeatureFlags } from 'config/feature-flags';
import * as actionTypes from './feature-flags-actions-types';
import type { FeatureFlagsAction } from './feature-flags-actions-types';
import initialState from './feature-flags-initial-state';
import type { FeatureFlagsState } from './feature-flags-initial-state';


const featureFlags = (
  state: FeatureFlagsState = initialState, action: FeatureFlagsAction,
): FeatureFlagsState => {
  switch (action.type) {
    case actionTypes.REQUEST: return {
      ...state,
      isFetched: false,
      isLoading: true,
    };
    case actionTypes.REQUEST_SUCCESS: return {
      ...state,
      isFetched: true,
      isLoading: false,
      featureFlags: action.featureFlags,
    };
    case actionTypes.REQUEST_ERROR: return {
      featureFlags: {
        ...defaultFeatureFlags,
        ...state.featureFlags,
      },
      isFetched: state.isFetched,
      isLoading: false
    };
    default: return state;
  }
};

export default featureFlags;

export const getFeatureFlags = (state: FeatureFlagsState): ResolvedFeatureFlags => state.featureFlags;
export const getIsFeatureFlagsLoading = (state: FeatureFlagsState) => state.isLoading;
