import React, { type ComponentType } from 'react';
import useWizardEmitError from './hooks/use-wizard-emit-error';

export interface WithWizardEmitError {
  onWizardEmitError: ReturnType<typeof useWizardEmitError>;
}

const withWizardEmitError = <Props extends Record<string, unknown>>(Component: ComponentType<Props>) => {
  const WrappedComponent = (props: Omit<Props, 'onWizardEmitError'>) => {
    const wizardEmitError = useWizardEmitError();

    return (
      // @ts-expect-error -- TS doesn't understand that we're passing the props down
      <Component
        {...props}
        onWizardEmitError={wizardEmitError}
      />
    );
  };

  WrappedComponent.displayName = `withWizardEmitError(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export default withWizardEmitError;
