import React  from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { Subscription } from 'state/subscription/subscription-initial-state';
import renderPlanChangedNotificationMessage
  from 'components/user-flow/change-price-plan/plan-changed-notification/render-plan-changed-notification-message';
import successIcon from './images/icons-success.svg';

import './plan-changed-notification.scss';

type Props = {
  nextPricePlan: PricePlan;
  previousPricePlan: PricePlan;
  primarySubscription: Subscription;
  nextPricePlanCalculationData?: NextPricePlanCalculationData;
} & InjectedIntlProps;


const PlanChangedNotification = (props: Props) => {
  return (
    <div className="plan-changed-notification">
      <img width="20" height="20" src={successIcon} alt="Success icon" />
      <div className="ml-3">{renderPlanChangedNotificationMessage(props)}</div>
    </div>
  );
};

PlanChangedNotification.defaultProps = {
  nextPricePlanCalculationData: undefined,
};

export default injectIntl(PlanChangedNotification);
