import React from 'react';
import type { ReactNode } from 'react';
import chunk from 'lodash.chunk';
import { Link } from 'components/navigation';

import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

import urls from 'config/urls';

import IosPackCard from '../ios-pack-card/ios-pack-card';

import './ios-apps-pack.scss';

type Props = {
  id: number;
  appsList: IosApp[];
  title: string | ReactNode;
  pictureType: 'start' | 'stress';
  collectionName: string;
}

const IosAppsPack = ({ appsList, title, pictureType, id, collectionName }: Props) => {
  const splitApps: Array<IosApp[]> = chunk(appsList, 2);

  return (
    <div className="ios-apps-pack">
      <Link
        to={`${urls.iosCollection}/${id}`}
        className="ios-apps-pack__link"
      >
        <h4 className="ios-apps-pack__title">{title}</h4>
        <div className={`ios-apps-pack__illustration ios-apps-pack__illustration_${pictureType}`} />
      </Link>
      <div className="ios-apps-pack__container">
        {splitApps.map((appColumn) => (
          <div key={appColumn[0]!.name} className="ios-apps-pack__column">
            {appColumn.map((item) => (
              <IosPackCard
                key={item.id}
                iosApp={item}
                collectionName={collectionName}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IosAppsPack;
