import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { AnimatedLogo } from '@setapp/ui-kit';

import classnames from 'classnames';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import Footer from 'components/layout/footer/footer';
import PricePlansRow from 'components/shared/price-plans-row/price-plans-row';
import InfoButton from 'components/shared/info-button/info-button';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { User } from 'state/user/user-initial-state';

import { useNavigation } from 'components/navigation';
import giftIcon from './images/icons-gift.svg';

import './choose-plan-screen.scss';

type Props = {
  user: User;
  isNewUser: boolean;
  isReferralUser: boolean;
  switchedToAnnual: boolean;
  activeMacDevicesCount: number;
  onChangePlanPeriod: () => void;
  onSelectPlanClick: (pricePlan: PricePlan) => void;
};

const ChoosePlanScreen = (props: Props) => {
  const {
    user,
    isNewUser,
    isReferralUser,
    switchedToAnnual,
    activeMacDevicesCount,
    onChangePlanPeriod,
    onSelectPlanClick,
  } = props;
  const { accountCurrency, isSetappMobileAvailable } = user;
  const { isEmbedded } = useNavigation();

  const description = (
    <>
      {accountCurrency === 'EUR' && (
        <div className="mb-2">
          <FormattedMessage
            id="activatePage.choosePlan.vatNotice"
            defaultMessage="Price includes estimated VAT for your location."
          />
        </div>
      )}
      {accountCurrency === 'USD' && isSetappMobileAvailable && (
        <div className="mb-2">
          <FormattedMessage
            id="activatePage.choosePlan.vatUsdNotice"
            defaultMessage="Tax may apply, depending on your country of residence."
          />
        </div>
      )}
      {isNewUser && (
        <div className="mb-10">
          <FormattedHTMLMessage
            id="activatePage.choosePlan.moneyChargeWarning"
            defaultMessage="By completing this step, you are abandoning your trial and beginning your Setapp subscription immediately.</br>You will be charged right away."
          />
        </div>
      )}
      {isReferralUser && (
        <div>
          <img className="choose-plan__referral-icon" src={giftIcon} width="24" height="24" alt="" />
          <FormattedMessage
            id="activatePage.choosePlan.freeMonthHint"
            defaultMessage="Get +1 free month"
          />
          &nbsp;
          <InfoButton>
            <FormattedMessage
              id="activatePage.choosePlan.freeMonthTooltip"
              defaultMessage="Start your subscription with Setapp, and your first month is on us."
            />
          </InfoButton>
        </div>
      )}
    </>
  );

  return (
    <FullscreenLayout>
      <div className="choose-plan">
        <div className={classnames('choose-plan__container', { 'choose-plan__container-embedded': isEmbedded })}>
        <div className="choose-plan__content">
          {
            !isEmbedded && (
              <div className="choose-plan__setapp-logo">
                <AnimatedLogo hasCaption size="sm" />
              </div>
            )
          }
          <PricePlansRow
            switchedToAnnual={switchedToAnnual}
            description={description}
            onChangePlanPeriod={onChangePlanPeriod}
            onSelectPlanClick={onSelectPlanClick}
            activeMacDevicesCount={activeMacDevicesCount}
          />
        </div>
        </div>
        {!isEmbedded ? <Footer /> : null}
      </div>
    </FullscreenLayout>
  );
};

export default ChoosePlanScreen;
