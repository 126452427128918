import React from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  defineMessages,
} from 'react-intl';
import ReCaptcha from 'react-google-recaptcha';
import { FormField } from '@setapp/ui-kit';

import type { ElementRef, KeyboardEvent, ReactNode, SyntheticEvent } from 'react';
import type { InjectedIntlProps } from 'react-intl';

import { Link } from 'components/navigation';
import OuterForm from 'components/shared/outer-form/outer-form';
import CaptchaField from 'components/shared/form/captcha-field/captcha-field';


const messages = defineMessages({
  formTitle: {
    id: 'outer.recoverPassword.title',
    defaultMessage: 'Password recovery',
  },
  formSubTitle: {
    id: 'outer.recoverPassword.subTitle',
    defaultMessage: 'Enter your email address and we\'ll send you a link to reset your password.',
  },
  successTitle: {
    id: 'outer.recoverPassword.titleSuccess',
    defaultMessage: 'Alright, all done.',
  },
  emailLabel: {
    id: 'outer.recoverPassword.emailLabel',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'outer.recoverPassword.emailPlaceholder',
    defaultMessage: 'Enter your email',
  },
  submitBtnCaption: {
    id: 'outer.recoverPassword.submitBtnCaption',
    defaultMessage: 'Reset password',
  },
});

type Props = {
  onFormSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
  onEmailChange: (e: KeyboardEvent<HTMLInputElement>) => void;
  isRequestProcessing: boolean;
  isRequestConfirmed: boolean;
  previousPage: string;
  emailError: ReactNode;
  genericError: ReactNode;
  email: string;
  showCaptcha: boolean;
  onCaptchaChange: (captcha: string | null) => void;
  setCaptchaRef: (captchaRef: ElementRef<typeof ReCaptcha>) => void;
  captchaError: ReactNode;
} & InjectedIntlProps;

const RecoverPasswordForm = (props: Props) => {
  const {
    onFormSubmit,
    genericError,
    emailError,
    onEmailChange,
    isRequestProcessing,
    isRequestConfirmed,
    email,
    previousPage,
    intl,
    showCaptcha,
    onCaptchaChange,
    captchaError,
    setCaptchaRef,
  } = props;

  const title = isRequestConfirmed
    ? intl.formatMessage(messages.successTitle)
    : intl.formatMessage(messages.formTitle);
  const subTitle = isRequestConfirmed
    ? <FormattedHTMLMessage
      id="outer.recoverPassword.subTitleSuccess"
      defaultMessage="The link to reset your password is on its way<br/>to <strong>{email}</strong> if we have an account registered with this email."
      values={{ email }}
      />
    : intl.formatMessage(messages.formSubTitle);

  return (
    <OuterForm
      title={title}
      subTitle={subTitle}
      submitBtnCaption={intl.formatMessage(messages.submitBtnCaption)}
      footer={(
        <p className="text-center">
          <Link className="link-outer" to={previousPage}>
            <FormattedMessage id="outer.recoverPassword.backToPreviousPage" defaultMessage="Go back" />
          </Link>
        </p>
      )}
      onSubmit={onFormSubmit}
      errorMessage={genericError}
      isSubmitProcessing={isRequestProcessing}
      hideSubmit={isRequestConfirmed}
      qaLabel="forgotPasswordForm"
    >
      {!isRequestConfirmed && (
        <FormField
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          label={intl.formatMessage(messages.emailLabel)}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          value={email}
          helpText={emailError}
          invalid={Boolean(emailError)}
          onChange={onEmailChange}
          autoFocus
        />
      )}
      {!isRequestConfirmed && showCaptcha && (
        <CaptchaField onChange={onCaptchaChange} errorMessage={captchaError} setCaptchaRef={setCaptchaRef} />
      )}
    </OuterForm>
  );
};

export { RecoverPasswordForm as PureRecoverPasswordForm };

export default injectIntl(RecoverPasswordForm);
