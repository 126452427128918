import React from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import { useWizardEmit } from '@mwkt/react-magic-frame';
import useIsAllowedPath from 'components/hooks/use-is-allowed-path/use-is-allowed-path';
import useGetPathnameFromTo from 'components/hooks/use-get-pathname-from-to/use-get-pathname-from-to';
import { MagicFrameAction, NavigateEventData } from './constants';
import { useNavigation } from './navigation';

const Link: React.FC<LinkProps> = ({  children, to, onClick, ...props }) => {
  const { isEmbedded } = useNavigation();
  const pathname = useGetPathnameFromTo({ to });

  const emitNavigateEvent = useWizardEmit<NavigateEventData>({
    event: MagicFrameAction.Navigate,
    skip: !isEmbedded,
  });

  const { isAllowedPath } = useIsAllowedPath(pathname);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    emitNavigateEvent({ path: pathname, isAllowed: isAllowedPath });

    onClick?.(event);
  };

  const finalHref = isEmbedded ? (isAllowedPath ? to : '#') : to;

  return (
    <ReactRouterLink {...props} to={finalHref} onClick={handleClick}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;
