import { match } from 'path-to-regexp';
import { useNavigation } from 'components/navigation';

const useIsAllowedPath = (to): { isAllowedPath: boolean } => {
  const { allowedPaths } = useNavigation();
  const isAllowedPath = allowedPaths.some((allowedPath) => match(allowedPath)(to as string));

  return {
    isAllowedPath
  };
};

export default useIsAllowedPath;
