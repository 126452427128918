// FIXME: add tests
/* istanbul ignore file */

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonLoading } from '@setapp/ui-kit';
import { useHistory } from 'components/navigation';

import CAMPAIGNS from 'config/campaigns';
import urls from 'config/urls';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import {
  getDisplayedPricePlan,
  isPaymentMethodCreated as isPaymentMethodCreatedSelector,
  isPaymentMethodFetched as isPaymentMethodFetchedSelector,
} from 'state/root-reducer';
import { retryCharge } from 'state/subscription/subscription-actions';
import type { SpecialOfferCampaign } from 'state/user/campaign-types';

import usePaymentWaiting from 'components/hooks/use-payment-waiting/use-payment-waiting';

import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';

import DefaultError from 'components/shared/default-error/default-error';
import ErrorMessage from 'components/shared/error-message/error-message';
import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';

import SpecialOfferDetails from './special-offer-details/special-offer-details';

type Props = {
  campaign: SpecialOfferCampaign;
};

const SignupSpecialOfferFlow = (props: Props) => {
  const { campaign } = props;
  const campaignConfig = CAMPAIGNS[campaign];

  const history = useHistory();
  const dispatch = useDispatch();

  const pricePlan = useSelector(getDisplayedPricePlan);
  const isPaymentMethodCreated = useSelector(isPaymentMethodCreatedSelector);
  const isPaymentMethodFetched = useSelector(isPaymentMethodFetchedSelector);

  // we need a reference to avoid a closure in the useEffect hook
  const isPaymentMethodCreatedRef = useRef<boolean>(false);
  isPaymentMethodCreatedRef.current = isPaymentMethodCreated;

  const [isPaymentMethodAdded, setIsPaymentMethodAdded] = useState(false);
  const [isPendingPayment, setIsPendingPayment] = useState(false);
  const [isFailedPayment, setIsFailedPayment] = useState(false);
  const [isUserAlreadyHavePaymentMethod, setIsUserAlreadyHavePaymentMethod] = useState(false);

  const [loadingText, setLoadingText] = useState(
    <FormattedMessage
      id="signup.specialOffer.paymentForm.loadingText"
      defaultMessage="Payment in progress…"
    />
  );

  useEffect(() => {
    (async () => {
      try {
        // we need to check if the payment method is added or not, to use right method to create a payment method
        // on first try we need to use add method, on try to pay again case we need to use update method
        if (!isPaymentMethodFetched) {
          await dispatch(fetchPaymentMethod());
        }

        // store init value of isPaymentMethodCreated
        setIsUserAlreadyHavePaymentMethod(isPaymentMethodCreatedRef.current);
      } catch {
        dispatch(showDangerNotification(<DefaultError />));
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccessPayment = () => {
    history.push(urls.successfulRegistration);
  };
  const handleFailedPayment = () => {
    setIsFailedPayment(true);
  };

  const { waitForPaymentSuccess } = usePaymentWaiting({
    timeoutDelay: 5000,
    onSuccessPayment: handleSuccessPayment,
    onFailedPayment: handleFailedPayment,
    onPendingPayment: () => {
      setLoadingText(
        <FormattedMessage
          id="signup.specialOffer.paymentForm.loadingTextSecondTry"
          defaultMessage="Almost there…"
        />
      );
    },
  });

  const { waitForPaymentSuccess: waitForPaymentSuccessSecondTry } = usePaymentWaiting({
    timeoutDelay: 15000,
    onSuccessPayment: handleSuccessPayment,
    onFailedPayment: handleFailedPayment,
    onPendingPayment: () => {
      setLoadingText(
        <FormattedMessage
          id="signup.specialOffer.paymentForm.loadingTextThirdTry"
          defaultMessage="A little more time…"
        />
      );
    },
  });

  const { waitForPaymentSuccess: waitForPaymentSuccessThirdTry } = usePaymentWaiting({
    timeoutDelay: 30000,
    onSuccessPayment: handleSuccessPayment,
    onFailedPayment: handleFailedPayment,
    onPendingPayment: () => {
      setIsPendingPayment(true);
    },
  });

  const handlePaymentDetailsAdded = async () => {
    setIsPaymentMethodAdded(true);

    // On first try after adding payment details BE automatically start a payment.
    // For try to pay again case we need to manually retry a charge
    if (isUserAlreadyHavePaymentMethod) {
      try {
        await dispatch(retryCharge());
      } catch {
        dispatch(showDangerNotification(<DefaultError />));
      }
    }

    // TODO: move this to separate hook?
    // start promises simultaneously, don't need to use `await` here
    waitForPaymentSuccess();
    waitForPaymentSuccessSecondTry();
    waitForPaymentSuccessThirdTry();
  };

  const handleTryAgain = () => {
    // redirect user to main page, if subscription is not active user will be redirected
    // to this page again to update payment method and try to pay again
    history.push(urls.subscription);
  };

  if (!pricePlan || !isPaymentMethodFetched) {
    return <AppLayoutLoading />;
  }

  const submitText = (
    <FormattedMessage
      id="signup.specialOffer.paymentForm.submitBtnTitle"
      defaultMessage="Pay now"
    />
  );

  const renderContent = () => {
    if (!isPaymentMethodAdded) {
      return (
        <PaymentForm
          onSuccessSubmit={handlePaymentDetailsAdded}
          braintreeFormOptions={{
            submitBtnTitle: submitText,
            loadingText,
          }}
          paymentMethodOptions={{
            amount: campaignConfig.price,
            currencyCode: 'USD',
          }}
        />
      );
    }

    if (isPendingPayment || isFailedPayment) {
      return (
        <>
          <Button block onClick={handleTryAgain}>
            <FormattedMessage
              id="signup.specialOffer.tryAgainButton"
              defaultMessage="Try again"
            />
          </Button>
          <ErrorMessage className="mt-4">
            <FormattedMessage
              id="signup.specialOffer.paymentError"
              defaultMessage="Sorry, we’re having trouble processing your payment"
            />
          </ErrorMessage>
        </>
      );
    }

    return (
      <ButtonLoading
        block
        disabled
        isLoading
        loadingText={loadingText}
      >
        {submitText}
      </ButtonLoading>
    );
  };

  return (
    <div className="signup-payment-details-form__container">
      <div className="signup-payment-details-form">
        <h3 className="mb-6">
          <FormattedMessage
            id="signup.specialOffer.paymentForm.title"
            defaultMessage="Add your payment info"
          />
        </h3>

        <div className="mb-6">
          <SpecialOfferDetails campaign={campaign} campaignConfig={campaignConfig} />
        </div>

        {renderContent()}

        <div className="mt-5 mb-2">
          <NextPaymentDateEstimation
            price={pricePlan.price}
            currency={pricePlan.currency}
            paidMonth={pricePlan.paidMonth}
          />
        </div>

        <div className="mt-5">
          <TaxInfo />
        </div>
      </div>
    </div>
  );
};

export default SignupSpecialOfferFlow;
