import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { createSelector } from '@reduxjs/toolkit';
import isAfter from 'date-fns/isAfter';

import { mapDevicesToSeats } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';
import {
  isEduPricePlan,
  isIosOnlyPricePlan,
  isMembershipPriceIncreasePlan,
} from 'services/price-plans/utils';
import * as subscriptionsListService from 'services/subscriptions/subscriptions-list';
import * as subscriptionService from 'services/subscriptions/subscription';

import type { State } from './state-types';

import user, * as userSelectors from './user/user-reducer';
import * as subscriptionStatuses from './subscription/statuses';
import subscriptions, * as subscriptionSelectors from './subscription/subscription-reducer';
import paymentMethod, * as paymentMethodSelectors from './payment-method/payment-method-reducer';
import notifier, * as notifierSelectors from './notifier/notifier-reducer';
import devices, * as devicesSelectors from './devices/devices-reducer';
import desktopApp, * as desktopAppReducer from './desktop-app/desktop-app-reducer';
import referrals from './referrals/referrals-reducer';
import invoices, * as invoicesSelectors from './invoices/invoices-reducer';
import apps, * as appsSelectors from './apps/apps-reducer';
import pricePlans, * as pricePlansSelectors from './price-plans/price-plans-reducer';
import modalReducer from './modal/modal-reducer';
import * as pricePlansTypes from './price-plans/price-plans-types';
import giftCards, * as giftCardsSelectors from './gift-cards/gift-cards-reducer';
import familyPlan, * as familyPlanSelectors from './family-plan/family-plan-reducer';
import featureFlags, * as featureFlagsSelectors from './feature-flags/feature-flags-reducer';
import iosApps from './ios-apps/ios-apps-reducer';
import redeemCode, * as redeemCodeSelectors from './redeem-code/redeem-code-reducer';
import trialStatus from './trial-status/trial-status-reducer';

const DEFAULT_TRIAL_LENGTH = 7;
const DEFAULT_MONTHLY_PRICE = 9.99;
// TODO: refactor when API for apps count is released
const APPS_COUNT = 240;

export default combineReducers({
  apps,
  iosApps,
  user,
  subscriptions,
  paymentMethod,
  notifier,
  devices,
  desktopApp,
  referrals,
  pricePlans,
  invoices,
  giftCards,
  redeemCode,
  familyPlan,
  featureFlags,
  modal: modalReducer,
  intl: intlReducer,
  trialStatus,
});

// Feature Flags
export const getFeatureFlagsState = (state: State) => state.featureFlags;
export const getFeatureFlags = createSelector(
  getFeatureFlagsState,
  featureFlagsSelectors.getFeatureFlags,
);
export const getIsFeatureFlagsLoading = createSelector(
  getFeatureFlagsState,
  featureFlagsSelectors.getIsFeatureFlagsLoading,
);

// User
export const getUser = (state: State) => state.user;
export const getUserEmail = createSelector(getUser, userSelectors.getEmail);
export const getUserId = createSelector(getUser, userSelectors.getId);
export const getCurrentFamilyMember = createSelector(getUser, userSelectors.getCurrentFamilyMember);
export const isUserAuthenticated = createSelector(getUser, userSelectors.isAuthenticated);
export const isUserWithRequiredPaymentDetails = createSelector(getUser, userSelectors.isPaymentInfoRequired);
export const isFreeUser = createSelector(getUser, userSelectors.isFree);
export const isUsersEmailConfirmed = createSelector(getUser, userSelectors.isEmailConfirmed);
export const isEduUserWithInvalidEmail = createSelector(getUser, userSelectors.isEduUserWithInvalidEmail);
export const isReferralUser = createSelector(getUser, userSelectors.isReferralUser);
export const isUpsaleFlow = createSelector(getUser, userSelectors.isUpsaleFlow);
export const isAppLandingFlow = createSelector(getUser, userSelectors.isAppLandingFlow);
export const canUserSwitchToEduPlan = createSelector(getUser, userSelectors.canSwitchToEduPlan);
export const isUserRegistrationCompleted = createSelector(getUser, userSelectors.isRegistrationCompleted);
export const isUserPlainFamilyMember = createSelector(getUser, userSelectors.isPlainFamilyMember);
export const isUserFamilyMember = createSelector(getUser, userSelectors.isFamilyMember);
export const isUserFamilyOwner = createSelector(getUser, userSelectors.isFamilyOwner);
export const isSpecialOfferCampaign = createSelector(getUser, userSelectors.isSpecialOfferCampaign);
export const hasPriceFeatures = createSelector(getUser, userSelectors.hasPriceFeatures);
export const getUserSignupGiftCardCode = createSelector(getUser, userSelectors.getSignupGiftCardCode);
export const getUserSignupPromoCode = createSelector(getUser, userSelectors.getSignupPromoCode);
export const canUseReferralProgram = createSelector(getUser, userSelectors.canUseReferralProgram);
export const getUserAiCredits = createSelector(getUser, userSelectors.getAiCredits);
export const getIsSetappMobileAvailable = createSelector(getUser, userSelectors.getIsSetappMobileAvailable);
export const getShowEstimatedVatIncludedWarning = createSelector(
  getUser,
  userSelectors.getShowEstimatedVatIncludedWarning
);
export const isUserMarketingAffiliate = createSelector(getUser, userSelectors.isMarketingAffiliate);

// Subscription
export const getSubscriptions = (state: State) => state.subscriptions;
export const getSubscriptionsList = createSelector(getSubscriptions, subscriptionSelectors.getList);
export const isSubscriptionsFetched = createSelector(getSubscriptions, subscriptionSelectors.isFetched);
export const getPrimarySubscription = createSelector(getSubscriptions, subscriptionSelectors.getPrimary);
export const getAdditionalSeatsSubscriptionInfo = createSelector(
  getSubscriptions,
  subscriptionSelectors.getAdditionalSeatsInfo
);
export const getAllAdditionalSubscriptions = createSelector(getSubscriptions, subscriptionSelectors.getAllAdditional);
export const getAllActiveAdditionalSubscriptions = createSelector(
  getSubscriptions,
  subscriptionSelectors.getAllActiveAdditional
);
export const getFirstAdditionalSubscription = (state: State) => getAllActiveAdditionalSubscriptions(state)[0];

export const isChangePlanAllowed = createSelector(
  getSubscriptions,
  getUser,
  (subscriptions, user) => subscriptionSelectors.isPrimaryPlanChangeAllowed(subscriptions)
    && !userSelectors.isFamilyMember(user),
);

export const isManageSubscriptionAllowed = createSelector(
  getSubscriptions,
  getUser,
  (subscriptions, user) => subscriptionSelectors.isPrimaryPlanManageAllowed(subscriptions)
    && !userSelectors.isPlainFamilyMember(user),
);

export const isCancelSubscriptionAllowed = createSelector(
  getSubscriptions,
  getUser,
  hasPriceFeatures,
  (subscriptions, user, hasPriceFeatures) => {
    const isCancelSubscriptionAllowed = subscriptionSelectors.isCancelPrimaryAllowed(subscriptions);
    const isFamilyMember = userSelectors.isFamilyMember(user);
    const isFamilyOwner = userSelectors.isFamilyOwner(user);

    // Old price plans
    if (!hasPriceFeatures) {
      return isCancelSubscriptionAllowed && !isFamilyMember;
    }

    // New family price plans
    if (isFamilyMember) {
      return isCancelSubscriptionAllowed && isFamilyOwner;
    }

    // Other new price plans
    return isCancelSubscriptionAllowed;
  },
);

export const isActivateSubscriptionAllowed = createSelector(
  getPrimarySubscription,
  getUser,
  (primarySubscription, user) => (
    primarySubscription != null
    && subscriptionService.isActivationAllowed(primarySubscription)
    && !userSelectors.isPlainFamilyMember(user)
  ),
);

export const isInactiveSubscription = createSelector(getSubscriptions, subscriptionSelectors.isPrimaryInactive);
export const isSubscriptionTrial = createSelector(getSubscriptions, subscriptionSelectors.isPrimaryTrial);
export const isSubscriptionNew = createSelector(getSubscriptions, subscriptionSelectors.isPrimaryNew);
export const isSubscriptionFree = createSelector(getSubscriptions, subscriptionSelectors.isPrimaryFree);
export const isSubscriptionBlocked = createSelector(getSubscriptions, subscriptionSelectors.isPrimaryBlocked);

export const isUsersCurrentPlanMonthly = createSelector(
  getSubscriptions,
  subscriptionSelectors.isPrimaryPlanMonthly
);

export const isNextPricePlanAnnual = createSelector(
  getSubscriptions,
  subscriptionSelectors.isNextPrimaryPlanAnnual,
);

export const showSwitchToAnnualNotification = createSelector(
  [isChangePlanAllowed, isUsersCurrentPlanMonthly, isNextPricePlanAnnual],
  (isChangePlanAllowed, isCurrentPlanMonthly, isNextPricePlanAnnual) => (
    isChangePlanAllowed && isCurrentPlanMonthly && !isNextPricePlanAnnual
  )
);

export const getBasicPlanSeatsCount = createSelector(getSubscriptions, subscriptionSelectors.getPrimarySeatsCount);
export const getAllAvailableSeatsCount = createSelector(
  getSubscriptions,
  subscriptionSelectors.getMaxAvailableSeatsNumber
);
export const getPurchasedSeatsCount = createSelector(getSubscriptionsList, subscriptionSelectors.getTotalSeatsNumber);
export const isLastAdditionalSeatPaymentFailed = createSelector(
  getAllAdditionalSubscriptions,
  subscriptionSelectors.isLastAdditionalSeatPaymentFailed
);
export const getPrimaryPricePlanFeatures =  createSelector(
  getSubscriptions,
  subscriptionSelectors.getPrimaryFeatures
);

export const getExtraSeatsCount = (state: State) => getAllAvailableSeatsCount(state) - getBasicPlanSeatsCount(state);
export const isAllAvailableSeatsPurchased = (state: State) => (
  getAllAvailableSeatsCount(state) <= getPurchasedSeatsCount(state)
);

export const getPrimaryPricePlan = createSelector(
  getSubscriptions,
  subscriptionSelectors.getPrimaryPricePlan,
);

export const isIosOnlyUser = createSelector(
  getPrimaryPricePlan,
  (pricePlan) => pricePlan && isIosOnlyPricePlan(pricePlan.tierType)
);

export const hasIosSeats = createSelector(
  getPrimaryPricePlan,
  (pricePlan) => (pricePlan?.features?.iosSeatsCount
    ? pricePlan.features.iosSeatsCount > 0 : false)
);

export const hasTrialSeats = createSelector(
  getPrimarySubscription,
  (primarySubscription) => primarySubscription && subscriptionService.hasTrialSeats(primarySubscription)
);

export const getDefaultTrialLength = () => DEFAULT_TRIAL_LENGTH;
export const getDefaultMonthlyPrice = () => DEFAULT_MONTHLY_PRICE;
export const getTrialLength = (state: State) => state.user.trialPeriodLength || getDefaultTrialLength();

export const isSuspendedSubscription = createSelector(
  [getPrimarySubscription, hasPriceFeatures],
  (primarySubscription, hasPriceFeatures) => (
    hasPriceFeatures
    && primarySubscription != null
    && subscriptionService.isSuspendedSubscription(primarySubscription)
  ),
);

export const isSuspendedSubscriptionWithOldPricePlan = createSelector(
  [getPrimarySubscription, hasPriceFeatures],
  (primarySubscription, hasPriceFeatures) => (
    !hasPriceFeatures
    && primarySubscription != null
    && subscriptionService.isSuspendedSubscriptionWithOldPricePlan(primarySubscription)
  ),
);

export const hasSubscriptionHiddenPricePlan = createSelector(
  [getPrimarySubscription, hasPriceFeatures],
  (primarySubscription, hasPriceFeatures) => (
    primarySubscription !== undefined
    && hasPriceFeatures
    && subscriptionService.hasSubscriptionHiddenPricePlan(primarySubscription)
  ),
);

export const isSubscriptionWithDiscount = createSelector(
  getPrimarySubscription,
  (primarySubscription) => (
    primarySubscription !== undefined
    && Boolean(primarySubscription.discount)
  ),
);

export const isEduSubscription = createSelector(
  [getPrimarySubscription, hasPriceFeatures],
  (primarySubscription) => {
    if (!primarySubscription) {
      return false;
    }

    return isEduPricePlan(primarySubscription.nextPricePlan || primarySubscription.pricePlan);
  },
);

const isSubscriptionExpired = createSelector(
  getPrimarySubscription,
  (primarySubscription) => {
    if (!primarySubscription) {
      return false;
    }

    const { expirationDate } = primarySubscription;

    return expirationDate != null && isAfter(Date.now(), expirationDate * 1000);
  }
);

export const isSubscriptionCancelledAndActive = createSelector(
  [getPrimarySubscription, isSubscriptionExpired],
  (primarySubscription, isExpired) => (
    primarySubscription != null
    && primarySubscription.status === subscriptionStatuses.CANCELLED
    && !isExpired
  ),
);

export const isSubscriptionCancelledAndInactive = createSelector(
  [getPrimarySubscription, isSubscriptionExpired],
  (primarySubscription, isExpired) => (
    primarySubscription != null
    && primarySubscription.status === subscriptionStatuses.CANCELLED
    && isExpired
  ),
);

// Payment method
export const getPaymentMethod = (state: State) => state.paymentMethod;
export const getBusinessAccountDetails = createSelector(
  getPaymentMethod,
  paymentMethodSelectors.getBusinessAccountDetails
);
export const isPaymentMethodCreated = createSelector(getPaymentMethod, paymentMethodSelectors.isCreated);
export const isPaymentMethodFetched = createSelector(getPaymentMethod, paymentMethodSelectors.isFetched);
export const isPaymentDetailsLoading = createSelector(
  getPaymentMethod,
  (paymentDetails) => paymentDetails.isLoading,
);
export const creditCardExpiration = (state: State) => paymentMethodSelectors
  .creditCardExpiration(getPaymentMethod(state));
export const canRemovePaymentDetails = createSelector(
  [isInactiveSubscription, isPaymentMethodCreated, getPrimarySubscription],
  (
    isInactiveSubscription,
    isPaymentMethodCreated,
    primarySubscription
  ) => isInactiveSubscription && isPaymentMethodCreated && !primarySubscription?.paymentPending
);

// Price plans
export const getPricePlans = (state: State) => state.pricePlans;
export const getPricePlansList = createSelector(getPricePlans, pricePlansSelectors.getList);
export const getActivePricePlanByType = (state: State, type: string) => (
  pricePlansSelectors.getFirstActiveByType(getPricePlans(state), type)
);
export const getAdditionalSeatPlan = (state: State) => getActivePricePlanByType(
  state,
  pricePlansTypes.ADDITIONAL_SEAT_MONTHLY
);
export const getMonthlyPlan = (state: State) => getActivePricePlanByType(state, pricePlansTypes.MONTHLY);
export const getPricePlansGroup = (state: State) => {
  const primarySubscription = getPrimarySubscription(state);

  return primarySubscription ? primarySubscription.pricePlan.group : null;
};
export const getActivePricePlansByGroup = createSelector(
  getPricePlans,
  getPricePlansGroup,
  pricePlansSelectors.getActiveByGroup,
);
export const getIosPricePlans = createSelector(getPricePlans, pricePlansSelectors.getIos);
export const getAvailablePricePlans = createSelector(
  [isIosOnlyUser, getIosPricePlans, getActivePricePlansByGroup, hasPriceFeatures],
  (isIosOnlyUser, iosPricePlans, activePricePlansByGroup, hasPriceFeatures) => {
    const availablePricePlans = pricePlansSelectors.getAvailableByFeatures(
      activePricePlansByGroup, hasPriceFeatures
    );

    if (isIosOnlyUser) {
      return availablePricePlans;
    }

    const iosTierTypes = iosPricePlans.map((plan) => plan.tierType);

    return availablePricePlans.filter((plan) => !iosTierTypes.includes(plan.tierType));
  }
);
export const getFeaturedPricePlans = createSelector(getPricePlans, pricePlansSelectors.getFeatured);
export const getDisplayedPricePlan = createSelector(getPrimarySubscription, pricePlansSelectors.getDisplayed);

// Family Plan
export const getFamilyPricePlans = createSelector(getPricePlans, pricePlansSelectors.getFamilyPlans);

// Notifications
export const getNotifier = (state: State) => state.notifier;
export const getNotification = createSelector(getNotifier, notifierSelectors.getList);

// Devices
export const getDevices = (state: State) => state.devices;
export const getDevicesList = createSelector(getDevices, devicesSelectors.getList);
export const getMacDevicesList = createSelector(getDevices, devicesSelectors.getMacList);

export const isAdditionalSeatPurchaseAvailable = (state: State) => {
  const primarySubscription = getPrimarySubscription(state);
  const isPrimaryStatusActive = Boolean(primarySubscription && subscriptionService.isStatusActive(primarySubscription));

  return !isFreeUser(state) && isPrimaryStatusActive && !isUserPlainFamilyMember(state) && !hasTrialSeats(state);
};

export const getDevicesInSeat = createSelector(
  [getSubscriptionsList, getDevicesList, hasPriceFeatures],
  mapDevicesToSeats,
);

export const isAdditionalSeatPaymentPending = createSelector(
  getAllAdditionalSubscriptions,
  subscriptionsListService.isAdditionalSeatPaymentPending
);

export const getAdditionalSeatsPaymentPendingCount = createSelector(
  getAllAdditionalSubscriptions,
  subscriptionsListService.getAdditionalSeatsPaymentPendingCount
);

export const getPaymentFailedSeatsSubscriptions = createSelector(
  getAllAdditionalSubscriptions,
  subscriptionsListService.getPaymentFailedSeatsSubscriptions
);

// Desktop App
export const hasDesktopAppAlreadyDetected = (state: State) => desktopAppReducer.hasAlreadyDetected(state.desktopApp);
export const isDesktopAppAvailable = (state: State) => desktopAppReducer.isAvailable(state.desktopApp);
export const isDesktopAppDetectionInProgress = (state: State) => (
  desktopAppReducer.isDetectionInProgress(state.desktopApp)
);

// Invoices
export const getInvoices = (state: State) => state.invoices;
export const getInvoicesList = createSelector(getInvoices, invoicesSelectors.getList);

// Referrals
export const getReferrals = (state: State) => state.referrals;

// Applications
export const getApps = (state: State) => state.apps;
export const getAppsList = createSelector(getApps, appsSelectors.getList);
export const getAppsCount = () => APPS_COUNT;

// iOS Applications
export const getIosApps = (state: State) => state.iosApps;

// Gift cards
export const getGiftCardTypes = (state: State) => giftCardsSelectors.getCardTypes(state.giftCards);
export const getGiftCardTypesList = (state: State) => giftCardsSelectors.getCardTypesList(state.giftCards);
export const getGiftCards = (state: State) => giftCardsSelectors.getGiftCards(state.giftCards);
export const getGiftCardsList = (state: State) => giftCardsSelectors.getGiftCardsSortedList(state.giftCards);

// Old Family Plan
export const getFamilyPlan = (state: State) => state.familyPlan;
export const getFamilyMembersExceptCurrent = createSelector(getFamilyPlan, familyPlanSelectors.getMembersExceptCurrent);
export const getFamilyOwner = createSelector(getFamilyPlan, familyPlanSelectors.getOwner);
export const isFamilyFull = createSelector(getFamilyPlan, familyPlanSelectors.isFull);
export const isFamilyFetched = createSelector(getFamilyPlan, familyPlanSelectors.isFetched);
export const isFamilyStatusPending = createSelector(getFamilyPlan, familyPlanSelectors.isPendingStatus);
export const isFamilyStatusActive = createSelector(getFamilyPlan, familyPlanSelectors.isActiveStatus);
export const canUserSwitchToFamily = (state: State) => (!isUserPlainFamilyMember(state) && !isFreeUser(state));
export const availableSeats = createSelector(getFamilyPlan, familyPlanSelectors.availableSeats);

// Redeem Code
export const getRedeemCode = (state: State) => state.redeemCode;
export const isPromoCodeRedeemCode = createSelector(getRedeemCode, redeemCodeSelectors.isPromoCodeRedeemCode);
export const isGiftCardRedeemCode = createSelector(getRedeemCode, redeemCodeSelectors.isGiftCardRedeemCode);

// Modal
export const getModalState = (state: State) => state.modal;

export const isMembershipPriceIncreaseEnabled
  = createSelector(getFeatureFlags, getPrimaryPricePlan, (featureFlags, primaryPlan) => {
    const isPriceIncreaseEnabled = Boolean(featureFlags.membershipPriceIncrease.value);
    const isAllowedPlan = primaryPlan && isMembershipPriceIncreasePlan(primaryPlan.tierType);
    const isMembershipIncreasePlan = Boolean(primaryPlan?.priceKey.includes(':pricing2024'));

    const isEnabled = isAllowedPlan && isMembershipIncreasePlan;
    const percentage = isEnabled ? '24%' : '10%';

    return {
      membershipPriceIncrease: isPriceIncreaseEnabled && isEnabled,
      percentage
    };
  });
