import merge from 'deepmerge';
import HTTPStatus from 'http-status';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RequestError from '@setapp/request-error';

// TODO: extract to a separate file when decide how to camelize response body using a middleware
const decamelizeBodyKeysMiddleware = (next) => (url, options) => {
  const body = options.body && JSON.stringify(decamelizeKeys(options.body));

  return next(url, {
    ...options,
    body,
  });
};

let requestMiddleware = [
  decamelizeBodyKeysMiddleware,
];
let errorCallback;

function checkStatus(response) {
  if (!response.ok) {
    return Promise.reject(response);
  }

  return Promise.resolve(response);
}

function getJSON(response) {
  if (response.status === HTTPStatus.NO_CONTENT || response.status === HTTPStatus.CREATED) {
    return Promise.resolve({}); // TODO: resolve with null
  }

  return response.json()
    .catch(() => Promise.reject('Invalid response format')); // TODO: logger.logError
}

function formatError(response) {
  errorCallback?.(response);

  // Handle TypeError that fetch returns if the browser is offline
  if (!(response instanceof Response)) {
    throw RequestError.create(response);
  }

  return getJSON(response)
    .catch(() => Promise.reject(RequestError.create(response)))
    .then((responseBody) => Promise.reject(RequestError.create(response, responseBody)));
}

function camelizeRules(key, convert) {
  return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
}

export function sendRequest(url, options = {}) {
  const additionalOptions = {
    headers: { // TODO: use Headers
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  };

  const sendRequestWithMiddleware = requestMiddleware
    .reduce((result, applyMiddleware) => applyMiddleware(result), fetch);

  return sendRequestWithMiddleware(url, merge(additionalOptions, options))
    .then(checkStatus)
    .then(getJSON)
    .then((responseJSON) => camelizeKeys(responseJSON.data, camelizeRules)) // TODO: camelize via middleware???
    .catch(formatError);
}

const request = {
  get(url) {
    return sendRequest(url, { method: 'GET' });
  },

  post(url, meta) {
    const data = merge(meta, { method: 'POST' });

    return sendRequest(url, data);
  },

  patch(url, meta) {
    const data = merge(meta, { method: 'PATCH' });

    return sendRequest(url, data);
  },

  put(url, meta) {
    const data = merge(meta, { method: 'PUT' });

    return sendRequest(url, data);
  },

  delete(url) {
    return sendRequest(url, { method: 'DELETE' });
  },

  useMiddleware(middleware) {
    requestMiddleware = requestMiddleware.concat(middleware);
  },

  onError(callback) {
    errorCallback = callback;
  }
};

export default request;
