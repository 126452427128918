import {  useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'state/root-reducer';
import urls from 'config/urls';

import { EMBED_PAGE_PATH, O10R_REDIRECT_EXCEPTION_URLS } from '../constants';

const { O10R_ROOT } = process.env;

if (!O10R_ROOT) {
  throw new Error('O10R_ROOT env variable is not set');
}

const useNavigationGuard = () => {
  const location = useLocation();
  const featureFlags = useSelector(getFeatureFlags);

  const isEmbeddedPath = location.pathname.includes(EMBED_PAGE_PATH);
  const isPageOpenedInIframe = window.self !== window.top;
  const isEmbedded = isEmbeddedPath && isPageOpenedInIframe;

  // Redirect via react router
  let redirectPath: string | undefined;
  // Redirect to outer URL
  let redirectUrl: URL | undefined;

  if (isEmbeddedPath && !isPageOpenedInIframe) {
    redirectPath = location.pathname.replace(EMBED_PAGE_PATH, '');
  }

  if (!redirectPath) {
    const locationState = location.state as { nextLocation?: Location } | undefined;
    const isLoginWithFollowingException = location.pathname === urls.login
      && locationState?.nextLocation
      && O10R_REDIRECT_EXCEPTION_URLS.has(locationState.nextLocation.pathname);

    const shouldRedirectToO10r = featureFlags.o10rReleaseScopeEnabled?.value
      && !isPageOpenedInIframe
      && !O10R_REDIRECT_EXCEPTION_URLS.has(location.pathname)
      && !isLoginWithFollowingException;

    if (shouldRedirectToO10r) {
      redirectUrl = new URL(O10R_ROOT);
      redirectUrl.pathname = location.pathname;
      redirectUrl.search = location.search;
    }
  }

  return {
    isEmbedded,
    redirectPath,
    redirectUrl,
  };
};

export default useNavigationGuard;
