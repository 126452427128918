import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as subscriptionStatuses from 'state/subscription/statuses';

import logger from 'utils/logger';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import {
  getDisplayedPricePlan,
  getPrimarySubscription,
  isPaymentMethodCreated as isPaymentMethodCreatedSelector,
  isPaymentMethodFetched as isPaymentMethodFetchedSelector,
} from 'state/root-reducer';
import { activateSubscription, fetchAllSubscriptions } from 'state/subscription/subscription-actions';

import useMultiplePaymentWaiting from 'components/hooks/use-multiple-payment-waiting/use-multiple-payment-waiting';
import { useNavigation } from 'components/navigation';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import CheckoutPricePlanStep from 'components/user-flow/activate/checkout-price-plan-step/checkout-price-plan-step';
import PaymentErrorStep from 'components/user-flow/shared/payment-error-step/payment-error-step';

import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';
import SubscriptionActivated from 'components/shared/subscription-activated/subscription-activated';

type Step = 'checkoutPricePlanStep'
  | 'paymentErrorStep'
  | 'updatePaymentDetailsStep'
  | 'successActivationStep';

const BACK_STEP_MAPPING: {[key in Step]?: Step} = {
  paymentErrorStep: 'checkoutPricePlanStep',
  updatePaymentDetailsStep: 'checkoutPricePlanStep',
};

const ActivateEduUserFlow = () => {
  const dispatch = useDispatch();
  const { isEmbedded } = useNavigation();

  const primarySubscription = useSelector(getPrimarySubscription);
  const nextPricePlan = useSelector(getDisplayedPricePlan);
  const isPaymentMethodCreated = useSelector(isPaymentMethodCreatedSelector);
  const isPaymentMethodFetched = useSelector(isPaymentMethodFetchedSelector);

  const [step, setStep] = useState<Step>('checkoutPricePlanStep');
  const [isProcessing, setIsProcessing] = useState(false);

  // Fetch necessary data if needed
  useEffect(() => {
    try {
      if (!primarySubscription) {
        dispatch(fetchAllSubscriptions());
      }
      if (!isPaymentMethodFetched) {
        dispatch(fetchPaymentMethod());
      }
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show success step if subscription is already active
  useEffect(() => {
    if (
      primarySubscription
      && primarySubscription.status === subscriptionStatuses.ACTIVE
      && step === 'checkoutPricePlanStep'
    ) {
      setStep('successActivationStep');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primarySubscription]);

  const { waitMultipleForPaymentSuccess } = useMultiplePaymentWaiting({
    onSuccessPayment: () => {
      setIsProcessing(false);
      setStep('successActivationStep');
    },
    onFailedPayment: () => {
      setIsProcessing(false);
      setStep('paymentErrorStep');
    },
  });

  if (!primarySubscription || !isPaymentMethodFetched || !nextPricePlan) {
    return <FullscreenLayoutLoading />;
  }

  const handleCheckoutPricePlan = async () => {
    setIsProcessing(true);

    try {
      await dispatch(activateSubscription({ primaryPricePlanId: nextPricePlan.id }));
      await waitMultipleForPaymentSuccess();
    } catch (error) {
      setIsProcessing(false);

      logger.logError('Activate edu user flow: Error during subscription activation', error);
      dispatch(showDangerNotification(<DefaultError />));
    }
  };

  const handleBackButtonClick = () => {
    const backStep = BACK_STEP_MAPPING[step];

    if (backStep) {
      setStep(backStep);
    }
  };

  const renderBackButton = () => {
    const backStep = BACK_STEP_MAPPING[step];

    if (!backStep) {
      return null;
    }

    return (
      <>
        <div className="mb-5">
          <ButtonBack
            disabled={isProcessing}
            onClick={handleBackButtonClick}
          />
        </div>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 'checkoutPricePlanStep':
        return (
          <CheckoutPricePlanStep
            isPaymentMethodCreated={isPaymentMethodCreated}
            isProcessing={isProcessing}
            showPricePlanPeriodSwitcher={false}
            pricePlan={nextPricePlan}
            gracePeriod={primarySubscription.gracePeriod}
            onCheckoutPricePlan={handleCheckoutPricePlan}
            onPaymentDetailsSaved={handleCheckoutPricePlan}
            onUpdatePaymentDetailsClick={() => setStep('updatePaymentDetailsStep')}
          />
        );
      case 'paymentErrorStep':
        return (
          <PaymentErrorStep
            isProcessing={isProcessing}
            onRetry={() => setStep('checkoutPricePlanStep')}
            onUpdatePaymentDetails={() => setStep('updatePaymentDetailsStep')}
          />
        );
      case 'updatePaymentDetailsStep':
        return (
          <>
            <h3 className="mt-0 mb-3">
              <PaymentDetailsActionText action="update" />
            </h3>
            <PaymentForm
              onSuccessSubmit={() => setStep('checkoutPricePlanStep')}
            />
          </>
        );
      case 'successActivationStep':
        return <SubscriptionActivated />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (step === 'successActivationStep') {
      return renderStep();
    }

    return (
      <FullscreenLayout withLogo={!isEmbedded}>
        <FullscreenLayout.PrimaryContent wideContainer>
          {renderBackButton()}
          {renderStep()}
        </FullscreenLayout.PrimaryContent>
      </FullscreenLayout>
    );
  };

  return renderContent();
};

export default ActivateEduUserFlow;
