import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatedLogo } from '@setapp/ui-kit';

import { getUser, getFeatureFlags } from 'state/root-reducer';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { cancelAllSubscriptions } from 'state/subscription/subscription-actions';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import analytics, { events } from 'utils/analytics';

import { useHistory } from 'components/navigation';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import DefaultError from 'components/shared/default-error/default-error';
import CancelSubscriptionFlow from 'components/user-flow/cancel-subscription/cancel-subscription-flow';
import PauseSubscriptionFlow from 'components/user-flow/pause-subscription/pause-subscription-flow';

import './cancel-subscription-page.scss';

const CancelSubscriptionPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id: userId } = useSelector(getUser);
  const { pauseSubscriptionTest } = useSelector(getFeatureFlags);
  const [showPauseStep, managePauseStep] = useState<boolean>(true);
  const isPauseSubscriptionTest = pauseSubscriptionTest.value && showPauseStep;

  useEffect(() => analytics.trackEvent(events.CANCEL_SUBSCRIPTION_OPEN), []);

  const handleCloseButtonClick = () => history.push(urls.subscription);

  const handleCancelSubscriptionButtonClick = async () => {
    try {
      await dispatch(cancelAllSubscriptions());
    } catch (error) {
      dispatch(showDangerNotification(<DefaultError />));
    }

    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_SUCCESS);
    history.push(urls.successfulCancellation);
  };

  const handlePauseCancelButtonClick = () => managePauseStep(false);
  const handleManagePlanButtonClick = () => history.push(urls.changePricePlan);
  const handleReadHowToSaveMoreButtonClick = () => history.push(urls.referral);
  const handleLookMoreAppsButtonClick = () => window.location.assign(externalUrls.allApps);
  const handleReadWalkthroughButtonClick = () => window.location.assign(externalUrls.howToMasterSetappArticle);

  return (
    <FullscreenLayout>
      <div className="cancel-subscription-page">
        <div className="cancel-subscription-page__logo">
          <AnimatedLogo size="sm" hasCaption />
        </div>

        {isPauseSubscriptionTest && (
          <PauseSubscriptionFlow
            onCloseButtonClick={handleCloseButtonClick}
            onPauseCancelButtonClick={handlePauseCancelButtonClick}
          />
        )}

        {!isPauseSubscriptionTest && (
          <CancelSubscriptionFlow
            userId={userId}
            onCloseButtonClick={handleCloseButtonClick}
            onCancelSubscriptionButtonClick={handleCancelSubscriptionButtonClick}
            onManagePlanButtonClick={handleManagePlanButtonClick}
            onReadHowToSaveMoreButtonClick={handleReadHowToSaveMoreButtonClick}
            onLookMoreAppsButtonClick={handleLookMoreAppsButtonClick}
            onReadWalkthroughButtonClick={handleReadWalkthroughButtonClick}
          />
        )}
      </div>
    </FullscreenLayout>
  );
};

export default CancelSubscriptionPage;
