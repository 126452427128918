import { useDispatch } from 'react-redux';
import { useWizardListener } from '@mwkt/react-magic-frame';

import { LOCALE_ALIASES, NEXT_INTL_LOCALE_CODE_MAP } from 'config/locales';
import { updateLocale } from 'state/user/user-actions';

import { MagicFrameAction } from '../constants';

const useLocaleSync = () => {
  const dispatch = useDispatch();

  useWizardListener({ event: MagicFrameAction.SyncLocale, callback: (data) => {
    const locale = NEXT_INTL_LOCALE_CODE_MAP[data.locale] ?? LOCALE_ALIASES.en;

    dispatch(updateLocale(locale));
  } });
};

export default useLocaleSync;
