import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Redirect } from 'components/navigation';
import {
  getUserId,
  hasPriceFeatures as hasPriceFeaturesSelector,
  isUserFamilyOwner as isUserFamilyOwnerSelector,
  isMembershipPriceIncreaseEnabled,
} from 'state/root-reducer';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchUser } from 'state/user/user-actions';

import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import DefaultError from 'components/shared/default-error/default-error';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import OldFamilyPlanPage from 'components/pages/old-family-plan/old-family-plan-page';
import ManageFamilyPage from 'components/pages/manage-family-page/manage-family-page';
import urls from 'config/urls';

const FamilyPlanPage = () => {
  const isUserFetched = Boolean(useSelector(getUserId));
  const hasPriceFeatures = useSelector(hasPriceFeaturesSelector);
  const isUserFamilyOwner = useSelector(isUserFamilyOwnerSelector);
  const { membershipPriceIncrease } = useSelector(isMembershipPriceIncreaseEnabled);

  const fetchUserAction = useDispatchAction(fetchUser);
  const showDangerNotificationAction = useDispatchAction(showDangerNotification);

  useEffect(() => {
    const dataToBeFetched = [
      !isUserFetched ? fetchUserAction : null,
    ];

    Promise.all(dataToBeFetched.map((action) => action?.())).catch(() => {
      showDangerNotificationAction(<DefaultError />);
    });
  }, []);

  if (!isUserFetched) {
    return <FullscreenLayoutLoading />;
  }

  if (membershipPriceIncrease) {
    return <Redirect to={urls.subscription} />;
  }

  if (hasPriceFeatures && isUserFamilyOwner) {
    return <ManageFamilyPage />;
  }

  if (!hasPriceFeatures) {
    return <OldFamilyPlanPage />;
  }

  return <Redirect to={urls.subscription} />;
};

export default FamilyPlanPage;
