import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'components/navigation';

import PromoBanner from 'components/shared/promo-banner/promo-banner';
import urls from 'config/urls';
import analytics, { events } from 'utils/analytics';
import catImage from './images/cat.svg';


const trackButtonClick = () => {
  analytics.trackEvent(
    events.SUBSCRIPTION_ACTIVATION_MODAL_OPEN,
    {
      eventLabel: 'withPaymentDetails',
      eventLabel2: 'Referree Banner',
      comment: 'Start Subscription',
    },
  );
};

const FreeMonthActivationBanner = () => (
  <PromoBanner
    image={catImage}
    title={
      <FormattedMessage
        id="freeMonthActivationBanner.title"
        defaultMessage="You’re almost there"
      />
    }
    message={
      <FormattedMessage
        id="freeMonthActivationBanner.text"
        defaultMessage="Let’s start your subscription and +1 free month on Setapp is yours."
      />
    }
    buttonText={
      <FormattedMessage
        id="freeMonthActivationBanner.buttonText"
        defaultMessage="Start subscription"
      />
    }
    buttonTag={Link}
    buttonUrl={urls.activateSubscription}
    onButtonClick={trackButtonClick}
  />
);

export default FreeMonthActivationBanner;
