// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import DefaultError from 'components/shared/default-error/default-error';

import { removeQueryParams } from 'utils/location';

import withRouter from 'components/navigation/navigation-with-router';
import Modal from 'components/shared/modal/modal';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import { changePricePlan } from 'state/subscription/subscription-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchDevices, deactivateDevicesDeferred } from 'state/devices/devices-reducer';
import { fetchUser } from 'state/user/user-actions';
import {
  getPrimarySubscription,
  getSubscriptions,
  getAvailablePricePlans,
  getPricePlans,
  getActivePricePlanByType,
  getDevices,
  getDevicesList,
  isInactiveSubscription,
} from 'state/root-reducer';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';

import analytics, { events } from 'utils/analytics';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import PlanChangedNotification from 'components/pages/subscription/components/plan-changed-notification/plan-changed-notification';
import PickActiveDeviceForm from './pick-active-device-form/pick-active-device-form';
import ChangePlanForm from './change-plan-form/change-plan-form';


const mapStateToProps = (state, ownProps) => ({
  mainSubscription: getPrimarySubscription(state),
  // Display next price plan info if user changed plan. It's made by design.
  currentPricePlan: getPrimarySubscription(state)?.nextPricePlan || getPrimarySubscription(state)?.pricePlan,
  pricePlans: getAvailablePricePlans(state),
  isPlansListLoading: getPricePlans(state).isLoading,
  isDevicesListLoading: getDevices(state).isLoading,
  isProcessing: getSubscriptions(state).isLoading,
  devices: getDevicesList(state),
  selectedPlan: getActivePricePlanByType(state, ownProps.selectedPlanType),
  isInactiveSubscription: isInactiveSubscription(state),
});

const mapActionsToProps = {
  fetchPricePlans,
  fetchDevices,
  fetchUser,
  deactivateDevicesDeferred,
  changePricePlan,
  showSuccessNotification,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  onHide: () => void;
  onExited: () => void;
  show: boolean;
} & ConnectedProps<typeof connector> & RouteComponentProps

type State = {
  showPickActiveDevice: boolean;
  selectedPlan: PricePlan;
}

class ChangePlanModal extends Component<Props, State> {
  static defaultProps = {
    selectedPlan: undefined,
    selectedPlanType: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { currentPricePlan, selectedPlan } = props;

    this.state = {
      showPickActiveDevice: false,
      selectedPlan: selectedPlan || currentPricePlan!,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const {
      selectedPlan: newSelectedPlan,
      currentPricePlan,
    } = props;

    const {
      selectedPlan,
    } = state;

    if (!newSelectedPlan) {
      return null;
    }

    const shouldChangeSelectedPlan = !selectedPlan || currentPricePlan === selectedPlan;

    if (shouldChangeSelectedPlan) {
      return {
        selectedPlan: newSelectedPlan,
      };
    }

    return null;
  }

  componentDidMount() {
    const { fetchPricePlans, showDangerNotification } = this.props;

    return fetchPricePlans()
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const {
      show,
      onHide,
      pricePlans,
      devices,
      isPlansListLoading,
      currentPricePlan,
      isProcessing,
    } = this.props;
    const { showPickActiveDevice, selectedPlan } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={this.onExited}
        title={showPickActiveDevice ? (
          <FormattedMessage
            id="pricePlans.changePlanModal.pickActiveDevice.title"
            defaultMessage="Select a device to disconnect"
          />
        ) : (
          <FormattedMessage
            id="pricePlans.changePlanModal.title"
            defaultMessage="Choose plan"
          />
        )}
      >
        {(isPlansListLoading) && (
          <div className="text-center">
            <AnimatedLogo animate />
          </div>
        )}
        {!isPlansListLoading && (
          showPickActiveDevice
            ? <PickActiveDeviceForm
              devices={devices}
              onBackToPlansClick={this.onExited}
              onPickActiveDevice={this.onPickActiveDeviceClick}
              isLoading={isProcessing}
              /> : <ChangePlanForm
              onPlanChange={this.onPlanSelected}
              onSubmit={this.onChangePlanFormSubmit}
              selectedPlan={selectedPlan}
              currentPlan={currentPricePlan}
              pricePlans={pricePlans}
              isLoading={isProcessing}
                   />)}
      </Modal>
    );
  }

  onChangePlanFormSubmit = () => {
    const { fetchDevices } = this.props;
    const { selectedPlan } = this.state;
    const isNewPlanEdu = selectedPlan.tierType === pricePlanTypes.EDU;

    if (!isNewPlanEdu) {
      return this.changePlan();
    }

    return fetchDevices().then(() => {
      const { devices } = this.props;

      // TODO: remove hardcoded EDU plan seats count
      // TODO: probably check that the seats count with the next price plan is greater that the current devices count
      if (devices.length > 1) {
        this.showPickActiveDevice();
      } else {
        this.changePlan();
      }
    }).catch(() => {
      const { showDangerNotification } = this.props;

      showDangerNotification(<DefaultError />);
    });
  };

  changePlan() {
    const {
      mainSubscription,
      changePricePlan,
      onHide,
    } = this.props;
    const { selectedPlan } = this.state;

    if (!mainSubscription) {
      return null;
    }

    return changePricePlan(mainSubscription.id, selectedPlan.id)
      .then(() => {
        analytics.trackEvent(events.SUBSCRIPTION_PLAN_CHANGED, { eventLabel: selectedPlan.tierType });

        onHide();

        return this.onSuccessfulPlanChange();
      }).catch(() => {
        const { showDangerNotification } = this.props;

        showDangerNotification(<DefaultError />);
      });
  }

  onPickActiveDeviceClick = (deviceId: number) => {
    this.changePlan();
    this.deactivateDevicesDeferred(deviceId);
  };

  deactivateDevicesDeferred(deviceId: number) {
    const { deactivateDevicesDeferred } = this.props;
    const { showDangerNotification } = this.props;

    return deactivateDevicesDeferred([deviceId]).catch(() => {
      showDangerNotification(<DefaultError />);
    });
  }

  showPickActiveDevice() {
    this.setState({ showPickActiveDevice: true });
  }

  hidePickActiveDevice() {
    this.setState({ showPickActiveDevice: false });
  }

  onPlanSelected = (planId: number) => {
    const { pricePlans } = this.props;
    const selectedPlan = pricePlans.filter((pricePlan) => pricePlan.id === planId)[0];

    if (!selectedPlan) return;

    this.setState({ selectedPlan });
  };

  onExited = () => {
    const { onExited, history } = this.props;

    /*
    query params are removed on modal exited (when it's completely hidden) instead of on hide (when it's disappearing)
    because of selected plan is switched back to the current during fade out animation
     */
    removeQueryParams(history, 'show_change_plan_modal', 'selected_plan');
    this.hidePickActiveDevice();
    onExited();
  };

  onSuccessfulPlanChange = () => {
    const { fetchUser } = this.props;

    this.showSuccessfulChangePlanNotification();

    return fetchUser();
  };

  showSuccessfulChangePlanNotification() {
    const {
      mainSubscription,
      isInactiveSubscription,
      showSuccessNotification,
      currentPricePlan,
    } = this.props;

    if (!mainSubscription) {
      return;
    }

    const { nextPaymentDate } = mainSubscription;

    const { price, currency } = currentPricePlan!;

    showSuccessNotification(
      <PlanChangedNotification
        price={price}
        currency={currency}
        paymentDate={nextPaymentDate!}
        isSubscriptionActive={!isInactiveSubscription}
      />
    );
  }
}

export default connector(withRouter(ChangePlanModal));
