import React from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import { Redirect } from 'components/navigation';

type Props = ComponentPropsWithoutRef<typeof Redirect>;

const RedirectWithQuery = (props: Props) => {
  const { from, to, exact, strict, ...rest } = props;

  return (
    <Route
      path={from}
      exact={exact}
      strict={strict}
      render={({ location }) => {
        let redirectLocation;

        if (typeof to === 'string') {
          const parsedURL = queryString.parseUrl(to);
          const search = {
            ...queryString.parse(location.search),
            ...parsedURL.query,
          };

          redirectLocation = {
            pathname: parsedURL.url,
            search: queryString.stringify(search),
          };
        } else {
          const search = {
            ...queryString.parse(location.search),
            ...(to.search && queryString.parse(to.search)),
          };

          redirectLocation = {
            ...to,
            search: queryString.stringify(search),
          };
        }

        redirectLocation.state = {
          previousPathname: location.pathname,
        };

        return <Redirect {...rest} to={redirectLocation} />;
      }}
    />
  );
};

export default RedirectWithQuery;
