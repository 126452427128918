import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@setapp/ui-kit';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { Link } from 'components/navigation';

import FadeTransition from 'components/shared/fade-transition/fade-transition';
import Balloon from 'components/shared/balloon/balloon';

import { getReferrals } from 'state/root-reducer';
import { fetchReferrals } from 'state/referrals/referrals-actions';

import urls from 'config/urls';

import './invite-friends-button.scss';

import giftIcon from './images/gift-icon.svg';
import successIcon from './images/success-icon.svg';

const messages = defineMessages({
  inviteFriends: {
    id: 'inviteFriendsButton.invite',
    defaultMessage: 'Invite friends',
  },
  linkCopied: {
    id: 'inviteFriendsButton.linkCopied',
    defaultMessage: 'Link copied',
  },
  balloonTitle: {
    id: 'inviteFriendsButton.balloonTitle',
    defaultMessage: 'Time to share!',
  },
  balloonText: {
    id: 'inviteFriendsButton.balloonText',
    defaultMessage: 'Share this link with a friend. Once they start their paid Setapp subscription, you’ll both get a free month.',
  },
  balloonLink: {
    id: 'inviteFriendsButton.balloonLink',
    defaultMessage: 'Learn more',
  },
});

const canvasForTextMeasure = document.createElement('canvas');

// We measure width using canvas so that button is always wide enough to fit the longest text
const measureButtonText = (...texts: string[]) => {
  const context = canvasForTextMeasure.getContext('2d');

  if (!context) {
    return 0;
  }

  // this is not actual font settings, but it gives results close to real
  context.font = '17px sans-serif';

  return Math.max(...texts.map((text) => context.measureText(text).width));
};

const InviteFriendsButton = ({ intl }: InjectedIntlProps) => {
  const dispatch = useDispatch();
  const referrals = useSelector(getReferrals);

  const [isCopied, setIsCopied] = useState(false);

  const balloonRef = useRef<HTMLDivElement>(null);
  const inviteButtonRef = useRef<HTMLDivElement>(null);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

  const inviteText = intl.formatMessage(messages.inviteFriends);
  const linkCopiedText = intl.formatMessage(messages.linkCopied);

  const iconWidthAndGap = 16 + 8;
  const minTextWidth = measureButtonText(inviteText, linkCopiedText) + iconWidthAndGap;

  const addBalloonTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const clearBalloonTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
  };

  const handleInviteButtonClick = () => {
    copy(referrals.url);

    setIsCopied(true);

    addBalloonTimeout();
  };

  const handleMouseInsideBalloon = () => {
    clearBalloonTimeout();
  };

  const handleMouseOutsideBalloon = () => {
    addBalloonTimeout();
  };

  useEffect(() => {
    const handleClickOutsideBalloon = (event: MouseEvent) => {
      if (balloonRef.current && inviteButtonRef.current
          && !balloonRef.current.contains(event.target as Node)
          && !inviteButtonRef.current.contains(event.target as Node)
      ) {
        setIsCopied(false);
        clearBalloonTimeout();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideBalloon);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBalloon);

      clearBalloonTimeout();
    };
  }, []);

  useEffect(() => {
    if (!referrals.url && !referrals.isLoading) {
      dispatch(fetchReferrals());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrals.isLoading, referrals.url]);

  const buttonClass = classNames('invite-friends-btn', {
    'invite-friends-btn_copied': isCopied,
  });

  const balloonMarkup = (
    <div
      className="invite-friends-btn__balloon"
      ref={balloonRef}
      onMouseEnter={handleMouseInsideBalloon}
      onMouseLeave={handleMouseOutsideBalloon}
    >
      <Balloon
        position="top"
        tailPosition="topRight"
        show={isCopied}
      >
        <p className="text_weight-bold mb-2">{intl.formatMessage(messages.balloonTitle)}</p>
        <p className="text_sm mb-4">{intl.formatMessage(messages.balloonText)}</p>
        <Link className="text_sm text_weight-medium" to={urls.referral}>
          {intl.formatMessage(messages.balloonLink)}
        </Link>
      </Balloon>
    </div>
  );

  return (
    <div className="invite-friends-btn__container" ref={inviteButtonRef}>
      <Button
        size="sm"
        className={buttonClass}
        onClick={handleInviteButtonClick}
      >
        <FadeTransition
          current={isCopied ? 1 : 0}
          className="invite-friends-btn__text"
          style={{ minWidth: minTextWidth }}
        >
          <>
            <img src={giftIcon} alt="" width={16} height={16} />
            {inviteText}
          </>
          <>
            <img src={successIcon} alt="" width={16} height={16} />
            {linkCopiedText}
          </>
        </FadeTransition>
      </Button>
      {balloonMarkup}
    </div>
  );
};

export default injectIntl(InviteFriendsButton);
