import React from 'react';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';

import { Button } from '@setapp/ui-kit';
import { Link } from 'components/navigation';

import urls from 'config/urls';
import image404 from './images/404.svg';


import './not-found.scss';

const messages = defineMessages({
  imageAlt: {
    id: 'notFound.imageAlt',
    defaultMessage: 'Confused guy looks around',
  },
});

const NotFound = ({ intl }: InjectedIntlProps) => (
  <div className="not-found text-center">
    <img className="img-responsive center-block" src={image404} alt={intl.formatMessage(messages.imageAlt)} />
    <h1>
      <FormattedMessage
        id="notFound.title"
        defaultMessage="Sorry, page not found"
      />
    </h1>
    <Button
      tag={Link}
      to={urls.root}
      className="mt-9"
    >
      <FormattedMessage
        id="notFound.homeLink"
        defaultMessage="Take me to profile"
      />
    </Button>
  </div>
);

export { NotFound as PureNotFound };

export default injectIntl(NotFound);
