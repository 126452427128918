import React, { useEffect } from 'react';
import type { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import FormContainer from 'components/shared/form/form-container/form-container';

import {
  getUser,
  hasIosSeats as hasIosSeatsSelector,
} from 'state/root-reducer';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { login } from 'state/user/user-actions';

import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

import urls from 'config/urls';

import { useHistory } from 'components/navigation';
import LoginForm from './login-form/login-form';

type FormFields = {
  email: string;
  password: string;
  captcha?: string;
}

type FormFieldsErrors = {
  email?: ReactNode;
  password?: ReactNode;
  captcha?: ReactNode;
}

type NextLocation = {
  pathname: string;
  search: string;
  state?: {
    previousPathname?: string;
  };
};

const LoginFormContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{nextLocation?: NextLocation}>();

  const user = useSelector(getUser);
  const hasIosSeats = useSelector(hasIosSeatsSelector);

  const { email = '', redirect_url: redirectUrl } = queryString.parse(location.search);

  const { nextLocation } = location?.state ?? {};

  // when user gets to root location, he is redirected to '/subscription'
  // then to '/login' because he is not signed in
  // he has nextLocation, but we shouldn't redirect to it
  const redirectedFromRoot = nextLocation
    && nextLocation.state?.previousPathname === urls.root;

  const shouldRedirectToIosApps = hasIosSeats && isMobile
    && (!nextLocation || redirectedFromRoot);

  const handleRedirectToNextLocation = () => {
    if (shouldRedirectToIosApps) {
      history.push(urls.iosApps);
    } else if (redirectUrl) {
      history.push(`/${redirectUrl}`);
    } else {
      history.push(nextLocation || urls.root);
    }
  };

  const getNextLocation = () => {
    const { nextLocation } = location?.state ?? {};
    const url = nextLocation ? `${nextLocation.pathname}${nextLocation.search}` : '';

    return url;
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      handleRedirectToNextLocation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAuthenticated]);

  const validateForm = (fields: FormFields): FormFieldsErrors => {
    return validate(fields, {
      email: {
        required: <FormattedMessage
        id="outer.login.validation.emptyEmail"
        defaultMessage="Email is required"
                  />,
        emailFormat: <FormattedMessage
        id="outer.login.validation.invalidEmail"
        defaultMessage="Invalid email"
                     />,
      },
      password: {
        required: <FormattedMessage
        id="outer.login.validation.emptyPassword"
        defaultMessage="Password is required"
                  />,
      },
    });
  };

  const loginUser = async (data: FormFields): Promise<void> => {
    try {
      await dispatch(login(data));
      await dispatch(fetchAllSubscriptions());

      analytics.trackEvent(events.LOGIN_SUCCESS);
    } catch (error) {
      analytics.trackEvent(events.LOGIN_ERROR);

      throw error;
    }
  };

  const onFormSubmit = (fields: FormFields) => loginUser(fields);

  return (
    <FormContainer
      initialValues={{ email: email as string, password: '' }}
      onSubmit={onFormSubmit}
      onSubmitSuccess={handleRedirectToNextLocation}
      validate={validateForm}
    >
      {({
        fields,
        fieldsErrors,
        formError,
        isProcessing,
        onSubmit,
        onFieldChange,
        captcha,
      }) => <LoginForm
        email={fields.email}
        password={fields.password}
        emailError={fieldsErrors.email}
        passwordError={fieldsErrors.password}
        onEmailChange={onFieldChange}
        onPasswordChange={onFieldChange}
        genericError={formError}
        isRequestProcessing={isProcessing}
        onFormSubmit={onSubmit}
        captcha={captcha}
        innerRedirectUri={getNextLocation()}
            />}
    </FormContainer>
  );
};

export default LoginFormContainer;
