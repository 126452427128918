import useAccessTokenGuard from './hooks/use-access-token-guard';
import useLocaleSync from './hooks/use-locale-sync';
import useAllowedPathsSync from './hooks/use-allowed-paths-sync';

const EmbeddedProvider = ({ children }) => {
  const isAccessTokenSet = useAccessTokenGuard();
  useLocaleSync();
  useAllowedPathsSync();

  if (!isAccessTokenSet) {
    return null;
  }

  return children;
};
export default EmbeddedProvider;
