import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'components/navigation';
import urls from 'config/urls';
import { getPaymentMethod, getPrimarySubscription } from 'state/root-reducer';
import analytics, { events } from 'utils/analytics';

const useSuspendedActivationHandler = () => {
  const history = useHistory();
  const primarySubscription = useSelector(getPrimarySubscription);
  const paymentMethod = useSelector(getPaymentMethod);

  return useCallback(() => {
    const eventLabel = paymentMethod.data ? 'withPaymentDetails' : 'withoutPaymentDetails';
    const eventLabel2 = primarySubscription && primarySubscription.pricePlan.tierType;

    analytics.trackEvent(events.SUBSCRIPTION_GET_PLAN, { eventLabel, eventLabel2 });
    history.push(urls.activateSubscription);
  }, [history, primarySubscription, paymentMethod]);
};

export default useSuspendedActivationHandler;
