import { useWizardListener } from '@mwkt/react-magic-frame';

import { MagicFrameAction } from '../constants';
import { useNavigation } from '../navigation';

const useAllowedPathsSync = () => {
  const { setAllowedPaths } = useNavigation();

  useWizardListener({ event: MagicFrameAction.SetAllowedPaths, callback: (data) => {
    setAllowedPaths(JSON.parse(data.allowedPaths));
  } });
};

export default useAllowedPathsSync;
