import React, { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import { type RouteComponentProps } from 'react-router-dom';
import { FormFieldWrapper } from '@setapp/ui-kit';
import { Link } from 'components/navigation';
import withRouter from 'components/navigation/navigation-with-router';

import urls from 'config/urls';

type Props = {
  email: string;
  history: RouteComponentProps['history'];
} & ComponentProps<typeof FormFieldWrapper>
  & ComponentProps<typeof FormControl>;

const CurrentPasswordField = (props: Props) => {
  const {
    id,
    label,
    invalid,
    helpText,
    qaLabel,
    showLabel,
    history,
    // fix `React does not recognize the `staticContext` prop on a DOM element`
    staticContext, //eslint-disable-line @typescript-eslint/no-unused-vars
    ...inputProps
  } = props;

  return (
    <FormFieldWrapper
      id={id}
      label={label}
      invalid={invalid}
      helpText={helpText}
      qaLabel={qaLabel}
      showLabel={showLabel}
    >
      <InputGroup>
        <FormControl
          {...inputProps}
          id={id}
          type="password"
          autoComplete="current-password"
        />
        <InputGroup.Addon>
          <Link to={{
            pathname: urls.recoverPassword,
            state: { previousPage: history.location.pathname },
          }}
          >
            <FormattedMessage id="currentPasswordInput.forgotLinkTitle" defaultMessage="Forgot?" />
          </Link>
        </InputGroup.Addon>
      </InputGroup>
    </FormFieldWrapper>
  );
};

export default withRouter(CurrentPasswordField);
