import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import type { KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import analytics, { events } from 'utils/analytics';
import { showModal } from 'state/modal/modal-reducer';
import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';
import useIosCollectionsConfig from 'components/hooks/use-ios-collections/use-ios-collections';
import urls from 'config/urls';
import { useHistory } from 'components/navigation';

type Params = {
  ownEventLabel?: string;
}

const useIosEventHandlers = ({ ownEventLabel }: Params = {}) => {
  const { appPacks, collectionBanners } = useIosCollectionsConfig();
  const allCollections = [...appPacks, ...collectionBanners];

  const dispatch = useDispatch();
  const history = useHistory();
  const { id: collectionId } = useParams<{id: string}>();

  const handleInteraction = (iosApp: IosApp) => {
    let eventLabel2 = ownEventLabel ?? 'All Apps';

    if (collectionId && !ownEventLabel) {
      const collection = allCollections.find(
        (collection) => collection.id === Number(collectionId)
      );
      if (collection) {
        eventLabel2 = collection.name;
      }
    }

    analytics.trackEvent(events.IOS_ACTIVATION_APP_CLICK,
      { eventLabel: iosApp.name, eventLabel2 }
    );

    if (isMobile) {
      return history.push(`${urls.iosAppActivation}/${iosApp.id}`);
    }

    return dispatch(showModal('ACTIVATE_IOS_APP', { iosApp }));
  };

  const handleIosAppClick = (iosApp: IosApp) => {
    handleInteraction(iosApp);
  };

  const handleIosAppKeyDown = (
    event: KeyboardEvent<HTMLDivElement>,
    iosApp: IosApp
  ) => {
    if (event.key !== ' ' && event.key !== 'Enter') {
      return;
    }

    handleInteraction(iosApp);
  };

  return { handleIosAppClick, handleIosAppKeyDown };
};

export default useIosEventHandlers;
