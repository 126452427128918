import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import { InjectedIntlProps, injectIntl } from 'react-intl';
import logger from 'utils/logger';

import type { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import { hasLessAiCreditsInNextPlan, hasLessDevisesInNextPlan, isAiPricePlan } from 'services/price-plans/utils';

import type { State as RootState } from 'state/configure-store';
import { getUser } from 'state/root-reducer';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { changePricePlan, fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { Subscription } from 'state/subscription/subscription-initial-state';
import { fetchUser } from 'state/user/user-actions';

import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import withInvoicePolling from 'components/shared/with-invoice-polling/with-invoice-polling';
import type { InjectedInvoicePollingProps } from 'components/shared/with-invoice-polling/with-invoice-polling';
import renderPlanChangedNotificationMessage
  from 'components/user-flow/change-price-plan/plan-changed-notification/render-plan-changed-notification-message';

import { NotificationEventData } from 'components/navigation/constants';
import PlanChangedNotification from '../plan-changed-notification/plan-changed-notification';
import ConfirmLessDevicesStep from '../confirm-less-devices-step/confirm-less-devices-step';
import ConfirmPricePlanStep from '../confirm-price-plan-step/confirm-price-plan-step';
import ConfirmAiPlanJumpStep from '../confirm-ai-plan-jump-step/confirm-ai-plan-jump-step';

const mapStateToProps = (state: RootState) => ({
  user: getUser(state),
});

const mapActionsToProps = {
  changePricePlan,
  fetchAllSubscriptions,
  fetchUser,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Step = 'confirmPricePlanStep'
  | 'confirmLessDevicesStep'
  | 'confirmAiPlanJumpStep';

export type Props = InjectedInvoicePollingProps & PropsFromRedux & InjectedIntlProps & {
  currentPricePlan: PricePlan;
  primarySubscription: Subscription;
  nextPricePlan: PricePlan;
  nextPricePlanCalculationData: NextPricePlanCalculationData;
  shouldHideBackButton?: boolean;
  onGoBackward: () => void;
  onClose: () => void;
  onSuccessPlanChange?: () => void;
  emitNotificationEvent: (data: NotificationEventData) => void;
  isEmbedded?: boolean;
};

type State = {
  step: Step;
  isProcessing: boolean;
  previousPricePlan: PricePlan | null;
};

const BACK_STEP_MAPPING: {[key in Step]?: Step | 'goBackward'} = {
  confirmPricePlanStep: 'goBackward',
  confirmLessDevicesStep: 'confirmPricePlanStep',
};

class DowngradeFlow extends PureComponent<Props, State> {
  state: State = {
    step: 'confirmPricePlanStep',
    isProcessing: false,
    previousPricePlan: null,
  };

  componentDidMount() {
    const { currentPricePlan, nextPricePlan, primarySubscription } = this.props;

    const isTrialUser = primarySubscription.status === subscriptionStatuses.TRIAL;

    // if doing jump from monthly usual plan to annual AI plan
    if (isAiPricePlan(nextPricePlan.tierType)
        && !isTrialUser
        && currentPricePlan.paidMonth === 1
        && nextPricePlan.paidMonth === 12) {
      this.setState({ step: 'confirmAiPlanJumpStep' });
    }
  }

  render() {
    const { shouldHideBackButton } = this.props;

    return (
      <>
        {!shouldHideBackButton && (
          <div className="mb-5">
            <ButtonBack onClick={this.handleBackButtonClick} />
          </div>
        )}
        {this.renderStep()}
      </>
    );
  }

  renderStep() {
    const {
      currentPricePlan,
      nextPricePlan,
      nextPricePlanCalculationData,
      primarySubscription,
      user,
    } = this.props;
    const { step, isProcessing } = this.state;

    switch (step) {
      case 'confirmPricePlanStep':
        return (
          <ConfirmPricePlanStep
            isProcessing={isProcessing}
            currentPricePlan={currentPricePlan}
            nextPricePlan={nextPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onSubmitNextPlan={this.handleSubmitNextPlan}
            isTrialUser={primarySubscription.status === subscriptionStatuses.TRIAL}
            user={user}
          />
        );
      case 'confirmLessDevicesStep':
        return (
          <ConfirmLessDevicesStep
            isProcessing={isProcessing}
            nextPricePlan={nextPricePlan}
            currentPricePlan={currentPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onConfirmLessDevices={this.changePlan}
          />
        );
      case 'confirmAiPlanJumpStep':
        return (
          <ConfirmAiPlanJumpStep
            primarySubscription={primarySubscription}
            nextPricePlan={nextPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
          />
        );
      default:
        return null;
    }
  }

  handleBackButtonClick = () => {
    const { onGoBackward } = this.props;
    const { step } = this.state;

    const backStep = BACK_STEP_MAPPING[step];

    if (backStep === 'goBackward') {
      onGoBackward();
    } else {
      this.setState({ step: backStep ?? step });
    }
  }

  handleSubmitNextPlan = async () => {
    const { currentPricePlan, nextPricePlan, primarySubscription } = this.props;

    if (
      (
        hasLessDevisesInNextPlan(currentPricePlan, nextPricePlan)
        || hasLessAiCreditsInNextPlan(currentPricePlan, nextPricePlan))
      // trial users should not see the confirmation step
      && primarySubscription.status !== subscriptionStatuses.TRIAL) {
      this.setState({ step: 'confirmLessDevicesStep' });

      return;
    }

    await this.changePlan();
  }

  changePlan = async () => {
    const {
      waitForPaymentSuccess,
      currentPricePlan,
      primarySubscription,
      nextPricePlan,
      changePricePlan,
    } = this.props;

    this.setState({
      isProcessing: true,
      // need to remember previous price plan, because after changing price plan
      // property `currentPricePlan` will return new price plan object
      previousPricePlan: currentPricePlan,
    });

    try {
      const { strategy, invoiceId } = await changePricePlan(
        primarySubscription.id,
        nextPricePlan.id,
        { fetchSubscription: false },
      );

      // just show success message for unforeseen situations
      if (strategy === 'instant' && !invoiceId) {
        logger.logWarn(
          'Downgrade flow: Change price plan API return incorrect data',
          { strategy, invoiceId },
        );

        await this.successfulPlanChange();

        return;
      }

      // in case of instant strategy need to poll invoice status
      if (strategy === 'instant' && invoiceId) {
        await waitForPaymentSuccess(
          invoiceId,
          this.successfulPlanChange,
          this.showDefaultError,
          this.showDefaultError,
          { userFlow: 'downgrade' }
        );

        return;
      }

      // for scheduled strategy just show success message
      await this.successfulPlanChange();
    } catch (err) {
      this.showDefaultError();
      logger.logError('Downgrade flow: Error during change plan', err);
    }
  }

  successfulPlanChange = async () => {
    const {
      primarySubscription,
      nextPricePlan,
      nextPricePlanCalculationData,
      fetchAllSubscriptions,
      fetchUser,
      showSuccessNotification,
      onClose,
      onSuccessPlanChange,
      emitNotificationEvent,
      isEmbedded,
      intl
    } = this.props;
    const { previousPricePlan } = this.state;

    if (!previousPricePlan) {
      return;
    }

    if (isEmbedded) {
      const messageString = renderPlanChangedNotificationMessage({
        nextPricePlan,
        nextPricePlanCalculationData,
        previousPricePlan,
        primarySubscription,
        intl,
      });
      emitNotificationEvent({ message: messageString });
    } else {
      showSuccessNotification(
        <PlanChangedNotification
          nextPricePlan={nextPricePlan}
          nextPricePlanCalculationData={nextPricePlanCalculationData}
          previousPricePlan={previousPricePlan}
          primarySubscription={primarySubscription}
        />
      );
    }

    onSuccessPlanChange?.();

    await fetchUser();
    await fetchAllSubscriptions();

    onClose();
  }

  showDefaultError = () => {
    const { showDangerNotification, onClose } = this.props;

    showDangerNotification(<DefaultError />);
    onClose();
  }
}

export { DowngradeFlow as PureDowngradeFlow };

export default connector(withInvoicePolling(injectIntl(DowngradeFlow)));
