import { useEffect, useState, useCallback } from 'react';
import type { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { getUser, getPrimarySubscription, isSubscriptionsFetched } from 'state/root-reducer';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { fetchUser } from 'state/user/user-actions';
import { resetFeatureFlags } from 'state/feature-flags/feature-flags-actions';

import urls from 'config/urls';
import { TEAMS_WEB_ROOT } from 'config/app';
import withRouter from 'components/navigation/navigation-with-router';

const mapStateToProps = (state) => {
  const user = getUser(state);
  const primarySubscription = getPrimarySubscription(state);

  return {
    campaign: user.campaign,
    isUserFetched: Boolean(user.id),
    isUserAuthenticated: user.isAuthenticated,
    isUserTeamMember: user.isTeamMember,
    isDesktopCcRequired: user.desktopCcRequired,
    isPaymentInfoRequired: user.paymentInfoRequired,
    isSubscriptionsFetched: isSubscriptionsFetched(state),
    subscriptionStatus: primarySubscription?.status,
  };
};

const mapActionsToProps = {
  fetchAllSubscriptions,
  fetchUser,
  resetFeatureFlags,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  children: ReactElement;
} & RouteComponentProps & ConnectedProps<typeof connector>;

const AuthenticatedSection = (props: Props) => {
  const {
    children,
    history,
    isUserFetched,
    isUserAuthenticated,
    isUserTeamMember,
    isDesktopCcRequired,
    isPaymentInfoRequired,
    fetchUser,
    resetFeatureFlags,

    campaign,
    isSubscriptionsFetched,
    subscriptionStatus,
    fetchAllSubscriptions,
  } = props;

  const { location, replace } = history;
  // We need to know if we can immediately show the page to avoid blinking on remount
  const initialAccessGranted = isUserFetched && isUserAuthenticated && !isUserTeamMember && !isPaymentInfoRequired;
  const [isAccessGranted, setAccessGranted] = useState(initialAccessGranted);

  const redirectToLoginPage = useCallback(() => {
    replace({
      pathname: urls.login,
      search: location.search,
      state: {
        nextLocation: location,
      },
    });
  }, [location, replace]);

  useEffect(() => {
    // Check if user is in autologin flow. In this case he shouldn't be redirected elsewhere
    const isDesktopReferrer = location.pathname === urls.authDesktop;
    // Skip AddPaymentDetails, SocialAuthWelcome, ConfirmEmail, FamilyInvite pages
    const shouldSkipPage = [
      urls.signupAddPaymentMethod,
      urls.embedSignupAddPaymentMethod,
      urls.socialAuthWelcome,
      urls.signupConfirmEmail,
      urls.acceptFamilyInvite,
    ]
      .some((path) => location.pathname.startsWith(path));

    const isVisitingSetappMobile = [
      urls.customerOauth,
      urls.setappMobile,
    ].some((path) => location.pathname.startsWith(path));
    const shouldForbidSetappMobilePage = isVisitingSetappMobile && isUserTeamMember;

    const isFamilyMemberUrl = () => {
      const params = new URLSearchParams(location.search);

      return Boolean(params.get('family'));
    };

    let shouldRedirectToPaymentInfoPage = false;
    if (shouldSkipPage || isFamilyMemberUrl() || isDesktopCcRequired) {
      shouldRedirectToPaymentInfoPage = false;
    } else if (isPaymentInfoRequired && !isDesktopReferrer && !location.pathname.startsWith(urls.setappMobile)) {
      shouldRedirectToPaymentInfoPage = true;
    }

    // We don't need redirect to the login if the page is embedded
    if (!isUserAuthenticated) {
      redirectToLoginPage();
    } else if (!isUserFetched) {
      // Fetch profile only on initial load. Otherwise it should already be fetched by the one of auth actions
      fetchUser().catch(() => redirectToLoginPage());
    } else if (!isSubscriptionsFetched) {
      // Fetch subscription if it isn't already fetched
      fetchAllSubscriptions().then(() => {
        // Feature flags reset is done here, not after the fetchUser
        // because we need subscription data to determine evidences
        resetFeatureFlags();
      }).catch(() => redirectToLoginPage());
    } else if (shouldForbidSetappMobilePage) {
      replace({ pathname: urls.restrictSetappMobile });
    } else if (isUserTeamMember && !isDesktopReferrer) {
      // Team members must be redirected to the team account
      window.location.replace(TEAMS_WEB_ROOT + location.pathname + location.search);
    } else if (shouldRedirectToPaymentInfoPage) {
      replace({
        pathname: urls.signupAddPaymentMethod,
        search: location.search,
        state: { nextPathname: location.pathname },
      });
    } else {
      setAccessGranted(true);
    }
  }, [
    isUserFetched,
    isUserAuthenticated,
    isUserTeamMember,
    isPaymentInfoRequired,
    fetchUser,
    redirectToLoginPage,
    location,
    replace,
    history,

    campaign,
    isSubscriptionsFetched,
    subscriptionStatus,
    fetchAllSubscriptions,
  ]);

  // TODO: show loading after migration to react-router@4
  return isAccessGranted ? children : null;
};

export default connector(withRouter(AuthenticatedSection));
