import React, { useEffect } from 'react';
import { Redirect as ReactRouterRedirect, RedirectProps } from 'react-router-dom';
import { useWizardEmit } from '@mwkt/react-magic-frame';
import useIsAllowedPath from 'components/hooks/use-is-allowed-path/use-is-allowed-path';
import { MagicFrameAction, NavigateEventData } from './constants';
import { useNavigation } from './navigation';

const EmbedRedirect: React.FC<RedirectProps> = ({  to, ...props }) => {
  const emitNavigateEvent = useWizardEmit<NavigateEventData>({
    event: MagicFrameAction.Navigate
  });

  const { isAllowedPath } = useIsAllowedPath(to);

  useEffect(() => {
    emitNavigateEvent({ path: to as string, isAllowed: isAllowedPath });
  }, []);

  if (!isAllowedPath) {
    return null;
  }

  return <ReactRouterRedirect {...props} to={to} />;
};

const Redirect: React.FC<RedirectProps> = ({  ...props }) => {
  const { isEmbedded } = useNavigation();

  if (isEmbedded) {
    return <EmbedRedirect {...props} />;
  }

  return <ReactRouterRedirect {...props} />;
};

export default Redirect;
