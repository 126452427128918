import React, { useState, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { isMobile, isIOS } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { useHistory } from 'components/navigation';
import ErrorBoundary from 'components/shared/error-boundary/error-boundary';
import CookieBanner from 'components/shared/banners/cookie-banner/cookie-banner';
import EmergencyBanner from 'components/shared/emergency-banner/emergency-banner';
import NotificationsContainer from 'components/shared/notification/notifications-container';
import Footer from 'components/layout/footer/footer';

import { getFeatureFlags, getModalState } from 'state/root-reducer';

import urls from 'config/urls';

import AppHeader from './header/app-header';
import PartnershipBanner from './partnership-banner/partnership-banner';
import PermanentNotificationsContainer from './permanent-notifications-container/permanent-notifications-container';
import Navigation from './sidebar-navigation/sidebar-navigation';
import ModalRoot from './modal-root/modal-root';

import './app-layout.scss';

type Props = {
  children: ReactNode;
  contentClasses?: string;
};

const AppLayout = ({ children, contentClasses = '' }: Props) => {
  const history = useHistory();
  const scroller = useRef<HTMLDivElement | null>(null);
  const [canOpenSidebarNav, setCanOpenSidebarNav] = useState(true);
  const { ref, inView } = useInView();
  const featureFlags = useSelector(getFeatureFlags);
  const { isVisible: isModalVisible } = useSelector(getModalState);
  const isPartnershipPromo = Boolean(featureFlags.specialOffer.value) && !isIOS;
  const hideBannersOnIosPage = (
    isMobile && history.location.pathname.startsWith(urls.iosApps)
  );
  const shouldHideAllNotificaions = history.location.pathname.startsWith(urls.setappMobile);

  const appContentClasses = classnames('app-layout__content', contentClasses);
  const headerClasses = classnames('app-layout__header', {
    'app-layout__header_with-shadow': !inView,
  });
  // We hide permanent notifications when modal is visible
  // so that temporary notifications are at the top of the page
  const permanentNotificationsClasses = classnames('app-layout__permanent-notifications', {
    'app-layout__permanent-notifications_hidden': isModalVisible,
  });

  const handleHeaderNavToggle = () => {
    setCanOpenSidebarNav(!canOpenSidebarNav);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!scroller.current) {
        return;
      }

      scroller.current.scrollTop = 0;
    });

    return () => {
      unlisten();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <div className="app-layout">
      {!hideBannersOnIosPage && !isPartnershipPromo && <EmergencyBanner />}
      <header className={headerClasses}>
        <div className="app-layout__container">
          <AppHeader onToggle={handleHeaderNavToggle} />
        </div>
      </header>
      <div className="app-layout__scrollable" ref={scroller}>
        <div data-testid="scroll-observable" ref={ref} />
        {isPartnershipPromo && (
          <PartnershipBanner />
        )}
        <main className="app-layout__main">
          <aside className="app-layout__sidebar">
            <div className="app-layout__sidebar-container">
              <Navigation canBeExpanded={canOpenSidebarNav} />
            </div>
          </aside>
          <div className="app-layout__right">
            {!shouldHideAllNotificaions && (
              <div className={permanentNotificationsClasses}>
                <PermanentNotificationsContainer hideBannersOnIosPage={hideBannersOnIosPage} />
              </div>
            )}
            <div className="app-layout__temporary-notifications-container">
              <div className="app-layout__temporary-notifications">
                <NotificationsContainer />
              </div>
            </div>
            <div className={appContentClasses}>
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            </div>
          </div>
        </main>
        <footer className="app-layout__footer">
          <Footer />
        </footer>
      </div>
      <CookieBanner />
      <ModalRoot />
    </div>
  );
};

export default AppLayout;
