import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Redirect, useHistory } from 'components/navigation';
import ActivationGuideModal, { DONT_SHOW_STORAGE_KEY }
  from 'components/modals/activation-guide/activation-guide-modal';
import { fetchIosApps } from 'state/ios-apps/ios-apps-actions';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import {
  hasIosSeats as hasIosSeatsSelector,
  isSubscriptionsFetched as isSubscriptionsFetchedSelector,
  getIosApps,
} from 'state/root-reducer';

import FadeTransition from 'components/shared/fade-transition/fade-transition';
import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';
import { getLocalStorageItem } from 'utils/local-storage';
import { fetchActivationUrl } from 'utils/activate-ios-helpers';

import FirstStep from './activation-steps/first-step';
import SecondStep from './activation-steps/second-step';

import './activate-ios-page.scss';

type Step = 'appStoreStep' | 'activateStep';

const ActivateIosPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: appId } = useParams<{id: string}>();

  const [activationUrl, setActivationUrl] = useState('');
  const [currentStep, setCurrentStep] = useState<Step>('appStoreStep');
  const [showGuide, setShowGuide] = useState(false);

  const hasIosSeats = useSelector(hasIosSeatsSelector);
  const isSubscriptionsFetched = useSelector(isSubscriptionsFetchedSelector);
  const { list: appsList } = useSelector(getIosApps);
  const iosApp = appsList.find((app) => app.id === Number(appId));

  useEffect(() => {
    try {
      if (!isSubscriptionsFetched) {
        dispatch(fetchAllSubscriptions());
      }
      if (!appsList.length) {
        dispatch(fetchIosApps());
      }
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getActivationUrl = async () => {
      try {
        if (iosApp) {
          const activationUrl = await fetchActivationUrl(iosApp, 'link_web');
          setActivationUrl(activationUrl);
        }
      } catch {
        dispatch(showDangerNotification(<DefaultError />));
        history.push(urls.iosApps);
      }
    };

    getActivationUrl();
  }, [dispatch, history, iosApp]);

  if (!appsList.length || !isSubscriptionsFetched) {
    return <FullscreenLayoutLoading />;
  }

  if (!hasIosSeats) {
    return <Redirect to={urls.subscription} />;
  }

  if (!iosApp) {
    return <Redirect to={urls.iosApps} />;
  }

  if (!activationUrl) {
    return <FullscreenLayoutLoading />;
  }

  const handleBackClick = () => {
    if (currentStep === 'activateStep') {
      return setCurrentStep('appStoreStep');
    }

    return history.goBack();
  };

  const handleAppStoreClick = () => {
    const dontShowGuide = getLocalStorageItem(DONT_SHOW_STORAGE_KEY);
    analytics.trackEvent(events.IOS_ACTIVATION_APP_STORE_CLICK, { eventLabel: iosApp.name });

    if (dontShowGuide) {
      window.open(iosApp.appStoreLink, '_blank', 'noopener,noreferrer');

      return setCurrentStep('activateStep');
    }

    return setShowGuide(true);
  };

  const handleGuideAppStoreClick = (checkedDontShow: boolean) => {
    window.open(iosApp.appStoreLink, '_blank', 'noopener,noreferrer');
    analytics.trackEvent(events.IOS_ACTIVATION_REMINDER_APP_STORE_CLICK, {
      eventLabel: iosApp.name,
      ...(checkedDontShow && { eventLabel2: 'Don\'t show it again' })
    });

    setCurrentStep('activateStep');
  };

  const handleActivateClick = () => {
    window.open(activationUrl, '_blank', 'noopener,noreferrer');
    analytics.trackEvent(events.IOS_ACTIVATION_ACTIVATE_CLICK, { eventLabel: iosApp.name });
  };

  const goToActivationStep = () => {
    analytics.trackEvent(events.IOS_ACTIVATION_ACTIVATE_CLICK, { eventLabel: iosApp.name });
    setCurrentStep('activateStep');
  };

  const handleHideModal = () => setShowGuide(false);

  return (
    <div className="activate-ios-page">
      <ButtonBack
        onClick={handleBackClick}
        className="activate-ios-page__back-btn"
      />
      <FadeTransition current={currentStep === 'appStoreStep' ? 0 : 1}>
        <FirstStep
          iosApp={iosApp}
          goToActivationStep={goToActivationStep}
          onAppStoreButtonClick={handleAppStoreClick}
        />
        <SecondStep
          iosApp={iosApp}
          goToAppStore={handleAppStoreClick}
          onActivateButtonClick={handleActivateClick}
        />
      </FadeTransition>
      <ActivationGuideModal
        show={showGuide}
        iosAppName={iosApp.name}
        onHideItself={handleHideModal}
        onAppStoreClick={handleGuideAppStoreClick}
      />
    </div>
  );
};

export default ActivateIosPage;
