import { useHistory as useReactRouterHistory } from 'react-router-dom';
import { useWizardEmit } from '@mwkt/react-magic-frame';
import { match } from 'path-to-regexp';
import { LocationDescriptor, Path } from 'history';
import { MagicFrameAction, NavigateEventData } from './constants';
import { useNavigation } from './navigation';

const useHistory = () => {
  const history = useReactRouterHistory();
  const { allowedPaths, isEmbedded } = useNavigation();

  const emitNavigateEvent = useWizardEmit<NavigateEventData>({
    event: MagicFrameAction.Navigate,
    skip: !isEmbedded,
  });

  const push = (location: Path | LocationDescriptor, state?: any) => {
    const path = typeof location === 'string' ? location : (location.pathname || '');

    if (!isEmbedded) {
      history.push(location, state);

      return;
    }

    const isAllowedPath = allowedPaths.some((allowedPath) => match(allowedPath)(path));
    emitNavigateEvent({ path, isAllowed: isAllowedPath });

    if (isAllowedPath) {
      history.push(location, state);
    }
  };

  const replace = (location: Path | LocationDescriptor, state?: any) => {
    const path = typeof location === 'string' ? location : (location.pathname || '');

    if (!isEmbedded) {
      history.replace(location, state);

      return;
    }

    const isAllowedPath = allowedPaths.some((allowedPath) => match(allowedPath)(path));
    emitNavigateEvent({ path, isAllowed: isAllowedPath });

    if (isAllowedPath) {
      history.replace(location, state);
    }
  };

  return {
    ...history,
    push,
    replace,
  };
};

export default useHistory;
