import React, { type ComponentType } from 'react';
import { type RouteComponentProps, useLocation, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'components/navigation';

const withRouter = <Props extends RouteComponentProps>(Component: ComponentType<Props>) => {
  const WrappedComponent = (props: Omit<Props, 'history' | 'match' | 'location'>) => {
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    return (
      // @ts-expect-error -- TS doesn't understand that we're passing the props down
      <Component
        {...props}
        location={location}
        history={history}
        match={match}
      />
    );
  };

  WrappedComponent.displayName = `withRouter(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export default withRouter;
