import httpStatuses from 'http-status';
import { apiURL } from 'config/api';
import request from 'utils/request';
import { getCurrentFamilyMember } from 'state/root-reducer';
import analytics, { events } from 'utils/analytics';
import type { Dispatch, GetState } from 'state/state-types';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { fetchUser } from 'state/user/user-actions';
import { fetchAllSubscriptions } from '../subscription/subscription-actions';
import * as actionTypes from './family-plan-action-types';


export const fetchFamilyPlan = () => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.get(apiURL.familyPlan)
    .then((data) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const activateFamilyPlan = (pricePlan: PricePlan) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(apiURL.familyPlan, { body: { priceId: pricePlan.id } })
    .then(() => {
      analytics.trackEvent(events.FAMILY_PLAN_SWITCH_SUCCESS, {
        eventLabel: pricePlan.tierType,
      });

      return dispatch(fetchFamilyPlan());
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    })
    .then(() => dispatch(fetchAllSubscriptions()));
};

export const dropFamily = () => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(apiURL.dropFamilyPlan)
    .then((data) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data,
      });

      dispatch({
        type: actionTypes.DROP,
      });

      analytics.trackEvent(events.FAMILY_PLAN_DROP_SUCCESS);
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    })
    .then(() => dispatch(fetchAllSubscriptions()));
};

export const inviteFamilyMember = (email: string) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(apiURL.familyMembers, { body: { email } })
    .then((data) => {
      dispatch({
        type: actionTypes.ADD_MEMBER,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const acceptFamilyInvite = (inviteId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(apiURL.acceptFamilyInvite, { body: { inviteId } })
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    })
    .then(() => dispatch(fetchUser(true)))
    .then(() => dispatch(fetchAllSubscriptions()));
};

export const resendInvite = (id: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  return request.post(`${apiURL.familyMembers}/${id}/resend_invite`)
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const removeFamilyMember = (id: number) => (dispatch: Dispatch, getState: GetState) => {
  dispatch({
    type: actionTypes.REQUEST,
  });

  const currentFamilyMember = getCurrentFamilyMember(getState());

  return request.delete(`${apiURL.familyMembers}/${id}`)
    .catch((error) => {
      // threat 404 error as success for case when a member was removed from other tab or browser
      if (error.status === httpStatuses.NOT_FOUND) {
        return Promise.resolve();
      }

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    })
    .then(() => {
      // fetch subscriptions if a member has left a family to display correct subscription info
      if (!currentFamilyMember || id !== currentFamilyMember.id) {
        return Promise.resolve();
      }

      return dispatch(fetchAllSubscriptions());
    })
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
      });

      dispatch({
        type: actionTypes.REMOVE_MEMBER,
        payload: id,
      });
    });
};
