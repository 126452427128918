import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'components/navigation';

import urls from 'config/urls';

const SocialAuthSuccessfulFromDesktop = () => (
  <div className="text-center" style={{ margin: 'auto' }}>
    <div className="successful-auth-title-wrapper">
      <h3>
        <FormattedMessage
          id="authFromDesktopCompleted.title"
          defaultMessage="You have successfully logged in."
        />
      </h3>
      <div>
        <FormattedMessage
          id="authFromDesktopCompleted.subTitle"
          defaultMessage="This page can be safely closed now."
        />
      </div>
    </div>
    <Link to={urls.root}>
      <FormattedMessage id="authFromDesktopCompleted.goToAccountLink" defaultMessage="Go to account" />
    </Link>
  </div>
);

export default SocialAuthSuccessfulFromDesktop;
