
import type { FeatureFlag } from '@setapp/abn-tests-client';

import featureFlags from 'utils/feature-flags';
import logger from 'utils/logger';

import { isIosOnlyUser, isUserFamilyMember, isUserMarketingAffiliate } from 'state/root-reducer';
import type { Dispatch, GetState } from 'state/state-types';

import * as actionTypes from './feature-flags-actions-types';

export const initFeatureFlags = () => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.REQUEST });

  try {
    await featureFlags.init();

    dispatch({
      type: actionTypes.REQUEST_SUCCESS,
      featureFlags: featureFlags.resolveFeatureFlags(),
    });
  } catch (error) {
    logger.logError('Couldn\'t initialize feature flags module', error);

    dispatch({ type: actionTypes.REQUEST_ERROR });
  }
};

export const resetFeatureFlags = () => async (dispatch: Dispatch, getState: GetState) => {
  dispatch({ type: actionTypes.REQUEST });

  const state = getState();

  try {
    await featureFlags.reset({
      isIosOnlyUser: Boolean(isIosOnlyUser(state)),
      isFamily: isUserFamilyMember(state),
      isMarketingAffiliate: isUserMarketingAffiliate(state),
    });

    dispatch({
      type: actionTypes.REQUEST_SUCCESS,
      featureFlags: featureFlags.resolveFeatureFlags(),
    });
  } catch (error) {
    logger.logError('Couldn\'t reset feature flags module', error);

    dispatch({ type: actionTypes.REQUEST_ERROR });
  }
};

export const trackFeatureFlagSplitResult = (featureFlag: FeatureFlag) => () => {
  featureFlags.trackSplitResult(featureFlag);
};
