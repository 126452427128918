import { LinkProps, useLocation } from 'react-router-dom';

const useGetPathnameFromTo = ({ to }: Pick<LinkProps, 'to'>): string => {
  const location = useLocation();
  const resolvedTo = typeof to === 'function' ? to(location) : to;

  return typeof resolvedTo === 'string' ? resolvedTo : (resolvedTo.pathname || '');
};

export default useGetPathnameFromTo;
