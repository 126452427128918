import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import queryString from 'query-string';

import {
  loginWithOneTimeToken,
  logout,
} from 'state/user/user-actions';
import { getUser } from 'state/root-reducer';
import { removeQueryParams } from 'utils/location';
import { useHistory } from 'components/navigation';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  isUserAuthenticated: getUser(state).isAuthenticated,
});

const mapActionsToProps = {
  loginWithOneTimeToken,
  logout,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector> & {
  children: ReactNode | $TSFixMe;
};

function getAccessToken(location) {
  const query = queryString.parse(location.search);
  const { 'access_token': accessToken } = query;

  return accessToken as string;
}

const CrossAuthGuard = (props: Props) => {
  const {
    children,
    isUserAuthenticated,
    loginWithOneTimeToken,
    logout,
  } = props;
  const history = useHistory();

  const [isAuthenticating, setAuthenticating] = useState(Boolean(getAccessToken(history.location)));

  useEffect(() => {
    const accessToken = getAccessToken(history.location);

    if (accessToken) {
      removeQueryParams(history, 'access_token');

      loginWithOneTimeToken(accessToken)
        .then(() => {
          setAuthenticating(false);
        })
        .catch(() => {
          if (isUserAuthenticated) {
            logout();
          }
          setAuthenticating(false);
        });
    }
  }, [history, isUserAuthenticated, logout, loginWithOneTimeToken]);

  return !isAuthenticating && children;
};

export { CrossAuthGuard as PureCrossAuthGuard };

export default connector(CrossAuthGuard);
