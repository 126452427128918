import React, { type FC } from 'react';
import { Redirect } from 'components/navigation';

import urls from 'config/urls';

import { setHasAppAccessIssue } from './utils/app-access-issue-storage';

const AppAccessIssue: FC = () => {
  setHasAppAccessIssue();

  return <Redirect to={urls.subscription} />;
};

export default AppAccessIssue;
