import { useWizardEmit } from '@mwkt/react-magic-frame';

import { MagicFrameAction, type MagicFrameErrorEventType } from '../constants';
import { useNavigation } from '../navigation';

const useWizardEmitError = () => {
  const { isEmbedded } = useNavigation();

  return useWizardEmit<MagicFrameErrorEventType>({ event: MagicFrameAction.Error, skip: !isEmbedded });
};

export default useWizardEmitError;
