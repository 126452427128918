import { useState } from 'react';
import Cookies from 'js-cookie';
import { useWizardListener } from '@mwkt/react-magic-frame';

import * as authConfig from 'config/auth';

import { COOKIES_SETTINGS, MagicFrameAction } from '../constants';

const useAccessTokenGuard = () => {
  const [isAccessTokenSet, setIsAccessTokenSet] = useState(false);

  useWizardListener({ event: MagicFrameAction.SetAccessToken, callback: (data) => {
    Cookies.set(authConfig.ACCESS_TOKEN_COOKIE, data.accessToken, COOKIES_SETTINGS);
    Cookies.set(
      authConfig.REFRESH_TOKEN_COOKIE,
      data.refreshToken,
      { ...COOKIES_SETTINGS, expires: authConfig.REMEMBER_ME_PERIOD },
    );

    setIsAccessTokenSet(true);
  } });

  return isAccessTokenSet;
};

export default useAccessTokenGuard;
