import RequestError from '@setapp/request-error';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import { InjectedIntlProps, injectIntl } from 'react-intl';
import analytics, { events } from 'utils/analytics';
import logger from 'utils/logger';

import type { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import { hasLessDevisesInNextPlan } from 'services/price-plans/utils';

import { getUser, isPaymentMethodCreated } from 'state/root-reducer';
import type { State as RootState } from 'state/state-types';
import type { InvoiceStatus } from 'state/invoices/invoice-statuses';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { changePricePlan, fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import { fetchUser } from 'state/user/user-actions';

import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';
import withInvoicePolling from 'components/shared/with-invoice-polling/with-invoice-polling';
import type { InjectedInvoicePollingProps } from 'components/shared/with-invoice-polling/with-invoice-polling';

import PaymentErrorStep from 'components/user-flow/shared/payment-error-step/payment-error-step';

import renderPlanChangedNotificationMessage
  from 'components/user-flow/change-price-plan/plan-changed-notification/render-plan-changed-notification-message';
import type { NotificationEventData } from 'components/navigation/constants';
import PlanChangedNotification from '../plan-changed-notification/plan-changed-notification';
import ConfirmLessDevicesStep from '../confirm-less-devices-step/confirm-less-devices-step';
import ConfirmPricePlanStep from '../confirm-price-plan-step/confirm-price-plan-step';


const mapStateToProps = (state: RootState) => ({
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  user: getUser(state),
});

const mapActionsToProps = {
  changePricePlan,
  fetchAllSubscriptions,
  fetchUser,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Step = 'confirmPricePlanStep'
  | 'confirmLessDevicesStep'
  | 'paymentErrorStep'
  | 'updatePaymentDetailsStep'
  | 'addPaymentDetailsStep';

type Props = InjectedInvoicePollingProps & PropsFromRedux & InjectedIntlProps & {
  currentPricePlan: PricePlan;
  primarySubscription: Subscription;
  nextPricePlan: PricePlan;
  nextPricePlanCalculationData: NextPricePlanCalculationData;
  shouldHideBackButton?: boolean;
  onGoBackward: () => void;
  onClose: () => void;
  onStartPayment?: () => void;
  onStopPayment?: () => void;
  onSuccessPlanChange?: () => void;
  emitNotificationEvent: (data: NotificationEventData) => void;
  isEmbedded?: boolean;
};

type State = {
  step: Step;
  isProcessing: boolean;
  previousPricePlan: PricePlan | null;
};

const BACK_STEP_MAPPING: {[key in Step]: Step | 'goBackward'} = {
  confirmPricePlanStep: 'goBackward',
  confirmLessDevicesStep: 'confirmPricePlanStep',
  paymentErrorStep: 'confirmPricePlanStep',
  updatePaymentDetailsStep: 'paymentErrorStep',
  addPaymentDetailsStep: 'goBackward',
};

class UpgradeFlow extends PureComponent<Props, State> {
  state: State = {
    step: 'confirmPricePlanStep',
    isProcessing: false,
    previousPricePlan: null,
  };

  componentDidMount() {
    const { isPaymentMethodCreated } = this.props;

    if (!isPaymentMethodCreated) {
      this.setState({ step: 'addPaymentDetailsStep' });
    }
  }

  render() {
    const { shouldHideBackButton } = this.props;
    const { isProcessing } = this.state;

    return (
      <>
        {!shouldHideBackButton && (
          <div className="mb-5">
            <ButtonBack
              disabled={isProcessing}
              onClick={this.handleBackButtonClick}
            />
          </div>
        )}
        {this.renderStep()}
      </>
    );
  }

  renderStep() {
    const {
      currentPricePlan,
      nextPricePlan,
      nextPricePlanCalculationData,
      user,
    } = this.props;
    const { step, isProcessing } = this.state;

    switch (step) {
      case 'confirmPricePlanStep':
        return (
          <ConfirmPricePlanStep
            isProcessing={isProcessing}
            currentPricePlan={currentPricePlan}
            nextPricePlan={nextPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onSubmitNextPlan={this.handleSubmitNextPlan}
            user={user}
          />
        );
      case 'confirmLessDevicesStep':
        return (
          <ConfirmLessDevicesStep
            isProcessing={isProcessing}
            nextPricePlan={nextPricePlan}
            currentPricePlan={currentPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onConfirmLessDevices={this.changePlan}
          />
        );
      case 'paymentErrorStep':
        return (
          <PaymentErrorStep
            isProcessing={isProcessing}
            onRetry={this.changePlan}
            onUpdatePaymentDetails={this.handleOpenPaymentDetailsModal}
          />
        );
      case 'updatePaymentDetailsStep':
        return (
          <>
            <h3 className="mt-0 mb-3">
              <PaymentDetailsActionText action="update" />
            </h3>
            <PaymentForm
              onSuccessSubmit={this.handlePaymentDetailsUpdated}
            />
          </>
        );
      case 'addPaymentDetailsStep':
        return (
          <>
            <h3 className="mt-0 mb-3">
              <PaymentDetailsActionText action="add" />
            </h3>
            <PaymentForm
              onSuccessSubmit={this.handlePaymentDetailsUpdated}
            />
          </>
        );
      default:
        return null;
    }
  }

  handleBackButtonClick = () => {
    const { onGoBackward } = this.props;
    const { step } = this.state;

    const backStep = BACK_STEP_MAPPING[step];

    if (backStep === 'goBackward') {
      onGoBackward();
    } else {
      this.setState({ step: backStep });
    }
  }

  handleSubmitNextPlan = async () => {
    const { currentPricePlan, nextPricePlan } = this.props;

    if (hasLessDevisesInNextPlan(currentPricePlan, nextPricePlan)) {
      this.setState({ step: 'confirmLessDevicesStep' });

      return;
    }

    await this.changePlan();
  }

  handleOpenPaymentDetailsModal = () => {
    this.setState({ step: 'updatePaymentDetailsStep' });
  }

  handlePaymentDetailsUpdated = async () => {
    this.setState({ step: 'confirmPricePlanStep' });
  }

  changePlan = async () => {
    const {
      waitForPaymentSuccess,
      currentPricePlan,
      primarySubscription,
      nextPricePlan,
      changePricePlan,
      onStartPayment,
    } = this.props;

    if (onStartPayment) onStartPayment();

    this.setState({
      isProcessing: true,
      // need to remember previous price plan, because after changing price plan
      // property `currentPricePlan` will return new price plan object
      previousPricePlan: currentPricePlan,
    });

    try {
      const { strategy, invoiceId } = await changePricePlan(
        primarySubscription.id,
        nextPricePlan.id,
        { fetchSubscription: false },
      );

      // just show success message for unforeseen situations
      if (!invoiceId || strategy !== 'charge') {
        logger.logWarn(
          'Upgrade flow: Change price plan API return incorrect data',
          { strategy, invoiceId },
        );

        await this.successfulPlanChange();

        return;
      }

      await waitForPaymentSuccess(
        invoiceId,
        this.successfulPlanChange,
        this.showPaymentErrorStep,
        this.showDefaultError,
        { userFlow: 'upgrade' },
      );
    } catch (err) {
      this.showDefaultError();
      logger.logError('Upgrade flow: Error during change plan', err as typeof RequestError);
    }
  }

  successfulPlanChange = async () => {
    const {
      primarySubscription,
      nextPricePlan,
      nextPricePlanCalculationData,
      fetchAllSubscriptions,
      fetchUser,
      showSuccessNotification,
      onClose,
      onSuccessPlanChange,
      isEmbedded,
      emitNotificationEvent,
      intl
    } = this.props;
    const { previousPricePlan } = this.state;

    if (!previousPricePlan) {
      return;
    }

    if (isEmbedded) {
      const messageString = renderPlanChangedNotificationMessage({
        nextPricePlan,
        nextPricePlanCalculationData,
        previousPricePlan,
        primarySubscription,
        intl,
      });
      emitNotificationEvent({ message: messageString });
    } else {
      showSuccessNotification(
        <PlanChangedNotification
          nextPricePlan={nextPricePlan}
          nextPricePlanCalculationData={nextPricePlanCalculationData}
          previousPricePlan={previousPricePlan}
          primarySubscription={primarySubscription}
        />
      );
    }

    onSuccessPlanChange?.();

    await fetchUser();
    await fetchAllSubscriptions();
    onClose();
  }

  showPaymentErrorStep = (status: InvoiceStatus) => {
    const { onStopPayment } = this.props;

    this.setState({ isProcessing: false, step: 'paymentErrorStep' });
    analytics.trackEvent(events.SELECT_PLAN_PAGE_ERROR, { eventLabel: `Invoice ${status}` });

    onStopPayment?.();
  }

  showDefaultError = () => {
    const { showDangerNotification, onClose } = this.props;

    showDangerNotification(<DefaultError />);
    onClose();
  }
}

export { UpgradeFlow as PureUpgradeFlow };

export default connector(withInvoicePolling(injectIntl(UpgradeFlow)));
