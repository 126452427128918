import * as paymentProviders from 'services/payment-details-api/payment-providers';
import type { PaymentProviderName } from 'services/payment-details-api/payment-details-api';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import type { Campaign } from 'state/user/campaign-types';

import type { UserType } from './user-types';
import type { RegistrationFlow } from './registration-flow-types';
import * as userTypes from './user-types';


export type SocialProvider = 'apple' | 'google';

type SocialAccount = {
  provider: SocialProvider;
};

export type UserLocale = 'en-US'
  | 'de-DE'
  | 'es-ES'
  | 'fr-FR'
  | 'it-IT'
  | 'pt-BR'
  | 'uk-UA'
  | 'zh-CN';

export type UserAICredits = {
  left: number;
  limit: number;
  example: {
    gpt35: number;
    gpt4Turbo: number;
    whisper: number;
    dalle2: number;
  };
}

export type User = {
  id: number | null;
  name: string;
  email: string;
  emailConfirmed: boolean;
  registrationDate: number;
  familyMember: FamilyMember | null;
  isAuthenticated: boolean;
  desktopCcRequired: boolean;
  paymentInfoRequired: boolean;
  canSwitchToEduPlan: boolean;
  isLoading: boolean;
  isEduEmail: boolean;
  isReferral: boolean;
  type: UserType;
  locale: UserLocale;
  campaign: Campaign | null;
  isLocaleChanged: boolean;
  registrationFlow: RegistrationFlow | null;
  registrationFlowParams: Record<string, unknown>;
  socialAccounts: Array<SocialAccount>;
  trialPeriodLength: number;
  marketingSubscribed: boolean;
  isTeamMember: boolean;
  paymentProvider: PaymentProviderName;
  aiCredits: UserAICredits;
  isMarketingAffiliate: boolean;
  hasPriceFeatures?: boolean;
  signupGiftCardCode?: string;
  signupPromoCode?: string;
  isSetappMobileAvailable?: boolean;
  accountCurrency?: 'USD' | 'EUR';
};

export type UserState = Readonly<User>;

const initialState: UserState = {
  id: null,
  name: '',
  email: '',
  emailConfirmed: false,
  registrationDate: 0,
  familyMember: null,
  isAuthenticated: false,
  desktopCcRequired: false,
  paymentInfoRequired: false,
  canSwitchToEduPlan: false,
  isLoading: false,
  isEduEmail: false,
  isReferral: false,
  type: userTypes.REGULAR,
  locale: 'en-US',
  campaign: null,
  isLocaleChanged: false,
  registrationFlow: null,
  registrationFlowParams: {},
  socialAccounts: [],
  trialPeriodLength: 0,
  marketingSubscribed: false,
  isTeamMember: false,
  paymentProvider: paymentProviders.BRAINTREE,
  isMarketingAffiliate: false,
  aiCredits: {
    left: 0,
    limit: 0,
    example: {
      gpt35: 0,
      gpt4Turbo: 0,
      whisper: 0,
      dalle2: 0,
    },
  },
};

export default initialState;
