import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import { getCreateSupportRequestUrl } from 'utils/support';

import withRouter from 'components/navigation/navigation-with-router';
import AutoDownloadSection from 'components/shared/auto-download-section/auto-download-section';
import ImmediatelyDownloadLink from 'components/shared/immediately-download-link/immediately-download-link';
import SpecialOfferCodeDeliveryMessage from 'components/shared/special-offer-code-delivery-message/special-offer-code-delivery-message';

import { detectDesktopApp } from 'state/desktop-app/desktop-app-reducer';
import {
  hasDesktopAppAlreadyDetected,
  isDesktopAppAvailable,
  getUser,
  getUserSignupGiftCardCode,
  getUserSignupPromoCode,
  isEduUserWithInvalidEmail,
  isSpecialOfferCampaign,
  isUserFamilyMember,
  isUserFamilyOwner,
} from 'state/root-reducer';

import setappIcon from '../images/setapp-icon.svg';
import illustrationDe from './images/illustration-de.svg';
import illustrationEn from './images/illustration-en.svg';
import illustrationEs from './images/illustration-es.svg';
import illustrationFr from './images/illustration-fr.svg';
import illustrationIt from './images/illustration-it.svg';
import illustrationPtBr from './images/illustration-pt-br.svg';
import illustrationUk from './images/illustration-uk.svg';

import './successful-registration-default.scss';

const illustrations = {
  'en-US': illustrationEn,
  'de-DE': illustrationDe,
  'es-ES': illustrationEs,
  'fr-FR': illustrationFr,
  'it-IT': illustrationIt,
  'pt-BR': illustrationPtBr,
  'uk-UA': illustrationUk,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  hasDesktopAppAlreadyDetected: hasDesktopAppAlreadyDetected(state),
  isDesktopAppAvailable: isDesktopAppAvailable(state),
  campaign: getUser(state).campaign,
  isEduUserWithInvalidEmail: isEduUserWithInvalidEmail(state),
  isGiftCardCodeValid: Boolean(getUserSignupGiftCardCode(state)),
  isPromoCodeValid: Boolean(getUserSignupPromoCode(state)),
  isSpecialOfferCampaign: isSpecialOfferCampaign(state),
  userLocale: getUser(state).locale,
  isUserFamilyMember: isUserFamilyMember(state),
  isUserFamilyOwner: isUserFamilyOwner(state),
});

const mapActionsToProps = {
  detectDesktopApp,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = RouteComponentProps<any, any, { forceFamilyOwnerState?: boolean }> & ConnectedProps<typeof connector> & {
  onDirectDownloadClick: () => void;
  onLaunchSetappClick: () => void;
};

type State = {
  isUserFamilyMemberInternalState: boolean;
  isUserFamilyOwnerInternalState: boolean;
};

class SuccessfulRegistrationDefault extends PureComponent<Props, State> {
  state: State = {
    isUserFamilyMemberInternalState: false,
    isUserFamilyOwnerInternalState: false,
  };

  componentDidMount() {
    const {
      hasDesktopAppAlreadyDetected,
      detectDesktopApp,
      location,
      isUserFamilyMember,
      isUserFamilyOwner,
    } = this.props;

    const isForceFamilyOwnerState = (location.state && location.state.forceFamilyOwnerState) ?? false;

    this.setState({
      isUserFamilyMemberInternalState: isForceFamilyOwnerState || isUserFamilyMember,
      isUserFamilyOwnerInternalState: isForceFamilyOwnerState || isUserFamilyOwner,
    });

    if (!hasDesktopAppAlreadyDetected) {
      detectDesktopApp();
    }
  }

  render() {
    const { userLocale } = this.props;

    return (
      <div className="successful-registration-default" data-testid="default-view">
        <div className="successful-registration-default__content">
          <img
            className="successful-registration-default__icon"
            src={setappIcon}
            alt="Setapp icon"
            width={104}
            height={104}
          />
          <h2 className="mt-3 mb-2" data-qa="pageTitle">{this.renderTitle()}</h2>
          <p data-qa="pageSubTitle">{this.renderDescription()}</p>
          {this.renderButtons()}
        </div>
        <div>
          <img className="successful-registration-default__illustration" src={illustrations[userLocale]} alt="Badges" />
        </div>
      </div>
    );
  }

  renderTitle = () => {
    const { isEduUserWithInvalidEmail, isGiftCardCodeValid, isPromoCodeValid } = this.props;
    const { isUserFamilyMemberInternalState } = this.state;

    if (isEduUserWithInvalidEmail) {
      return (
        <FormattedMessage
          id="successfulRegistration.eduFlowInvalid.title"
          defaultMessage="Your account is created but we couldn’t identify you as a student."
        />
      );
    }

    if (isGiftCardCodeValid) {
      return (
        <FormattedMessage
          id="successfulRegistration.giftCardRedeem.title"
          defaultMessage="Your gift card is activated."
        />
      );
    }

    if (isPromoCodeValid) {
      return (
        <FormattedMessage
          id="successfulRegistration.promoCodeRedeem.title"
          defaultMessage="Your code is activated."
        />
      );
    }

    if (isUserFamilyMemberInternalState) {
      return (
        <FormattedMessage
          id="successfulRegistration.family.title"
          defaultMessage="Welcome to the family!"
        />
      );
    }

    return (
      <FormattedMessage
        id="successfulRegistration.default.title"
        defaultMessage="You’re all Setapped!"
      />
    );
  }

  renderDescription = () => {
    const {
      isDesktopAppAvailable,
      isEduUserWithInvalidEmail,
      isSpecialOfferCampaign,
      campaign,
    } = this.props;
    const { isUserFamilyMemberInternalState } = this.state;

    if (isEduUserWithInvalidEmail) {
      return (
        <FormattedMessage
          id="successfulRegistration.eduFlowInvalid.description"
          defaultMessage="The email you’ve entered is not on our EDU list. If you’re a student, {link} and we’ll give you a discount."
          values={{
            link: (
              <a
                href={getCreateSupportRequestUrl()}
                className="link-outer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="successfulRegistration.eduFlowInvalid.description.supportLink"
                  defaultMessage="drop us a line"
                />
              </a>
            ),
          }}
        />
      );
    }

    if ((campaign && isSpecialOfferCampaign) || (isUserFamilyMemberInternalState && !isDesktopAppAvailable)) {
      return (
        <>
          <FormattedMessage
            id="successfulRegistration.specialOffer.description.main"
            defaultMessage="Setapp desktop app will start downloading now!"
          />
          {campaign && (
            <>
              {' '}
              <SpecialOfferCodeDeliveryMessage campaign={campaign} />
            </>
          )}
        </>
      );
    }

    if (isUserFamilyMemberInternalState && isDesktopAppAvailable) {
      return (
        <>
          <FormattedMessage
            id="successfulRegistration.specialOffer.description.family"
            defaultMessage="Yay, you are officially a participant of this Setapp Family."
          />
        </>
      );
    }

    return (
      <FormattedMessage
        id="successfulRegistration.default.description"
        defaultMessage="Discover a smart way to get apps in your Downloads folder."
      />
    );
  }

  renderButtons = () => {
    const { isUserFamilyOwnerInternalState } = this.state;
    const {
      isDesktopAppAvailable,
      onLaunchSetappClick,
      onDirectDownloadClick,
    } = this.props;

    switch (true) {
      case isUserFamilyOwnerInternalState:
        return (
          <div className="mt-8">
            <Button
              className="successful-registration-default__invite-participants-button"
              size="lg"
              href={urls.family}
            >
              <FormattedMessage
                id="successfulRegistration.buttons.inviteParticipants"
                defaultMessage="Invite participants"
              />
            </Button>
          </div>
        );
      case isDesktopAppAvailable:
        return (
          <Button
            className="mt-5 successful-registration-default__launch-button"
            size="lg"
            onClick={onLaunchSetappClick}
          >
            <FormattedMessage id="successfulRegistration.default.launchButton" defaultMessage="Launch Setapp" />
          </Button>
        );
      default:
        return (
          <>
            <ImmediatelyDownloadLink
              customClass="successful-registration-default__download-hint"
              onDirectDownloadClick={onDirectDownloadClick}
            />
            <AutoDownloadSection />
          </>
        );
    }
  }
}

export { SuccessfulRegistrationDefault as PureSuccessfulRegistrationDefault };

export default connector(withRouter(SuccessfulRegistrationDefault));
