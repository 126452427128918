import React from 'react';
import type { ReactNode } from 'react';
import { Link } from 'components/navigation';

import urls from 'config/urls';

import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

import './ios-collection-banner.scss';

type Props = {
  id: number;
  appsList: IosApp[];
  title: string | ReactNode;
  description: string | ReactNode;
}

const IosCollectionBanner = ({ id, appsList, title, description }: Props) => (
  <Link
    to={`${urls.iosCollection}/${id}`}
    className="ios-collection-banner"
  >
    <div className="ios-collection-banner__apps">
      {appsList.map((app) => (
        <div className="ios-collection-banner__icon" key={app.id}>
          <img width="44" height="44" src={app.icon} alt={app.name} />
        </div>
      ))}
    </div>
    <h5 className="mt-6 mb-2">{title}</h5>
    <div className="ios-collection-banner__description">
      {description}
    </div>
  </Link>
);

export default IosCollectionBanner;
