import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'components/navigation';

import urls from 'config/urls';

import logger from 'utils/logger';

import { showSuccessNotification } from 'state/notifier/notifier-reducer';
import { getPrimarySubscription } from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { retryCharge } from 'state/subscription/subscription-actions';

import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import useSubscriptionActiveWaiting from 'components/hooks/use-subscription-active-waiting/use-subscription-active-waiting';
import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';
import { getHasAppAccessIssue } from 'components/pages/app-access-issue/utils/app-access-issue-storage';

type Params = {
  onFailed: () => void;
};

const useRetryPayment = ({ onFailed }: Params) => {
  const history = useHistory();
  const primarySubscription = useSelector(getPrimarySubscription);

  const retryChargeAction = useDispatchAction(retryCharge);
  const showSuccessNotificationAction = useDispatchAction(showSuccessNotification);

  const [isProcessing, setIsProcessing] = useState(false);

  const { waitForSubscriptionActive } = useSubscriptionActiveWaiting();

  const handleFailedRetryPayment = () => {
    setIsProcessing(false);
    onFailed();
  };

  const retryPayment = async () => {
    setIsProcessing(true);

    // Try to retry charge first
    try {
      await retryChargeAction();
    } catch (error) {
      logger.logError('Failed to retry charge', error);

      handleFailedRetryPayment();

      return;
    }

    // If retry charge was successful, wait for subscription to be active
    try {
      await waitForSubscriptionActive();
    } catch (error) {
      if (primarySubscription && primarySubscription.status === subscriptionStatuses.BLOCKED) {
        // If subscription is still blocked after waiting timeout, it indicates that payment was not successful
        handleFailedRetryPayment();
      } else {
        // In other cases, redirect to subscription page. Subscription page will monitor subscription status further.
        history.push(urls.subscription);
      }

      return;
    }

    if (getStoredCustomerOauthError() === 'BLOCKED_CUSTOMER') {
      history.push(urls.customerOauth);

      return;
    }

    if (getHasAppAccessIssue()) {
      history.push(urls.appAccessGranted);

      return;
    }

    // On success, show notification and redirect to subscription page
    showSuccessNotificationAction(
      <FormattedMessage
        id="paymentRetryFlow.paymentSuccess"
        defaultMessage="Subscription back on!"
      />
    );
    history.push(urls.subscription);
  };

  return {
    isProcessing,
    retryPayment,
  };
};

export default useRetryPayment;
