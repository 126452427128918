import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Notification } from '@setapp/ui-kit';

import { Link } from 'components/navigation';

import analytics from 'utils/analytics/index';

import './switch-plan-notification.scss';

type Props = {
  onHide: () => void;
  submitEventName: string;
  children: ReactNode;
  buttonLink: string;
};

const SwitchPlanNotification = ({ onHide, submitEventName, children, buttonLink }: Props) => (
  <Notification
    type="info"
    onClose={onHide}
  >
    <div className="switch-plan-notification__container">
      {children}
      <Button
        className="switch-plan-notification__button"
        tag={Link}
        variant="primary-outline"
        size="sm"
        to={buttonLink}
        onClick={() => analytics.trackEvent(submitEventName)}
        data-qa="changePlanInNotificationBtn"
      >
        <FormattedMessage
          id="switchPlanNotification.switchNowButton"
          defaultMessage="Switch now"
        />
      </Button>
    </div>
  </Notification>
);

export default SwitchPlanNotification;
