import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import { Redirect } from 'components/navigation';
import DefaultError from 'components/shared/default-error/default-error';

import { getInvoicesList, isUserPlainFamilyMember, isFreeUser } from 'state/root-reducer';
import { fetchInvoices } from 'state/invoices/invoices-actions';
import type { Invoice } from 'state/invoices/invoices-initial-state';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import PageTitle from 'components/shared/page-title/page-title';
import PageDescription from 'components/shared/page-description/page-description';

import urls from 'config/urls';
import PaymentHistoryEmptyState from './payment-history-empty-state/payment-history-empty-state';
import PaymentHistoryList from './payment-history-list/payment-history-list';


type Props = {
  fetchInvoices: () => Promise<void>;
  showDangerNotification: (message: ReactNode) => void;
  invoices: Array<Invoice>;
  isUserPlainFamilyMember: boolean;
  isFreeUser: boolean;
};

class PaymentHistoryPage extends Component<Props> {
  componentDidMount() {
    const { fetchInvoices, showDangerNotification } = this.props;

    return fetchInvoices()
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const { invoices, isUserPlainFamilyMember, isFreeUser } = this.props;

    if (isUserPlainFamilyMember || isFreeUser) {
      return <Redirect to={urls.subscription} />;
    }

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="paymentHistory.title" defaultMessage="Receipts" />
        </PageTitle>
        <PageDescription>
          {invoices.length
            ? <FormattedMessage
              id="paymentHistory.subtitle"
              defaultMessage="Here's a list of all your successful payments with taxes. You can download the invoices if you need to."
              />
            : <FormattedMessage
              id="paymentHistory.subtitleNoInvoices"
              defaultMessage="Receipts from successful payments will appear here."
              />}
        </PageDescription>
        <Row>
          <Col md={10} lg={8}>
            {invoices.length
              ? <PaymentHistoryList invoices={invoices} />
              : <PaymentHistoryEmptyState />}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: getInvoicesList(state),
  isUserPlainFamilyMember: isUserPlainFamilyMember(state),
  isFreeUser: isFreeUser(state),
});

export { PaymentHistoryPage as PurePaymentHistoryPage };

const mapActionsToProps = {
  fetchInvoices,
  showDangerNotification,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
// @ts-expect-error TS(2345): Argument of type 'typeof PaymentHistoryPage' is no... Remove this comment to see the full error message
)(PaymentHistoryPage);
