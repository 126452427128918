import React, { useState } from 'react';
import type { ChangeEvent, ReactNode } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Button, ButtonLoading } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import logger from 'utils/logger';

import getPricePlanMetadata  from 'services/price-plans/price-plan-metadata-mapper';
import {
  isFeaturedPricePlan,
  isFeaturedIosPricePlan,
  getBillingPeriodsPricePlans,
  isPromotedPricePlan,
  isIosAdvancedPricePlan,
} from 'services/price-plans/utils';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import {
  getFeatureFlags,
  getShowEstimatedVatIncludedWarning,
  isMembershipPriceIncreaseEnabled
} from 'state/root-reducer';

import { FormattedPrice } from 'components/shared/formatter/formatter';

import PricePlanSwitcher from '../price-plan-switcher/price-plan-switcher';
import type { SwitcherData } from '../price-plan-switcher/price-plan-switcher';

import './signup-payment-details-description.scss';

type Props = {
  isPricePlanSelected: boolean;
  trialLength: number;
  pricePlan: PricePlan;
  featuredPricePlans: PricePlan[];
  onStartTrialClick: () => void;
  onPricePlanSwitcherChange: (event: ChangeEvent<HTMLInputElement>) => void;
  customTitle?: ReactNode;
};

function SignupPaymentDetailsDescription({
  isPricePlanSelected,
  trialLength,
  pricePlan,
  featuredPricePlans,
  onStartTrialClick,
  onPricePlanSwitcherChange,
  customTitle,
}: Props) {
  const showEstimatedVatIncludedWarning = useSelector(getShowEstimatedVatIncludedWarning);
  const [isLoading, setIsLoading] = useState(false);
  const { percentage } = useSelector(isMembershipPriceIncreaseEnabled);
  const { trialOnlyForAnnualRelaunch } = useSelector(getFeatureFlags);

  const planTitle = getPricePlanMetadata(pricePlan).titleFull;
  const monthlyPrice = pricePlan.price / pricePlan.paidMonth;
  const showBillingPerYear = isFeaturedPricePlan(pricePlan.tierType) && (pricePlan.paidMonth === 12);
  const isIosPlan = isFeaturedIosPricePlan(pricePlan.tierType);
  const iosSeatCount = pricePlan.features?.iosSeatsCount ?? 0;
  const isPromotedPlan = isPromotedPricePlan(pricePlan.tierType);
  const isIosAdvancedPlan = isIosAdvancedPricePlan(pricePlan.tierType);

  const planTitleClasses = classNames('mt-0', {
    'mb-2': !isIosPlan,
    'mb-0': isIosPlan,
  });

  const isMonthlyPlanWithoutTrial = trialOnlyForAnnualRelaunch.value && pricePlan.paidMonth === 1;

  const getPricePlanSwitcherData = (): SwitcherData[] | null => {
    const { monthlyPricePlan, annualPricePlan } = getBillingPeriodsPricePlans(featuredPricePlans, pricePlan);

    if (!monthlyPricePlan || !annualPricePlan) {
      logger.logError(
        'SignupPaymentDetailsDescription: can not get price plan switcher data',
        { pricePlanTierType: pricePlan.tierType },
      );

      return null;
    }

    const monthlyPricePerMonth = monthlyPricePlan.price;
    const annualPricePerMonth = annualPricePlan.price / annualPricePlan.paidMonth;
    const annualPricePerYear = annualPricePlan.price;

    const monthlyTierType = monthlyPricePlan.tierType;
    const annualTierType = annualPricePlan.tierType;

    const monthlySwitcherData = {
      name: (
        <FormattedMessage
          id="outer.signup.paymentDetails.pricePlanSwitcher.monthlyPriceName"
          defaultMessage="Monthly"
        />
      ),
      price: (
        <FormattedMessage
          id="outer.signup.paymentDetails.pricePlanSwitcher.monthlyPrice"
          defaultMessage="{price}/mo"
          values={{
            price: (
              <FormattedPrice
                price={monthlyPricePerMonth}
                currency={pricePlan.currency}
              />
            ),
          }}
        />
      ),
      priceAfterTrial: trialOnlyForAnnualRelaunch.value ? undefined : (
        <FormattedMessage
          id="outer.signup.paymentDetails.pricePlanSwitcher.monthlyPriceAfterTrial"
          defaultMessage="{price}/month after trial"
          values={{
            price: <FormattedPrice price={monthlyPricePerMonth} currency={pricePlan.currency} />,
          }}
        />
      ),
      value: monthlyTierType,
    };
    const annualSwitcherData = {
      name: (
        <FormattedMessage
          id="outer.signup.paymentDetails.pricePlanSwitcher.annualPriceName"
          defaultMessage="Annual"
        />
      ),
      price: (
        <FormattedMessage
          id="outer.signup.paymentDetails.pricePlanSwitcher.annualPrice"
          defaultMessage="{price}/mo"
          values={{
            price: (
              <FormattedPrice
                price={annualPricePerMonth}
                currency={pricePlan.currency}
              />
            ),
          }}
        />
      ),
      priceAfterTrial: (
        <FormattedMessage
          id="outer.signup.paymentDetails.pricePlanSwitcher.annualPriceAfterTrial"
          defaultMessage="{price}/year after trial"
          values={{
            price: <FormattedPrice price={annualPricePerYear} currency={pricePlan.currency} />,
          }}
        />
      ),
      value: annualTierType,
      discountMessage: (
        <FormattedMessage
          id="outer.signup.paymentDetails.description.switcherDiscount"
          defaultMessage="{percentage} cheaper"
          values={{ percentage }}
        />
      ),
    };

    return [monthlySwitcherData, annualSwitcherData];
  };

  const pricePlanSwitcherData: SwitcherData[] | null = isPromotedPlan ? getPricePlanSwitcherData() : null;
  const showPricePlanSwitcher = isPromotedPlan && Boolean(pricePlanSwitcherData);

  const handleStartTrialClick = () => {
    setIsLoading(true);
    onStartTrialClick();
  };

  const renderBillingMessage = () => {
    if (isPromotedPlan) {
      return (
        <FormattedMessage
          id="outer.signup.paymentDetails.paymentDetails.annualTestBillingMessage"
          defaultMessage="Your bill may include tax"
        />
      );
    }

    if (showBillingPerYear) {
      return (
        <FormattedMessage
          id="outer.signup.paymentDetails.features.pricePerYear"
          defaultMessage="{price}/year after trial"
          values={{
            price: <FormattedPrice price={pricePlan.price} currency={pricePlan.currency} />,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="outer.signup.paymentDetails.features.price"
        defaultMessage="{price}/month after trial"
        values={{
          price: <FormattedPrice price={monthlyPrice} currency={pricePlan.currency} />,
        }}
      />
    );
  };

  const renderTitle = () => {
    /* istanbul ignore next */
    if (customTitle) {
      return customTitle;
    }

    if (isPromotedPlan) {
      return (
        <FormattedMessage
          id="outer.signup.paymentDetails.description.noPreselectedPlanTitle"
          defaultMessage="Customize your plan"
        />
      );
    }

    return (
      <FormattedMessage
        id="outer.signup.paymentDetails.description.title"
        defaultMessage="Setting up your trial"
      />
    );
  };

  return (
    <div className="signup-payment-details-description">
      <h3 className="mb-4" data-qa="pageTitle">
        {renderTitle()}
      </h3>
      <p className="mb-0">
        <FormattedMessage
          id="outer.signup.paymentDetails.description.subTitle"
          defaultMessage="Your plan"
        />
      </p>
      <span className="signup-payment-details-description__title">
        <h4 className={planTitleClasses}>
          <FormattedMessage
            id="outer.signup.paymentDetails.description.trial"
            defaultMessage="{planTitle} {trialTextLength}"
            values={{
              planTitle,
              trialTextLength: isMonthlyPlanWithoutTrial ? undefined : (
                <span className="text_lg">
                  <FormattedMessage
                    id="outer.signup.paymentDetails.description.trialLength"
                    defaultMessage="(Free for {trialLength} days)"
                    values={{ trialLength }}
                  />
                </span>
              ),
            }}
          />
        </h4>
      </span>

      {(isIosPlan || isIosAdvancedPlan) && (
        <p className="mb-6">
          <FormattedMessage
            id="outer.signup.paymentDetails.description.iosDevices"
            defaultMessage="For {count} iOS devices"
            values={{ count: iosSeatCount }}
          />
        </p>
      )}

      {showPricePlanSwitcher && pricePlanSwitcherData && (
        <>
          <PricePlanSwitcher
            pricePlanSwitcherData={pricePlanSwitcherData}
            tierType={pricePlan.tierType}
            isPricePlanSelected={isPricePlanSelected}
            onChange={onPricePlanSwitcherChange}
          />
          <ButtonLoading
            block
            size="lg"
            className="mb-6"
            isLoading={isLoading}
            disabled={!isPricePlanSelected || isLoading}
            onClick={handleStartTrialClick}
          >
            {isMonthlyPlanWithoutTrial ? (
              <FormattedMessage
                id="outer.signup.paymentDetails.button.getStarted"
                defaultMessage="Get Started"
              />) : (
              <FormattedMessage
                id="outer.signup.paymentDetails.button.startTrial"
                defaultMessage="Start your {trialLength}-day trial"
                values={{ trialLength }}
              />)}
          </ButtonLoading>
        </>
      )}

      {!isMonthlyPlanWithoutTrial && (
        <>
          <div className="signup-payment-details-description__delimiter" />

          <p className="text_lg text_weight-bold">
            <FormattedMessage
              id="outer.signup.paymentDetails.description.trialBegin"
              defaultMessage="Trial begins: {beginning}"
              values={{
                beginning: isIosAdvancedPlan ? (
                  <FormattedMessage
                    id="outer.signup.paymentDetails.description.trialBegin.today"
                    defaultMessage="Today"
                  />
                ) : (
                  <FormattedMessage
                    id="outer.signup.paymentDetails.description.trialBegin.now"
                    defaultMessage="Now"
                  />
                ),
              }}
            />
            <br />
            <FormattedMessage
              id="outer.signup.paymentDetails.description.trialBilling"
              defaultMessage="Billing date: {date}"
              values={{
                date: (
                  <FormattedDate
                    value={new Date().setDate(new Date().getDate() + trialLength)}
                    year="numeric"
                    month="long"
                    day="numeric"
                  />
                ),
              }}
            />
          </p>

          <ul className="signup-payment-details-description__feature-list">
            <li className="mb-2 signup-payment-details-description__feature-list-item">
              <FormattedMessage
                id="outer.signup.paymentDetails.features.cancel"
                defaultMessage="Cancel anytime"
              />
            </li>
            <li className="mb-2 signup-payment-details-description__feature-list-item">
              <FormattedMessage
                id="outer.signup.paymentDetails.features.charges"
                defaultMessage="No charges during trial"
              />
            </li>
            <li className="mb-2 signup-payment-details-description__feature-list-item">
              <FormattedMessage
                id="outer.signup.paymentDetails.features.reminder"
                defaultMessage="Two email reminders before trial ends"
              />
            </li>
            <li className="mb-2 signup-payment-details-description__feature-list-item">
              {renderBillingMessage()}
            </li>
          </ul>
        </>
      )}

      {!showPricePlanSwitcher && (
        <Button
          block
          size="lg"
          className="mt-8"
          onClick={onStartTrialClick}
        >
          <FormattedMessage
            id="outer.signup.paymentDetails.button.startTrial"
            defaultMessage="Start your {trialLength}-day trial"
            values={{ trialLength }}
          />
        </Button>
      )}
      {showEstimatedVatIncludedWarning && (
        <p className="mt-7">
          <FormattedMessage
            id="outer.signup.paymentDetails.description.estimatedVatIncluded"
            defaultMessage="Price after trial is an estimate for your location. The final amount may vary depending on applicable VAT."
          />
        </p>
      )}
    </div>
  );
}

export default SignupPaymentDetailsDescription;
