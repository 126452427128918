import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { Link } from 'components/navigation';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import urls from 'config/urls';

import './signup-invitation-error.scss';

const SignUpInvitationError = () => (
  <div className="signup-invitation-error text-center">
    <AnimatedLogo hasCaption />
    <h4 className="signup-invitation-error-title">
      <FormattedMessage
        id="outer.signup.invalidInvitationToken.header"
        defaultMessage="Seems like this invite was already used."
      />
    </h4>
    <p className="signup-invitation-error-text">
      <FormattedMessage
        id="outer.signup.invalidInvitationToken.subHeader"
        defaultMessage="If you have a Setapp account, please Log in."
      />
    </p>
    <Button block tag={Link} to={urls.login}>
      <FormattedMessage id="outer.signup.invalidInvitationToken.btnCaption" defaultMessage="Log in" />
    </Button>
  </div>
);

export default SignUpInvitationError;
