import React, { useEffect, useState, ReactElement } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useHistory } from 'components/navigation';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import useOAuthClientInfo from 'components/hooks/use-oauth-client-info/use-oauth-client-info';
import { getUser } from 'state/root-reducer';
import { fetchUser } from 'state/user/user-actions';
import urls from 'config/urls';

const mapStateToProps = (state) => {
  const { id, isAuthenticated, emailConfirmed } = getUser(state);

  return {
    isUserFetched: Boolean(id),
    isUserAuthenticated: isAuthenticated,
    isEmailConfirmed: emailConfirmed,
  };
};

const mapActionsToProps = {
  fetchUser,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector> & { children: ReactElement };

const OAuthAuthenticationGuard = (props: Props) => {
  const { authScope } = useOAuthClientInfo();
  const {
    children,
    isUserFetched,
    isUserAuthenticated,
    isEmailConfirmed,
    fetchUser,
  } = props;

  const initialAccessGranted = isUserFetched && isUserAuthenticated;
  const [isAccessGranted, setAccessGranted] = useState(initialAccessGranted);
  const location = useLocation();
  const history = useHistory();

  const redirectToOAuthLogin = () => {
    const nextLocation = `${location.pathname}${location.search}`;
    const search = queryString.stringify({ 'redirect_url': nextLocation });

    history.replace({ pathname: urls.oauthLogin, search });
  };

  const redirectToOAuthConfirmEmail = () => history.replace(urls.oauthConfirmEmail + location.search);

  useEffect(() => {
    async function checkUserAuth() {
      if (!isUserAuthenticated) {
        redirectToOAuthLogin();
      } else if (!isUserFetched) {
        try {
          await fetchUser();
        } catch {
          redirectToOAuthLogin();
        }
      } else if (!isEmailConfirmed && authScope.includes('userinfo.email')) {
        redirectToOAuthConfirmEmail();
        setAccessGranted(true);
      } else {
        setAccessGranted(true);
      }
    }
    checkUserAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authScope, isEmailConfirmed, isUserAuthenticated, isUserFetched]);

  return isAccessGranted ? children : (
    <div style={{ margin: 'auto' }}>
      <AnimatedLogo animate />
    </div>
  );
};

export { OAuthAuthenticationGuard as PureOAuthAuthenticationGuard };

export default connector(OAuthAuthenticationGuard);
