import React, {
  useState,
  useCallback,
  ReactNode,
  FormEvent,
  ChangeEvent,
  ReactElement, MouseEvent,
} from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import { FormField, FormCheckbox, FormFieldWrapper } from '@setapp/ui-kit';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import { validators } from 'utils/auth-validation';

import { Link } from 'components/navigation';
import SimplifiedForm from 'components/shared/simplified-form/simplified-form';
import OuterForm from 'components/shared/outer-form/outer-form';
import TextDelimiter from 'components/shared/text-delimiter/text-delimiter';
import SocialAuthButtons from 'components/shared/social-auth-buttons/social-auth-buttons';
import PasswordRequirementsPopup from 'components/shared/password-requirements-popup/password-requirements-popup';

import './signup-form.scss';

const messages = defineMessages({
  namePlaceholder: {
    id: 'outer.signup.namePlaceholder',
    defaultMessage: 'Your name',
  },
  emailPlaceholder: {
    id: 'outer.signup.emailPlaceholder',
    defaultMessage: 'Your email',
  },
  passwordPlaceholder: {
    id: 'outer.signup.passwordPlaceholder',
    defaultMessage: 'Create password',
  },
});

interface PureProps {
  onFormSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onTextFieldChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubscribedChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTermsAcceptedChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSocialButtonClick?: (e: MouseEvent<HTMLElement>) => Promise<void>;
  isRequestProcessing: boolean;
  isSubscribed: boolean;
  termsAccepted: boolean;
  emailDisabled: boolean;
  withSocial: boolean;
  name: string;
  email: string;
  password: string;
  giftCard?: string;
  promoCode?: string;
  nameError?: ReactNode;
  emailError?: ReactNode;
  passwordError?: ReactNode;
  genericError: ReactNode;
  termsAcceptedError: ReactNode;
  simplified: boolean;
  submitButtonText: ReactNode;
  withPasswordRequirementsPopup?: boolean;
  isPasswordInvalid: boolean;
  captcha?: ReactElement<typeof FormFieldWrapper>;
  loginNextLocation?: string;
}

type Props = PureProps & InjectedIntlProps;

const SignUpForm = (props: Props) => {
  const {
    onTextFieldChange,
    onFormSubmit,
    captcha,
    onSocialButtonClick,
    onSubscribedChange,
    onTermsAcceptedChange,
    giftCard,
    promoCode,
    withSocial,
    name,
    password,
    email,
    nameError,
    emailError,
    passwordError,
    genericError,
    termsAcceptedError,
    isRequestProcessing,
    isSubscribed,
    termsAccepted,
    emailDisabled,
    intl,
    simplified,
    submitButtonText,
    withPasswordRequirementsPopup,
    isPasswordInvalid,
    loginNextLocation,
  } = props;

  const [isPasswordFieldOnFocus, setIsPasswordFieldOnFocus] = useState(false);

  const showPasswordRequirementsPopup = isPasswordFieldOnFocus || isPasswordInvalid;

  const handleFieldFocus = useCallback(() => {
    setIsPasswordFieldOnFocus(true);
  }, []);

  const handleFieldBlur = useCallback(() => {
    setIsPasswordFieldOnFocus(false);
  }, []);

  const getSignUpForm = () => (
    <>
      {withSocial && (
        <div>
          <SocialAuthButtons
            className="signup-form__social-buttons"
            onButtonClick={onSocialButtonClick}
            signupParams={{
              marketingSubscribed: isSubscribed,
              giftCard,
              promoCode,
            }}
          />

          <TextDelimiter>
            <FormattedMessage
              id="outer.signup.signUpWithEmail"
              defaultMessage="or sign up with email"
            />
          </TextDelimiter>
        </div>
      )}

      <FormField
        id="name"
        type="text"
        name="name"
        value={name}
        autoComplete="name"
        label={<FormattedMessage id="outer.signup.nameLabel" defaultMessage="Name" />}
        placeholder={intl.formatMessage(messages.namePlaceholder)}
        helpText={nameError}
        invalid={Boolean(nameError)}
        onChange={onTextFieldChange}
        autoFocus
        qaLabel="nameFormGroup"
      />
      <FormField
        id="email"
        type="email"
        name="email"
        value={email}
        autoComplete="email"
        label={<FormattedMessage id="outer.signup.emailLabel" defaultMessage="Email" />}
        placeholder={intl.formatMessage(messages.emailPlaceholder)}
        helpText={emailError}
        invalid={Boolean(emailError)}
        onChange={onTextFieldChange}
        qaLabel="emailFormGroup"
        disabled={emailDisabled}
      />
      <div className="signup-form__password-requirements-popup-wrapper">
        <FormField
          id="password"
          type="password"
          name="password"
          value={password}
          autoComplete="new-password"
          label={<FormattedMessage id="outer.signup.passwordLabel" defaultMessage="Password" />}
          placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          helpText={passwordError}
          invalid={Boolean(passwordError)}
          onChange={onTextFieldChange}
          qaLabel="passwordFormGroup"
          onFocus={handleFieldFocus}
          onBlur={handleFieldBlur}
        />

        {withPasswordRequirementsPopup && (
          <PasswordRequirementsPopup
            isMatchMinLength={validators.passwordMatchMinLength(password)}
            isContainsLower={validators.passwordContainsLower(password)}
            isContainsUpper={validators.passwordContainsUpper(password)}
            showErrorState={isPasswordInvalid}
            showPopup={showPasswordRequirementsPopup}
            position="right"
          />
        )}
      </div>

      {captcha && <div className="signup-form__captcha-container">{captcha}</div>}

      <div className="form-group" data-qa="subscribeFormGroup">
        <FormCheckbox id="signup-subscribe-checkbox" checked={isSubscribed} onChange={onSubscribedChange}>
          <span className="signup-form__note">
            <FormattedMessage
              id="outer.signup.subscribeCaption"
              defaultMessage="I want to get pro advice on Mac apps and exclusive member offers."
            />
          </span>
        </FormCheckbox>
      </div>
      <div className="form-group" data-qa="acceptTermsFormGroup">
        <FormCheckbox
          id="termsAccepted"
          name="termsAccepted"
          checked={termsAccepted}
          onChange={onTermsAcceptedChange}
        >
          <span className="signup-form__note">
            <FormattedMessage
              id="outer.signup.termsCaption"
              defaultMessage="Accept {termsOfService}, {privacyPolicy} and {cookiePolicy}"
              values={{
                termsOfService: (
                  <a href={externalUrls.termsOfUse} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      id="outer.signup.termsOfUseLinkLabel"
                      defaultMessage="Terms&nbsp;of&nbsp;Service"
                    />
                  </a>
                ),
                privacyPolicy: (
                  <a href={externalUrls.privacy} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      id="outer.signup.privacyPolicyLinkLabel"
                      defaultMessage="Privacy&nbsp;Policy"
                    />
                  </a>
                ),
                cookiePolicy: (
                  <a href={externalUrls.cookiePolicy} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      id="outer.signup.cookiePolicyLinkLabel"
                      defaultMessage="Cookie&nbsp;Policy"
                    />
                  </a>
                ),
              }}
            />
          </span>
        </FormCheckbox>
        {termsAcceptedError && (
          <span className="has-error">
            <span className="help-block">
              {termsAcceptedError}
            </span>
          </span>
        )}
      </div>
    </>
  );

  if (simplified) {
    return (
      <SimplifiedForm
        submitBtnCaption={submitButtonText}
        onSubmit={onFormSubmit}
        isSubmitProcessing={isRequestProcessing}
        errorMessage={genericError}
        qaLabel="signUpForm"
      >
        {getSignUpForm()}
      </SimplifiedForm>
    );
  }

  return (
    <OuterForm
      title={<FormattedMessage id="outer.signup.title" defaultMessage="Start your Setapp membership" />}
      submitBtnCaption={<FormattedMessage id="outer.signup.submitBtnCaption" defaultMessage="Continue" />}
      footer={(
        <p className="text-center">
          <span className="signup-form__note">
            <FormattedMessage
              id="outer.signup.signInLinkDescription"
              defaultMessage="Got an account? {loginLink}"
              values={{
                loginLink: (
                  <Link
                    to={{
                      pathname: urls.login,
                      ...(loginNextLocation && {
                        state: {
                          nextLocation: { pathname: loginNextLocation }
                        }
                      }),
                    }}
                  >
                    <FormattedMessage
                      id="outer.signup.signInLinkLabel"
                      defaultMessage="Sign in"
                    />
                  </Link>
                ),
              }}
            />
          </span>
        </p>
      )}
      onSubmit={onFormSubmit}
      errorMessage={genericError}
      isSubmitProcessing={isRequestProcessing}
      qaLabel="signUpForm"
    >
      {getSignUpForm()}
    </OuterForm>
  );
};

SignUpForm.defaultProps = {
  isRequestProcessing: false,
  isSubscribed: false,
  termsAccepted: false,
  emailDisabled: false,
  withSocial: true,
  name: '',
  email: '',
  password: '',
  genericError: '',
  simplified: false,
  submitButtonText: <FormattedMessage id="form.signup.submitBtnCaption" defaultMessage="Continue" />,
  withPasswordRequirementsPopup: true,
};

export { SignUpForm as PureSignUpForm };

export default injectIntl<PureProps>(SignUpForm);
