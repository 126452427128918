import React, { Component } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnimatedLogo } from '@setapp/ui-kit';

import { type RouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';
import { removeQueryParams } from 'utils/location';

import { calculateNextPricePlan } from 'services/price-plans/price-plans-api';
import type { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import { getOppositeBillingPeriodPlan, isAiPricePlan, isFeaturedFamilyPricePlan } from 'services/price-plans/utils';
import { isActive } from 'services/subscriptions/subscription';
import MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER from 'services/manage-subscription/manage-subscription-preselected-step-mapper';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { changePricePlan } from 'state/subscription/subscription-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchUser } from 'state/user/user-actions';
import {
  isCancelSubscriptionAllowed,
  getAvailablePricePlans,
  getPricePlans,
  getPrimarySubscription,
  getDisplayedPricePlan,
  isSubscriptionWithDiscount,
  isUserFamilyOwner,
  isPaymentMethodFetched, isMembershipPriceIncreaseEnabled,
} from 'state/root-reducer';

import withRouter from 'components/navigation/navigation-with-router';
import ButtonBack from 'components/shared/button-back/button-back';
import ConfirmLoseDiscount from 'components/shared/confirm-lose-discount/confirm-lose-discount';
import DefaultError from 'components/shared/default-error/default-error';
import Modal from 'components/shared/modal/modal';

import ChangeBillingPeriodFlow from 'components/user-flow/change-price-plan/change-billing-period-flow/change-billing-period-flow';
import ChangePricePlanFlow from 'components/user-flow/change-price-plan/change-price-plan-flow/change-price-plan-flow';
import { getIsInsideSetappMobileFlow } from 'components/pages/setapp-mobile/utils/setapp-mobile-flow-storage';
import { SETAPP_MOBILE_SUBSCRIPTION_NOTIF_QUERY } from 'components/pages/setapp-mobile/partials/onboarding-screen/onboarding-screen';
import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';

import ChangePlanStep from './change-plan-step/change-plan-step';
import ConfirmCancelFamilyStep from './confirm-cancel-family-step/confirm-cancel-family-step';
import CurrentPlanStep from './current-plan-step/current-plan-step';

import './manage-subscription.scss';

const mapStateToProps = (state) => ({
  isCancelSubscriptionAllowed: isCancelSubscriptionAllowed(state),
  isPlansListLoading: getPricePlans(state).isLoading,
  isUserFamilyOwner: isUserFamilyOwner(state),
  isPaymentMethodFetched: isPaymentMethodFetched(state),
  primarySubscription: getPrimarySubscription(state),
  pricePlans: getAvailablePricePlans(state),
  displayedPricePlan: getDisplayedPricePlan(state),
  isSubscriptionWithDiscount: isSubscriptionWithDiscount(state),
  annualDiscountPercentage: isMembershipPriceIncreaseEnabled(state).percentage,
});

const mapActionsToProps = {
  changePricePlan,
  fetchPricePlans,
  fetchUser,
  fetchPaymentMethod,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Step = 'currentPlanStep'
  | 'changePlanStep'
  | 'changePricePlanFlow'
  | 'changeBillingPeriodFlow'
  | 'confirmChangeBillingPeriodAndLoseDiscount'
  | 'confirmChangePlanAndLoseDiscount'
  | 'confirmCancelFamilyStep';

type PreselectedStepKey = keyof typeof MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER;
export type PreselectedStepValue = typeof MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER[PreselectedStepKey];

type Props = RouteComponentProps & PropsFromRedux & {
  show: boolean;
  onHide: () => void;
  selectedStep?: PreselectedStepValue;
  selectedNextPlan?: PricePlan;
  preselectedNextPlanId?: number;
  onExited: () => void;
};

type State = {
  isProcessing: boolean;
  isPageLoading: boolean;
  showCloseButton: boolean;
  step: Step;
  nextPricePlan?: PricePlan;
  nextPricePlanCalculationData?: NextPricePlanCalculationData;
  previousPricePlan?: PricePlan;
  errorMessage?: ReactNode;
  shouldHideBackButton?: boolean;
};

const INITIAL_STATE: State = {
  isProcessing: false,
  isPageLoading: false,
  showCloseButton: true,
  step: 'currentPlanStep',
};

const BACK_STEP_MAPPING: {[key in Step]?: Step} = {
  changePlanStep: 'currentPlanStep',
  changePricePlanFlow: 'changePlanStep',
  changeBillingPeriodFlow: 'currentPlanStep',
  confirmChangeBillingPeriodAndLoseDiscount: 'currentPlanStep',
  confirmChangePlanAndLoseDiscount: 'currentPlanStep',
  confirmCancelFamilyStep: 'currentPlanStep',
};

class ManageSubscription extends Component<Props, State> {
  state: State = INITIAL_STATE;

  componentDidMount() {
    const { fetchPricePlans, showDangerNotification, selectedStep, history: { location } } = this.props;
    const isAiSpecialOfferPage = location.pathname.includes('ai-special-offer');

    return fetchPricePlans(isAiSpecialOfferPage)
      .then(() => {
        if (selectedStep) {
          this.handlePreselectedStep(selectedStep);
        }
      })
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const { isPlansListLoading, show } = this.props;
    const { showCloseButton } = this.state;

    return (
      <Modal
        fullScreen
        fullSizeBody
        show={show}
        isLoading={isPlansListLoading}
        withCloseButton={showCloseButton}
        header={this.renderModalHeader()}
        title={this.renderTitle()}
        onHide={this.handleClose}
        onExited={this.handleExited}
      >
        <div className="manage-subscription">
          {this.renderStep()}
        </div>
      </Modal>
    );
  }

  renderModalHeader() {
    const { step } = this.state;

    if (this.state.shouldHideBackButton) {
      return null;
    }

    return ['currentPlanStep', 'changePlanStep'].includes(step)
      ? (
        <div className="manage-subscription__back-button">
          <ButtonBack
            visible={step !== 'currentPlanStep'}
            onClick={this.handleBackButtonClick}
            data-testid="header-back-button"
          />
        </div>
      )
      : null;
  }

  renderTitle() {
    const { step, isPageLoading } = this.state;

    if (isPageLoading) {
      return null;
    }

    switch (step) {
      case 'currentPlanStep':
        return (
          <div className="manage-subscription__title">
            <FormattedMessage
              id="manageSubscriptionModal.currentPlanStep.title"
              defaultMessage="Manage subscription"
            />
          </div>
        );
      case 'changePlanStep':
        return (
          <div className="manage-subscription__title">
            <FormattedMessage
              id="manageSubscriptionModal.changePlanStep.title"
              defaultMessage="Change plan"
            />
          </div>
        );
      default:
        return null;
    }
  }

  renderStep() {
    const {
      isCancelSubscriptionAllowed,
      primarySubscription,
      pricePlans,
      displayedPricePlan,
      changePricePlan,
      fetchUser,
      showDangerNotification,
      showSuccessNotification,
      isUserFamilyOwner,
      annualDiscountPercentage
    } = this.props;
    const {
      isProcessing,
      isPageLoading,
      step,
      nextPricePlan,
      nextPricePlanCalculationData,
      errorMessage,
    } = this.state;

    if (!primarySubscription || !displayedPricePlan) {
      return null;
    }

    if (isPageLoading) {
      return (
        <div className="manage-subscription__loader">
          <AnimatedLogo animate />
        </div>
      );
    }

    const isActiveSubscription = isActive(primarySubscription);
    const hasOppositeBillingPeriodPlan = Boolean(getOppositeBillingPeriodPlan(displayedPricePlan, pricePlans));
    const isTrialWithForbiddenSwitch = primarySubscription.status === subscriptionStatuses.TRIAL
      && primarySubscription.pricePlan.features?.trialSwitchFromStrategy === 'forbidden';

    const canChangePricePlan = isActiveSubscription && !isTrialWithForbiddenSwitch;
    const canChangeBillingPeriod = isActiveSubscription && hasOppositeBillingPeriodPlan && !isTrialWithForbiddenSwitch;

    switch (step) {
      case 'currentPlanStep':
        return (
          <CurrentPlanStep
            canCancelSubscription={isCancelSubscriptionAllowed}
            canChangeBillingPeriod={canChangeBillingPeriod}
            canChangePricePlan={canChangePricePlan}
            primarySubscription={primarySubscription}
            currentPricePlan={displayedPricePlan}
            onChangePlan={this.handleChangePlan}
            onChangePeriod={this.handleChangeBillingPeriod}
            onCancelSubscription={this.handleCancelSubscription}
          />
        );
      case 'confirmChangePlanAndLoseDiscount':
        return (
          <ConfirmLoseDiscount
            onConfirm={this.handleConfirmChangePlanAndLoseDiscount}
            onGoBackward={this.handleBackButtonClick}
          />
        );
      case 'confirmChangeBillingPeriodAndLoseDiscount':
        return (
          <ConfirmLoseDiscount
            onConfirm={this.handleConfirmChangeBillingPeriodAndLoseDiscount}
            onGoBackward={this.handleBackButtonClick}
          />
        );
      case 'changePlanStep':
        return (
          <ChangePlanStep
            isProcessing={isProcessing}
            currentPricePlan={displayedPricePlan}
            nextPricePlan={nextPricePlan}
            pricePlans={pricePlans}
            errorMessage={errorMessage}
            onSelectNextPlan={this.handleSelectNextPlan}
            onConfirmNextPlan={this.handleConfirmNextPlan}
            isUserFamilyOwner={isUserFamilyOwner}
          />
        );
      case 'changePricePlanFlow': {
        if (!nextPricePlan || !nextPricePlanCalculationData) {
          return null;
        }

        return (
          <ChangePricePlanFlow
            primarySubscription={primarySubscription}
            currentPricePlan={displayedPricePlan}
            nextPricePlan={nextPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onGoBackward={this.handleBackButtonClick}
            onClose={this.handleClose}
            onStartPayment={this.handleStartPayment}
            onStopPayment={this.handleStopPayment}
            onSuccessPlanChange={this.handleSuccessPlanChange}
            shouldHideBackButton={this.state.shouldHideBackButton}
          />
        );
      }
      case 'changeBillingPeriodFlow': {
        return (
          <ChangeBillingPeriodFlow
            currentPricePlan={displayedPricePlan}
            primarySubscription={primarySubscription}
            nextPricePlan={nextPricePlan}
            pricePlans={pricePlans}
            changePricePlan={changePricePlan}
            fetchUser={fetchUser}
            showDangerNotification={showDangerNotification}
            showSuccessNotification={showSuccessNotification}
            onSelectNextPlan={this.handleSelectNextPlan}
            onGoBackward={this.handleBackButtonClick}
            onClose={this.handleClose}
            annualDiscountPercentage={annualDiscountPercentage}
          />
        );
      }
      case 'confirmCancelFamilyStep': {
        return (
          <ConfirmCancelFamilyStep
            nextPaymentDate={primarySubscription.nextPaymentDate!}
            onConfirm={this.handleConfirmCancelFamily}
          />
        );
      }
      default:
        return null;
    }
  }

  redirectToCancelFlow = () => {
    const { history } = this.props;
    history.push(urls.cancelSubscription);
  };

  handleBackButtonClick = () => {
    const { step } = this.state;
    this.setState({ step: BACK_STEP_MAPPING[step] ?? step });
  }

  handleChangePlan = () => {
    const { isSubscriptionWithDiscount } = this.props;
    let nextStep: Step = 'changePlanStep';

    if (isSubscriptionWithDiscount) {
      nextStep = 'confirmChangePlanAndLoseDiscount';
    }

    this.setState({
      step: nextStep,
      nextPricePlan: undefined,
    });

    analytics.trackEvent(events.SELECT_PLAN_PAGE_CHANGE_PLAN_CLICK);
  }

  handleConfirmChangePlanAndLoseDiscount = () => {
    this.setState({ step: 'changePlanStep' });
  }

  handleConfirmChangeBillingPeriodAndLoseDiscount = () => {
    this.setState({ step: 'changeBillingPeriodFlow' });
  }

  handleConfirmNextPlan = async () => {
    const { primarySubscription, displayedPricePlan } = this.props;
    const { nextPricePlan } = this.state;

    if (!primarySubscription || !displayedPricePlan || !nextPricePlan) {
      return;
    }

    analytics.trackEvent(events.SELECT_PLAN_PAGE_PLAN_SELECT, {
      eventLabel: nextPricePlan.tierType,
      eventLabel2: displayedPricePlan.tierType,
    });

    this.setState({ isProcessing: true });

    try {
      const nextPricePlanCalculationData = await calculateNextPricePlan(primarySubscription.id, nextPricePlan.id);
      this.setState({
        step: 'changePricePlanFlow',
        isProcessing: false,
        nextPricePlanCalculationData,
      });
    } catch (err) {
      this.setState({
        errorMessage: (
          <FormattedMessage
            id="manageSubscriptionModal.errorMessage.calculateNextPricePlan"
            defaultMessage="Failed to calculate next price plan. Please try again later"
          />
        ),
        isProcessing: false,
      });
    }
  }

  handleSelectNextPlan = (nextPricePlan: PricePlan) => {
    this.setState({ nextPricePlan });
  }

  handleChangeBillingPeriod = () => {
    const { isSubscriptionWithDiscount } = this.props;
    let nextStep: Step = 'changeBillingPeriodFlow';

    if (isSubscriptionWithDiscount) {
      nextStep = 'confirmChangeBillingPeriodAndLoseDiscount';
    }

    this.setState({
      step: nextStep,
      nextPricePlan: undefined,
    });

    analytics.trackEvent(events.SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_CLICK);
  }

  handleStartPayment = () => {
    this.setState({ showCloseButton: false });
  }

  handleStopPayment = () => {
    this.setState({ showCloseButton: true });
  }

  handleSuccessPlanChange = () => {
    const { history, displayedPricePlan } = this.props;
    const { nextPricePlan } = this.state;

    const isNextPricePlanAi = isAiPricePlan(nextPricePlan!.tierType);
    const isInsideSetappMobileFlow = getIsInsideSetappMobileFlow();
    const isInsideCustomerOauthFlow = getStoredCustomerOauthError() === 'OFFER_UPGRADE';

    const event = isNextPricePlanAi && history.location.pathname === urls.aiOffer
      ? events.AI_PLUS_CONFIRM_CHANGE_PLAN
      : events.SELECT_PLAN_PAGE_CONFIRM_CHANGE_PLAN;

    analytics.trackEvent(event, {
      eventLabel: nextPricePlan!.tierType,
      eventLabel2: displayedPricePlan!.tierType,
    });

    if (isFeaturedFamilyPricePlan(nextPricePlan!.tierType)) {
      history.push(urls.family);

      return;
    }

    if (isInsideSetappMobileFlow) {
      const setappMobileUrl = `${urls.setappMobile}?${SETAPP_MOBILE_SUBSCRIPTION_NOTIF_QUERY}=true`;

      history.push(setappMobileUrl);

      return;
    }

    if (isInsideCustomerOauthFlow) {
      return; // do not push anywhere, just close the modal
    }

    if (isNextPricePlanAi) {
      history.push(urls.successfulAiOfferActivation);
    }
  }

  handleCancelSubscription = () => {
    const { isUserFamilyOwner } = this.props;

    analytics.trackEvent(events.SELECT_PLAN_PAGE_CANCEL_SUBSCRIPTION_CLICK);

    if (isUserFamilyOwner) {
      this.setState({ step: 'confirmCancelFamilyStep' });
    } else {
      this.redirectToCancelFlow();
    }
  }

  handleConfirmCancelFamily = () => {
    this.redirectToCancelFlow();
  };

  handleClose = () => {
    const { onHide } = this.props;

    onHide();
    this.setState({ ...INITIAL_STATE });
  }

  handleExited = () => {
    const { onExited, history } = this.props;

    /*
    query params are removed on modal exited (when it's completely hidden) instead of on hide (when it's disappearing)
     */
    removeQueryParams(history, 'show_change_plan_modal');
    this.setState({ ...INITIAL_STATE });
    onExited();
  };

  handlePreselectedStep = async (selectedStep: string) => {
    const { displayedPricePlan, pricePlans, isSubscriptionWithDiscount } = this.props;

    switch (selectedStep) {
      case MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER.ManageBillingPeriodAnnual: {
        if (displayedPricePlan && displayedPricePlan.paidMonth === 1) {
          // preselect annual plan if the user come with monthly plan
          const oppositeBillingPeriodPricePlan = getOppositeBillingPeriodPlan(displayedPricePlan, pricePlans);
          if (oppositeBillingPeriodPricePlan) {
            this.handleSelectNextPlan(oppositeBillingPeriodPricePlan);
          }
        }

        if (isSubscriptionWithDiscount) {
          this.setState({
            step: 'confirmChangeBillingPeriodAndLoseDiscount',
          });
        } else {
          this.setState({
            step: 'changeBillingPeriodFlow',
          });
        }

        break;
      }

      case MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER.ManageBillingPeriod: {
        if (isSubscriptionWithDiscount) {
          this.setState({
            step: 'confirmChangeBillingPeriodAndLoseDiscount',
          });
        } else {
          this.setState({
            step: 'changeBillingPeriodFlow',
          });
        }

        break;
      }

      case MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER.ManagePlan: {
        const nextPricePlan = pricePlans.find((plan) => plan.id === this.props.preselectedNextPlanId);

        this.setState({
          step: isSubscriptionWithDiscount ? 'confirmChangePlanAndLoseDiscount' : 'changePlanStep',
          nextPricePlan: nextPricePlan ?? this.state.nextPricePlan,
          shouldHideBackButton: getIsInsideSetappMobileFlow(),
        });

        break;
      }

      case MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER.ChangeToSelectedPlan: {
        const { selectedNextPlan, primarySubscription, isPaymentMethodFetched, fetchPaymentMethod } = this.props;

        this.setState({
          isPageLoading: true,
          shouldHideBackButton: true,
        });

        const [nextPricePlanCalculationData] = await Promise.all([
          calculateNextPricePlan(
            primarySubscription!.id,
            selectedNextPlan!.id
          ),
          !isPaymentMethodFetched && fetchPaymentMethod(),
        ]);

        this.setState({
          isPageLoading: false,
          step: 'changePricePlanFlow',
          nextPricePlan: selectedNextPlan,
          nextPricePlanCalculationData,
        });

        break;
      }
    }
  }
}

export const ManageSubscriptionWithConnector = connector(ManageSubscription);

export default withRouter(ManageSubscriptionWithConnector);
