import queryString from 'query-string';
import React, { ContextType, PureComponent } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { type RouteComponentProps } from 'react-router-dom';
import { AnimatedLogo } from '@setapp/ui-kit';

import classnames from 'classnames';
import urls from 'config/urls';

import { calculateNextPricePlan, NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import { PricePlanPeriod } from 'services/price-plans/types';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import {
  getFeaturedPricePlans,
  getPrimarySubscription,
  getUser,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';

import { NavigationContext } from 'components/navigation/navigation';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import Footer from 'components/layout/footer/footer';
import DefaultError from 'components/shared/default-error/default-error';
import ChangePricePlanFlow from 'components/user-flow/change-price-plan/change-price-plan-flow/change-price-plan-flow';
import withRouter from 'components/navigation/navigation-with-router';

import ChoosePricePlanStep from './choose-price-plan-step/choose-price-plan-step';

import './change-price-plan-page.scss';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
  featuredPricePlans: getFeaturedPricePlans(state),
  user: getUser(state),
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  fetchPricePlans,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Step = 'choosePricePlanStep' | 'changePricePlanFlow';

type Props = RouteComponentProps & PropsFromRedux;

type State = {
  isProcessing: boolean;
  step: Step;
  nextPricePlanCalculationData?: NextPricePlanCalculationData;
  nextPricePlan?: PricePlan;
  errorMessage?: ReactNode;
};

class ChangePricePlanPage extends PureComponent<Props, State> {
  state: State = {
    isProcessing: false,
    step: 'choosePricePlanStep',
    nextPricePlan: undefined,
    nextPricePlanCalculationData: undefined,
    errorMessage: undefined,
  };
  static contextType = NavigationContext;
  context!: ContextType<typeof NavigationContext>;

  componentDidMount() {
    const { fetchAllSubscriptions, fetchPricePlans, showDangerNotification } = this.props;

    return Promise.all([
      fetchAllSubscriptions(),
      fetchPricePlans(),
    ])
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const { primarySubscription } = this.props;

    if (!primarySubscription) {
      return <FullscreenLayoutLoading />;
    }

    return (
      <FullscreenLayout>
        <div className="change-price-plan-page">
          <div
            className={classnames('change-price-plan-page__container', {
              'change-price-plan-page__container-embedded': this.context?.isEmbedded,
            })}
          >
            <div className="change-price-plan-page__content">
              {!this.context?.isEmbedded && <AnimatedLogo size="sm" hasCaption />}
              {this.renderStep()}
            </div>
          </div>
          {!this.context?.isEmbedded && this.renderFooter()}
        </div>
      </FullscreenLayout>
    );
  }

  renderStep() {
    const { primarySubscription, user } = this.props;
    const {
      isProcessing,
      step,
      nextPricePlan,
      nextPricePlanCalculationData,
      errorMessage,
    } = this.state;

    // Display next price plan info if user changed plan. It's made by design.
    const currentPricePlan = primarySubscription?.nextPricePlan || primarySubscription?.pricePlan;

    if (!primarySubscription || !currentPricePlan) {
      return null;
    }

    switch (step) {
      case 'choosePricePlanStep': {
        const { 'billing_period': predefinedBillingPeriod } = queryString.parse(location.search);
        const switchedToAnnualInitValue: boolean | undefined = predefinedBillingPeriod
          ? Number(predefinedBillingPeriod) === PricePlanPeriod.Annual
          : undefined;

        return (
          <div className={classnames('change-price-plan-page__step', {
            'change-price-plan-page__step-embedded': this.context?.isEmbedded,
          })}
          >
            <ChoosePricePlanStep
              user={user}
              currentPricePlan={currentPricePlan}
              isProcessing={isProcessing}
              nextPricePlan={nextPricePlan}
              errorMessage={errorMessage}
              onConfirmNextPlan={this.handleConfirmNextPlan}
              isTrialUser={primarySubscription.status === subscriptionStatuses.TRIAL}
              switchedToAnnualInitValue={switchedToAnnualInitValue}
            />
          </div>
        );
      }
      case 'changePricePlanFlow': {
        if (!nextPricePlan || !nextPricePlanCalculationData) {
          return null;
        }

        return (
          <div className="change-price-plan-page__flow">
            <ChangePricePlanFlow
              primarySubscription={primarySubscription}
              currentPricePlan={currentPricePlan}
              nextPricePlan={nextPricePlan}
              nextPricePlanCalculationData={nextPricePlanCalculationData}
              onGoBackward={this.handleBackButtonClick}
              onClose={this.handleClose}
            />
          </div>
        );
      }
      default:
        return null;
    }
  }

  renderFooter() {
    const { step } = this.state;

    return step === 'choosePricePlanStep' ? <Footer /> : null;
  }

  handleBackButtonClick = () => {
    this.setState({ step: 'choosePricePlanStep' });
  }

  handleConfirmNextPlan = async (nextPricePlan: PricePlan) => {
    const { primarySubscription } = this.props;

    if (!primarySubscription) {
      return;
    }

    this.setState({ isProcessing: true, nextPricePlan });

    try {
      const nextPricePlanCalculationData = await calculateNextPricePlan(primarySubscription.id, nextPricePlan.id);
      this.setState({
        step: 'changePricePlanFlow',
        isProcessing: false,
        nextPricePlanCalculationData,
      });
    } catch (err) {
      this.setState({
        errorMessage: (
          <FormattedMessage
            id="changePricePlanPage.errorMessage.calculateNextPricePlan"
            defaultMessage="Failed to calculate next price plan. Please try again later"
          />
        ),
        isProcessing: false,
      });
    }
  }

  handleClose = () => {
    const { history } = this.props;
    history.push(urls.subscription);
  }
}

export { ChangePricePlanPage as PureChangePricePlanPage };

export default connector(withRouter(ChangePricePlanPage));
